import { Enums } from '@bode-canada/core';

export const getUserBusinessAccountByType = (user, accountType) => {
  const userBusinessAccounts = user.userBusinessAccounts || [];

  return userBusinessAccounts.find(({ businessAccount }) => businessAccount.type === accountType);
};

export default {
  user: (state) => state.userData || {},
  isBuilderListingSub: (state, getters) => !!state.userData
    && getters.builderAccount?.businessAccount?.isListingSub,
  isBuilderDataSub: (state, getters) => !!state.userData
    && getters.builderAccount?.businessAccount?.isDataSub,
  isAdmin: (state) => !!state.userData && state.userData.roles.admin,
  builderAccount: (state) => (state.userData ? getUserBusinessAccountByType(
    state.userData,
    Enums.BusinessAccountTypeEnum.BUILDER,
  ) : null),
};

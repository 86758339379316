/* eslint-disable no-param-reassign */
export const SET_HEADER_CONTENT = 'set-header-content';
export const SET_DESCRIPTION_CONTENT = 'set-description-content';
export const SET_CONFIRM_CONTENT = 'set-confirm-content';
export const SET_IS_CONTENT_LOADED = 'set-is-content-loaded';

export default {
  [SET_HEADER_CONTENT](previousState, content) {
    previousState.headerContent = content;
  },
  [SET_DESCRIPTION_CONTENT](previousState, content) {
    previousState.descriptionContent = content;
  },
  [SET_CONFIRM_CONTENT](previousState, content) {
    previousState.confirmContent = content;
  },
  [SET_IS_CONTENT_LOADED](previousState, isLoaded) {
    previousState.isContentLoaded = isLoaded;
  },
};

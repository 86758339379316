export const IconButtonPropsEventType = {
  Click: 'click',
};

export const StyleFillType = {
  Outlined: 'outlined',
  Button: 'button',
};

export const ShapeType = {
  Square: 'square',
  Rounded: 'rounded',
};

export const IconButtonProperty = {
  ButtonType: 'buttonType',
  ClassName: 'className',
  IconClassName: 'iconClassName',
  Type: 'type',
  Size: 'size',
  ColorScheme: 'colorScheme',
  IsDisabled: 'isDisabled',
  IsLoading: 'isLoading',
  FillType: 'fillType',
  ShapeType: 'shapeType',
};

import { ExternalLinks } from '@/utils/external-navigation/constants';
import { FooterTrackEvent } from '@/constants/AnalyticEvents';
import { IconType } from '@/ui-kit/components/icon/enums';

export const SOCIAL_LINKS = [
  {
    icon: IconType.Instagram,
    redirectTo: ExternalLinks.InstagramBode,
    eventName: FooterTrackEvent.InstagramIconClick,
  },
  {
    icon: IconType.FacebookCircle,
    redirectTo: ExternalLinks.FacebookBode,
    eventName: FooterTrackEvent.FacebookIconClick,
  },
  {
    icon: IconType.LinkedInCircle,
    redirectTo: ExternalLinks.LinkedInBode,
    eventName: FooterTrackEvent.LinkedInIconClick,
  },
];

export default {
  SOCIAL_LINKS,
};

import debug from 'debug';

/**
 * A tiny utility modelled after Node.js core's debugging technique for the browser.
 *
 * Returns a decorated version of console.log to pass debug statements to.
 * This will allow you to toggle the debug output for different parts of the application.
 *
 * @note Logs are not visible by default but can be enabled with a local storage flag
 * (ex: localStorage.setItem('debug', '*'))
 *
 * @param {String} topic
 * @return {Function}
 * @api public
 */

export function log(topic) {
  return (message) => {
    const logger = debug(`bode:${topic}`);
    // eslint-disable-next-line no-console
    logger.log = console.log.bind(console);
    return logger(message);
  };
}

/**
 * A tiny utility modelled after Node.js core's debugging technique for the browser.
 *
 * Returns a decorated version of console.error to pass debug statements to.
 * This will allow you to toggle the debug output for different parts of the application.
 *
 * @param {String} topic
 * @return {Function}
 * @api public
 */

export function error(topic) {
  return () => debug(`bode:${topic}`);
}


import Gutter from '@/ui-kit/components/gutter';
import { GutterProperty } from '@/ui-kit/components/gutter/enums';
import { StyleSizeType, StylePositionType } from '@/ui-kit/styles/enums';
import FooterLogoSection from './logo-section';
import FooterCopyright from './copyright';
import FooterNavigation from './navigation';

export default {
  name: 'Footer',
  components: {
    FooterNavigation,
    FooterLogoSection,
    FooterCopyright,
    Gutter,
  },
  data() {
    return {
      StyleSizeType,
      StylePositionType,
      GutterProperty,
    };
  },
};

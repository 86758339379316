import { TypographyProperty } from '../enums';
import {
  StyleColorSchemeType,
  StyleFontWeightType,
} from '../../../styles/enums';

const TypographyProps = {
  props: {
    [TypographyProperty.Type]: {
      type: String,
      default: '',
    },
    [TypographyProperty.ColorScheme]: {
      type: String,
      default: StyleColorSchemeType.GrayDark,
    },
    [TypographyProperty.FontWeight]: {
      type: String,
      default: StyleFontWeightType.Light,
    },
    [TypographyProperty.Alignment]: {
      type: String,
      default: '',
    },
    [TypographyProperty.LineThrough]: {
      type: Boolean,
      default: false,
    },
  },
};

export default TypographyProps;

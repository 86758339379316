export const ClientEvent = {
  Resize: 'resize',
  Scroll: 'scroll',
  Wheel: 'wheel',
};

export const KeyboardKeyCode = {
  Escape: 'Escape',
  Enter: 'Enter',
  ArrowUp: 'ArrowUp',
  ArrowRight: 'ArrowRight',
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
};

export const Comparator = {
  Greater: 'greater',
  Less: 'less',
};

export const PositionInOrder = {
  Odd: 'odd',
  Even: 'even',
};

export const RedirectType = {
  ThisWindow: 'thisWindow',
  NewWindow: 'newWindow',
};

export const AriaRole = {
  Combobox: 'combobox',
  Listbox: 'listbox',
};

export const Locale = {
  EnCA: 'en-CA',
  EnUS: 'en-US',
};

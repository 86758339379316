import { MenuTooltipProperty } from '../enums';

const MenuTooltipProps = {
  props: {
    [MenuTooltipProperty.IsDropdownViewable]: {
      default: true,
      type: Boolean,
    },
    [MenuTooltipProperty.IsRightSide]: {
      default: false,
      type: Boolean,
    },
  },
};

export default MenuTooltipProps;

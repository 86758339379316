
import ImagePlaceholder from '../image-placeholder';
import { ImagePlaceholderSlotType } from '../image-placeholder/enums';
import Spinner from '../spinner';
import ImageCommonProps from './mixins/ImageCommonProps';
import { ImageProperty, ImageEventType, ImagePropsEventType } from './enums';

export default {
  name: 'BaseImage',
  components: {
    Spinner,
    ImagePlaceholder,
  },
  mixins: [
    ImageCommonProps,
  ],
  props: {
    [ImageProperty.ReserveSrc]: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      isLoadingFinished: false,
      ImagePlaceholderSlotType,
      ImageProperty,
      ImageEventType,
    };
  },
  computed: {
    conditionSrc() {
      return this[ImageProperty.Src] || this[ImageProperty.ReserveSrc];
    },
  },
  methods: {
    handleImageLoad() {
      this.isLoadingFinished = true;
    },
    handleImageError(event) {
      if (!this.isLoadingFinished) {
        this.replaceSrcToReserve(event.target);
        this.isLoadingFinished = true;
        this.$emit(ImagePropsEventType.Error);
      }
    },
    replaceSrcToReserve(target) {
      // eslint-disable-next-line no-param-reassign
      target.src = this[ImageProperty.ReserveSrc];
    },
  },
};

export default () => ({
  isContentLoaded: false,
  heroContent: {},
  descriptionContent: {},
  cardTitle: {},
  cardContent: {
    list: [],
  },
  howItWorksTitle: {},
  howItWorksContent: {
    list: [],
  },
  footerContent: {},
});

/* eslint-disable camelcase */
import {
  SET_LISTING_SUBMIT_SUCCESS_CONTENT,
  SET_LISTING_SUBMIT_SUCCESS_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';
import ListingSubmitSuccessMapper from './mappers';

export const SET_LISTING_SUBMIT_SUCCESS_CONTENT_DATA = 'set-listing-submit-success-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_LISTING_SUBMIT_SUCCESS_CONTENT_DATA]({ commit }, data) {
    const content = ListingSubmitSuccessMapper.mapPrismicDataToContent(data);

    commit(SET_LISTING_SUBMIT_SUCCESS_CONTENT, content);
    commit(SET_LISTING_SUBMIT_SUCCESS_IS_CONTENT_LOADED, true);
  },
};

/* eslint-disable no-param-reassign */
export const SET_HERO_CONTENT = 'set-hero-content';
export const SET_STAT_CONTENT = 'set-stat-content';
export const SET_INFORMATION_CONTENT = 'set-information-content';
export const SET_FOOTER_CONTENT = 'set-footer-content';
export const SET_IS_CONTENT_LOADED = 'set-is-content-loaded';
export default {
  [SET_HERO_CONTENT](previousState, content) {
    previousState.heroContent = content;
  },
  [SET_STAT_CONTENT](previousState, content) {
    previousState.statContent = content;
  },
  [SET_INFORMATION_CONTENT](previousState, content) {
    previousState.informationContent = content;
  },
  [SET_FOOTER_CONTENT](previousState, content) {
    previousState.footerContent = content;
  },
  [SET_IS_CONTENT_LOADED](previousState, isLoaded) {
    previousState.isContentLoaded = isLoaded;
  },
};

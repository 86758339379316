
import Toast from '@/ui-kit/components/toast';
import ToastService from './toast-service';
import ToastStoreName from '@/store/toast/name';

export default {
  name: 'BodeToast',
  components: {
    Toast,
  },
  data() {
    return {
      ToastService,
    };
  },
  computed: {
    toastState() {
      return this.$store.state[ToastStoreName];
    },
  },
  mounted() {
    this.ToastService.setStore(this.$store);
  },
};

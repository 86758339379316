
import Icon from '../icon';
import { IconType } from '../icon/enums';
import { StyleColorSchemeType, StyleSizeType } from '../../styles/enums';
import SpinnerProps from './mixins/SpinnerProps';
import { SpinnerProperty } from './enums';

export default {
  name: 'SpinnerInline',
  components: {
    Icon,
  },
  mixins: [SpinnerProps],
  data() {
    return {
      IconType,
      StyleColorSchemeType,
      StyleSizeType,
      SpinnerProperty,
    };
  },
};

import { ListItemProperty } from '../enums';
import { StyleColorSchemeType, StyleSizeType } from '../../../styles/enums';

const ListItemProps = {
  props: {
    [ListItemProperty.Icon]: {
      default: '',
      type: String,
    },
    [ListItemProperty.IconColor]: {
      default: StyleColorSchemeType.Primary,
      type: String,
    },
    [ListItemProperty.ClassName]: {
      default: '',
      type: String,
    },
    [ListItemProperty.IconSize]: {
      default: StyleSizeType.Medium,
      type: String,
    },
    [ListItemProperty.AppendIcon]: {
      default: '',
      type: String,
    },
    [ListItemProperty.Label]: {
      default: '',
      type: String,
    },
    [ListItemProperty.Value]: {
      default: '',
      type: [String, Number, Object],
    },
    [ListItemProperty.Disabled]: {
      default: false,
      type: Boolean,
    },
    [ListItemProperty.IsActive]: {
      default: false,
      type: Boolean,
    },
  },
};

export default ListItemProps;

import { NavLinkProperty } from '../enums';

const NavLinkProps = {
  props: {
    [NavLinkProperty.Label]: {
      default: '',
      type: String,
    },
    [NavLinkProperty.RedirectTo]: {
      default: '',
      type: String,
    },
    [NavLinkProperty.IsBeta]: {
      default: false,
      type: Boolean,
    },
    [NavLinkProperty.NestedLinks]: {
      default: () => ([]),
      type: Array,
    },
  },
};

export default NavLinkProps;

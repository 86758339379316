
import ExternalLink from '@/ui-kit/components/external-link';
import { ExternalLinkProperty } from '@/ui-kit/components/external-link/enums';
import { StyleColorSchemeType } from '@/ui-kit/styles/enums';
import FooterLinkProps from './mixins/LinkProps';
import FooterLinkEvents from './mixins/LinkEvents';
import { FooterLinkProperty } from './enums';

export default {
  name: 'FooterNavigationExternalLink',
  components: {
    ExternalLink,
  },
  mixins: [FooterLinkProps, FooterLinkEvents],
  data() {
    return {
      ExternalLinkProperty,
      FooterLinkProperty,
      StyleColorSchemeType,
    };
  },
  computed: {
    linkEventData() {
      return this.makeLinkEventData(
        this[FooterLinkProperty.Data].label,
        this[FooterLinkProperty.Type],
      );
    },
  },
};

/* eslint-disable camelcase */
import {
  SET_PANEL_CONTENT,
  SET_LOGIN_CONTENT,
  SET_SUCCESSFUL_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      pro_signup_panel,
      pro_signup_login,
      pro_signup_successful,
    } = data;

    const [panelData] = pro_signup_panel;
    const [loginData] = pro_signup_login;
    const [successfulData] = pro_signup_successful;

    commit(SET_PANEL_CONTENT, panelData);
    commit(SET_LOGIN_CONTENT, loginData);
    commit(SET_SUCCESSFUL_CONTENT, successfulData);
    commit(SET_IS_CONTENT_LOADED, true);
  },
};

import * as Sentry from '@sentry/vue';
import { Cookie } from '@/utils/cookie/constants';
import UserApiService from '@/services/api/serverless/user';
import { getCurrentToken } from '../auth';
import request from '../request';
import store from './cache';
import * as analytics from '../analytics';
import { createCookie, deleteCookie } from '../cookie';
import { log } from '../debug';
import UserMapper from './user-mapper';

const debug = log('user');

/**
 * Get user data.
 * Refresh token and returns user from cache. If cache empty,
 * we will fetch the user's data from the backend and update the cache. In
 * case the user is not authenticated or data couldn't be fetched, the cache will
 * be reseted.
 *
 * @note because the suer cache is in memory we call identify on each user refresh.
 *
 * @public
 */

export async function getUser(forceRefresh) {
  debug('get user');
  try {
    // force refresh token
    const token = await getCurrentToken(true);
    let cache = store.get();

    if (forceRefresh || !cache) {
      const user = await UserApiService.getCurrentUser();
      cache = store.set(user, true);
    }
    const mappedUser = UserMapper.mapUserToStore(cache, token);
    if (forceRefresh || !cache) {
      // @note identify user on page load (optional)
      // https://segment.com/docs/connections/spec/best-practices-identify/#when-to-call-identify
      trackAnalyticsUser(mappedUser);
    }
    trackSentryUser(mappedUser);
    return mappedUser;
  } catch (error) {
    resetUser();

    return {
      isAuthenticated: false,
    };
  }
}

/**
 * Reset user.
 *
 * @private
 */

export function resetUser() {
  if (process.client) {
    resetAnalyticsUser();
    resetSentryUser();
    store.reset();
    resetImpersonateUser();
  }
}

/**
 * Impersonate user.
 *
 * @param {String} email
 * @returns {Object}
 * @public
 */

export async function impersonateUser(email) {
  store.reset();
  createCookie('bode-with-impersonation', 'true');
  createCookie(Cookie.BodeImpersonation, email, true);
}

function resetImpersonateUser() {
  deleteCookie('bode-with-impersonation');
  deleteCookie(Cookie.BodeImpersonation);
}

/**
 * Merge user cache with data and update
 * remotre user.
 *
 * @param {Object} data
 * @returns {Promise<Object>}
 * @public
 */

export async function updateUser(data) {
  debug('update user');
  // @todo Track new information
  const response = await request('put', '/user', {
    data,
  });
  return store.set(response.data);
}

/**
 * @track identity
 *
 * @note we call trackIdentity in the getUser method so we update a user
 * identity if something changed in the database. It also allow to set an identity
 * on subdomains where local storage is not shared.
 *
 * @param {Object} user
 * @private
 */
function trackAnalyticsUser(user) {
  analytics.identify(user.userId, {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    cognitoId: user.token.sub,
    emailVerified: user.token.email_verified,
  });
}

function resetAnalyticsUser() {
  analytics.reset();
}

/**
 * Tracks Sentry user to display.
 * Multiple calls are valid since no Sentry requests occurred on user set/update.
 *
 * @param {Object} user
 * @private
 */
function trackSentryUser(user) {
  const userRoles = Object.keys(user.roles);
  Sentry.setUser({
    id: user.userId,
    email: user.email,
    crmId: user.crmId,
    status: user.status,
    roles: userRoles,
  });
  Sentry.setTag('user_roles', userRoles.join(','));
}

function resetSentryUser() {
  Sentry.setUser(null);
  Sentry.setTag('user_roles', undefined);
}

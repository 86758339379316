export const ExternalLinks = {
  HelpBode: 'https://help.bode.ca/en/',
  InstagramBode: 'https://www.instagram.com/bode.canada/',
  FacebookBode: 'https://www.facebook.com/Bode.Canada/',
  LinkedInBode: 'https://www.linkedin.com/company/b%C5%8Dde/',
  BodeProofs: 'https://home.bode.ca/proof/',
  MaterialDefects: 'https://home.bode.ca/blog/material-latent-defects/',
  TrilogyApp: 'https://velocity-app.newton.ca/public/#/clientJourney/slides/home?shortCode=12a8in7houa7p&lang=en-ca',
  Realtor: 'https://www.realtor.ca/en',
  Partners: 'https://home.bode.ca/partners/',
  CanadianRealEstateAssociation: 'https://www.crea.ca',
  Sustainability: 'https://home.bode.ca/sustainability/',
  BlogBode: 'https://home.bode.ca/blog',
  EventsBode: 'https://home.bode.ca/bode-events',
  SellerUniversityBode: 'https://home.bode.ca/sellers-university',
  CalgaryGoogleMaps: 'https://www.google.com/maps/dir//bode+canada/@51.0381579,-114.1089848,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x5371710dddfaa3e7:0x1b599630d152786d!2m2!1d-114.0739655!2d51.0381092',
  ScheduleACall: 'https://calendly.com/bode-teambodeteam',
  BodeHelpOffers: 'https://home.bode.ca/blog/paperwork-for-buying-a-house-without-a-real-estate-agent',
  WireTransferDocument: '/forms/Wire Transfer Instructions.pdf',
  PowerOfAttorneyDocument: '/forms/Special POA.pdf',
  DowerActDocument: '/forms/Dower Consent And Acknowledgement.pdf',
  DowerRightsGuide: 'https://home.bode.ca/blog/what-are-dower-rights',
  LockShopOrder: 'https://www.amazon.ca/s?k=master+lock+bluetooth&rh=n%3A3006902011%2Cp_89%3AMaster+Lock&dc&qid=1604338827&rnid=7590290011&ref=sr_nr_p_89_1',
  LockShopPickUp: 'https://www.homedepot.ca/search?q=lockbox#!q=lockbox',
  ListingFlowPhotoAndMeasurementBlog: 'https://home.bode.ca/blog/the-benefits-of-professional-photos-when-selling-your-home',
  ListingFlowDowerRightsGuide: 'https://home.bode.ca/blog/what-are-dower-rights',
  ListingFlowPricingGuide: 'https://home.bode.ca/blog/how-to-price-your-home-with-confidence',
  ListingFlowCommissionsGuide: 'https://home.bode.ca/blog/decoding-real-estate-commissions',
  ListingFlowIdVerificationGuide: 'https://home.bode.ca/blog/the-role-of-id-verification-in-selling-your-home/',
  ListingFlowPowerOfAttorneyGuide: 'https://home.bode.ca/blog/selling-with-power-of-attorney',
  OrderLockboxOnline: 'https://www.amazon.ca/s?k=master+lock+bluetooth&rh=n%3A3006902011%2Cp_89%3AMaster+Lock&dc&qid=1604338827&rnid=7590290011&ref=sr_nr_p_89_1',
  HomeDescriptionGuide: 'https://home.bode.ca/blog/how-to-write-an-awesome-home-description',
  SignSelectionGuide: 'https://home.bode.ca/blog/sign',
  ListingAgreementON: '/forms/Listing Agreement_ON_03.2023_FINAL.pdf',
  ListingAgreementAB: '/forms/Listing Agreement_Alberta.pdf',
  ListingAgreementBC: '/forms/Listing Agreement_British Columbia.pdf',
  InteriorBoardCondoChecklist: '/forms/BC_Interior_Board_Condo_Strata_Checklist_Form.pdf',
  InteriorBoardCondoStrataForm: '/forms/BC_Interior_Board_Condo_Strata_Form.pdf',
  SoldDataExternalLink: 'https://help.bode.ca/en/articles/8557912-why-are-properties-missing-from-sold-data-results',
  MarketDataExternalLink: 'https://help.bode.ca/en/articles/8560198-why-are-edmonton-sold-data-results-out-of-date',
  ComparablesExternalLink: 'https://help.bode.ca/en/articles/8560195-why-are-properties-missing-from-comparables-results',
  DeededExternalLink: 'https://forms.deeded.ca/newclient',
  DeededAppointmentExternalLink: 'https://form.jotform.com/233317793691263',
  GuideForBuyers: 'https://home.bode.ca/blog/how-much-do-i-need-to-put-down-as-a-home-buyer',
};

export default {
  ExternalLinks,
};

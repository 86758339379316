
import FooterNavigationExternalLink from '../external-link';
import FooterNavigationInternalLink from '../internal-link';
import FooterNavigationComplexLink from '../complex-link';
import FooterLinkProps from '../mixins/LinkProps';
import FooterLinkEvents from '../mixins/LinkEvents';
import { FooterLinkProperty, FooterLinkType, FooterLinkEventType } from '../enums';

export default {
  name: 'FooterNavigationLinkFactory',
  mixins: [FooterLinkProps, FooterLinkEvents],
  data() {
    return {
      FooterLinkProperty,
      FooterLinkEventType,
    };
  },
  computed: {
    linkComponent() {
      switch (this[FooterLinkProperty.Type]) {
        case FooterLinkType.External:
          return FooterNavigationExternalLink;
        case FooterLinkType.Complex:
          return FooterNavigationComplexLink;
        case FooterLinkType.Internal:
        default:
          return FooterNavigationInternalLink;
      }
    },
  },
};

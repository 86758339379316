
import TypographyStyles from '../mixins/TypographyStyles';

export default {
  name: 'Header4',
  mixins: [TypographyStyles],
  computed: {
    header4ClassNames() {
      return this.getTypographyClassNames('header-4');
    },
  },
};

import api from '../../../utils/api';
import { ListingsPath } from './enums';

export default class ListingsApiService {
  static linkAddress(listingId, addressId) {
    return api.put(
      `${ListingsPath.Root}/${listingId}${ListingsPath.LinkAddress}`,
      { addressId },
    );
  }

  static async countShareListing(listingId) {
    const requestUrl = `${ListingsPath.Root}${ListingsPath.Shares}/${listingId}`;
    const { share_count: shareCount } = await api.put(requestUrl);

    return shareCount;
  }

  static shareListingByEmail(listingId, recipient) {
    const requestUrl = `${ListingsPath.Root}/${listingId}${ListingsPath.SendShareEmail}`;

    return api.post(requestUrl, recipient);
  }

  static getFavourites() {
    return api.get(`${ListingsPath.Root}${ListingsPath.Favourites}`).catch(() => ([]));
  }

  static addToFavourites(listingId) {
    const requestUrl = `${ListingsPath.Root}${ListingsPath.Favourites}`;

    return api.post(requestUrl, { listingId });
  }

  static removeFromFavourites(listingId) {
    const requestUrl = `${ListingsPath.Root}${ListingsPath.Favourites}/${listingId}`;

    return api.delete(requestUrl);
  }

  static saveFavouriteNotes(listingId, notes) {
    const requestUrl = `${ListingsPath.Root}${ListingsPath.Favourites}/${listingId}`;

    return api.put(requestUrl, notes);
  }

  static async getListingAgreementUrl(listingId, isResend) {
    const urlEnd = isResend ? ListingsPath.AgreementResend : ListingsPath.Agreement;
    const requestUrl = `${ListingsPath.Root}/${listingId}${urlEnd}`;
    const { url: agreementUrl } = await api.post(requestUrl, true);

    return agreementUrl;
  }

  static getListingAvailability(listingId, fromTime, toTime) {
    const requestUrl = `${ListingsPath.Root}/${listingId}${ListingsPath.Schedule}`;

    return api.get(requestUrl, {
      from: fromTime.toISOString(),
      to: toTime.toISOString(),
    });
  }

  static requestListingInfo(listingId, requestConfig) {
    const requestUrl = `${ListingsPath.Root}/${listingId}${ListingsPath.RequestInfo}`;

    return api.post(requestUrl, requestConfig);
  }

  static async relistListing(listingId) {
    const requestUrl = `${ListingsPath.Root}/${listingId}${ListingsPath.Relist}`;

    return api.put(requestUrl, { listingId });
  }
}

import { Enums } from '@bode-canada/core';

export default class UserMapper {
  static mapUserToStore(user, token) {
    return {
      ...user,
      token,
      isAuthenticated: true,
      roles: user.roles.reduce((accum, role) => ({
        ...accum,
        [role.role]: true,
      }), {}),
      picture: user.avatar || this.getPictureFromToken(token),
      userId: user.id,
      notifications: {},
      isVerified: user.idVerificationStatus === Enums.UserVerificationStatusEnum.VERIFIED
        || user.status === Enums.UserVerificationStatusEnum.VERIFIED,
    };
  }

  static getPictureFromToken(token) {
    return typeof token.picture === 'string' ? token.picture : token.picture?.data?.url;
  }
}

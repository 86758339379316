import { Enums } from '@bode-canada/core';
import {
  OffersManagementListingDtoField,
  OffersManagementOfferDtoField,
} from '@/services/api/offers-management/enums';
import OffersManagementFilterService
  from '@/components/page-specific/offers-management/services/OffersManagementFilterService';
import { OfferManagementStepperStatus } from '@/components/page-specific/offers-management/stepper/enums';
import OfferManagementOfferDetailsService
  from '@/components/page-specific/offers-management/services/OfferManagementOfferDetailsService';

const FINISHED_STATUSES = [
  OfferManagementStepperStatus.OfferRejected,
  OfferManagementStepperStatus.OfferAccepted,
  OfferManagementStepperStatus.Sold,
  OfferManagementStepperStatus.PossessionDay,
  OfferManagementStepperStatus.OfferRescinded,
  OfferManagementStepperStatus.ConditionsNotSatisfied,
];

class OfferManagementStepperService {
  static getStepperData(offers, role, listing) {
    const lastOffer = OffersManagementFilterService.getLastOffer(offers);
    const isOfflineOffer = lastOffer.offerIsOfflineOffer;
    const isMls = OfferManagementOfferDetailsService.getIsMls(listing);
    const steps = this.#getStepperSteps(offers, role, isMls, isOfflineOffer);
    const status = this.#getStepperStatus(offers, role, isMls);
    const isExpired = OfferManagementOfferDetailsService.getIsExpired(lastOffer);
    const isCounterOffer = status === OfferManagementStepperStatus.UnderNegotiation;

    if (FINISHED_STATUSES.includes(status) || !isExpired) {
      return { steps, status, isCounterOffer };
    }

    const indexOfStatusInSteps = steps.indexOf(status);
    const slicedBeforeActiveStatusSteps = steps.slice(0, indexOfStatusInSteps + 1);
    const newSteps = [...slicedBeforeActiveStatusSteps, OfferManagementStepperStatus.Expired];

    return { steps: newSteps, status: OfferManagementStepperStatus.Expired, isCounterOffer };
  }

  static #getStepperStatus(offers, role, isMls) {
    const isBuyer = role === Enums.UserRoleEnum.BUYER;
    const isRejected = OfferManagementOfferDetailsService.getIsRejected(offers);
    const isAccepted = OfferManagementOfferDetailsService.getIsAccepted(offers);
    const isOfflineOfferSubmitted = OfferManagementOfferDetailsService.getIsOfflineOfferSubmitted(offers);
    const hasCounterOffer = OfferManagementOfferDetailsService.getHasCounterOffers(offers);
    const lastOffer = OffersManagementFilterService.getLastOffer(offers);
    const isListingSold = (
      lastOffer[OffersManagementListingDtoField.ListingStatus] === Enums.ListingStatusEnum.SOLD
    );
    const offerStatus = lastOffer[OffersManagementOfferDtoField.OfferStatus];
    const isPossessionDayComplete = OfferManagementOfferDetailsService
      .getIsPossessionDayComplete(lastOffer);
    const isSubmitted = OfferManagementOfferDetailsService.getIsSubmitted(offers);

    if (
      isPossessionDayComplete
      && !isRejected
      && isListingSold
    ) {
      return OfferManagementStepperStatus.PossessionDay;
    }

    if (!isRejected && isListingSold) {
      return OfferManagementStepperStatus.Sold;
    }

    if (isAccepted && !isRejected) {
      return OfferManagementStepperStatus.OfferAccepted;
    }

    if (isAccepted && isRejected) {
      return OfferManagementStepperStatus.OfferRescinded;
    }

    if (hasCounterOffer && !isRejected) {
      return OfferManagementStepperStatus.UnderNegotiation;
    }

    if (isRejected) {
      return OfferManagementStepperStatus.OfferRejected;
    }

    if (isOfflineOfferSubmitted) {
      if (isBuyer) {
        return OfferManagementStepperStatus.OfflineOfferSubmitted;
      }

      return OfferManagementStepperStatus.OfflineOfferReceived;
    }

    if (isSubmitted) {
      if (isBuyer) {
        return OfferManagementStepperStatus.OfferSubmitted;
      }

      return OfferManagementStepperStatus.OfferReceived;
    }

    if (offerStatus === Enums.OfferStatusEnum.IN_PROGRESS && isBuyer && isMls) {
      return OfferManagementStepperStatus.InProgress;
    }

    if (isBuyer) {
      return OfferManagementStepperStatus.Draft;
    }

    return null;
  }

  static #getStepperSteps(offers, role, isMls, isOfflineOffer) {
    const steps = [];
    const isRejected = OfferManagementOfferDetailsService.getIsRejected(offers);
    const isPossessionDatePassed = OfferManagementOfferDetailsService.getIsPossessionDatePassed(
      offers,
    );

    if (role === Enums.UserRoleEnum.BUYER) {
      steps.push(OfferManagementStepperStatus.Draft);

      if (isMls) {
        steps.push(OfferManagementStepperStatus.InProgress);
      }

      if (isOfflineOffer) {
        steps.push(OfferManagementStepperStatus.OfflineOfferSubmitted);
      } else {
        steps.push(OfferManagementStepperStatus.OfferSubmitted);
      }
    }

    if (role === Enums.UserRoleEnum.SELLER) {
      if (isOfflineOffer) {
        steps.push(OfferManagementStepperStatus.OfflineOfferReceived);
      } else {
        steps.push(OfferManagementStepperStatus.OfferReceived);
      }
    }

    const hasCounterOffer = OfferManagementOfferDetailsService.getHasCounterOffers(offers);
    const isRejectedBeforeNegotiation = !hasCounterOffer && isRejected;
    const isRejectedOnNegotiation = hasCounterOffer && isRejected;

    if (isRejectedBeforeNegotiation) {
      steps.push(OfferManagementStepperStatus.OfferRejected);

      return steps;
    }

    steps.push(OfferManagementStepperStatus.UnderNegotiation);

    if (isRejectedOnNegotiation) {
      steps.push(OfferManagementStepperStatus.OfferRejected);

      return steps;
    }

    const isAccepted = OfferManagementOfferDetailsService.getIsAccepted(offers);
    const isRejectedOnAccepted = isAccepted && isRejected;

    steps.push(OfferManagementStepperStatus.OfferAccepted);

    if (isRejectedOnAccepted) {
      steps.push(OfferManagementStepperStatus.OfferRescinded);

      return steps;
    }

    steps.push(OfferManagementStepperStatus.Sold);

    if (!isPossessionDatePassed || isPossessionDatePassed) {
      steps.push(OfferManagementStepperStatus.PossessionDay);

      return steps;
    }

    return steps;
  }
}

export default OfferManagementStepperService;

/* eslint-disable no-param-reassign */
export const SET_HERO_CONTENT = 'set-hero-content';
export const SET_CONTACT_CARDS_CONTENT = 'set-contact-cards-content';
export const SET_OFFICE_CONTENT = 'set-office-content';
export const SET_IS_CONTENT_LOADED = 'set-is-content-loaded';

export default {
  [SET_HERO_CONTENT](previousState, content) {
    previousState.heroContent = content;
  },
  [SET_CONTACT_CARDS_CONTENT](previousState, content) {
    previousState.contactContent = content;
  },
  [SET_OFFICE_CONTENT](previousState, content) {
    previousState.officeContent = content;
  },
  [SET_IS_CONTENT_LOADED](previousState, isLoaded) {
    previousState.isContentLoaded = isLoaded;
  },
};

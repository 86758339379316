import { NavListProperty } from '../enums';

// eslint-disable-next-line import/prefer-default-export
export const NavListProps = {
  props: {
    [NavListProperty.Links]: {
      default: () => ([]),
      type: Array,
    },
  },
};

/* eslint-disable camelcase */
import {
  SET_OFFER_MANAGEMENT_SELLER_CONTENT,
  SET_OFFER_MANAGEMENT_SELLER_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';
import { onSetOfferManagementContentData } from '../shared/offer-management/actions';

export const SET_OFFER_MANAGEMENT_SELLER_CONTENT_DATA = 'set-offer-management-seller-content';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_OFFER_MANAGEMENT_SELLER_CONTENT_DATA]({ commit }, data) {
    onSetOfferManagementContentData(data, (mappedContent) => {
      commit(SET_OFFER_MANAGEMENT_SELLER_CONTENT, mappedContent);
      commit(SET_OFFER_MANAGEMENT_SELLER_IS_CONTENT_LOADED, true);
    });
  },
};

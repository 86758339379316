import ArrayHelper from '@/ui-kit/helpers/ArrayHelper';

export const COVER_IMAGE_POSITION = 1;

class ImageOrderServiceEntity {
  #formArray;

  constructor(formArray) {
    this.#formArray = formArray;
  }

  get imagesCount() {
    return this.#formArray.value.length || 0;
  }

  get sortedByPositionImageControls() {
    return [...this.#formArray.controls].sort((a, b) => a.position - b.position);
  }

  get lastPosition() {
    const positions = this.#formArray.value?.map(({ position }) => position);

    return positions?.length ? Math.max(...positions) : 0;
  }

  get nextPosition() {
    return this.lastPosition + 1;
  }

  moveForward(imageFormGroup) {
    const currentPosition = imageFormGroup.position;

    if (this.getIsCover(imageFormGroup)) {
      return;
    }

    this.moveToPosition(imageFormGroup, currentPosition - 1);
  }

  moveBackward(imageFormGroup) {
    const currentPosition = imageFormGroup.position;

    if (this.getIsLast(imageFormGroup)) {
      return;
    }

    this.moveToPosition(imageFormGroup, currentPosition + 1);
  }

  makeCover(imageFormGroup) {
    const currentPosition = imageFormGroup.position;

    if (currentPosition === COVER_IMAGE_POSITION) {
      return;
    }

    this.moveToPosition(imageFormGroup, COVER_IMAGE_POSITION);
  }

  getControlByPosition(position) {
    return this.#formArray.controls.find((control) => control.position === position);
  }

  // eslint-disable-next-line class-methods-use-this
  getIsCover(imageFormGroup) {
    return imageFormGroup.position === COVER_IMAGE_POSITION;
  }

  getIsLast(imageFormGroup) {
    return imageFormGroup.position === this.lastPosition;
  }

  moveToPosition(imageFormGroup, toPosition) {
    const controlToReplacePosition = this.getControlByPosition(toPosition);

    controlToReplacePosition.setPosition(imageFormGroup.position);
    imageFormGroup.setPosition(toPosition);
  }

  reorderImages() {
    this.sortedByPositionImageControls.forEach((image, index) => {
      const expectedPosition = index + 1;

      if (image.position === expectedPosition) {
        return;
      }

      image.setPosition(expectedPosition);
    });
  }

  moveWithShift(oldIndex, newIndex) {
    const draftImageControls = this.sortedByPositionImageControls.slice();
    ArrayHelper.arrayMove(draftImageControls, oldIndex, newIndex);
    draftImageControls.forEach((image, index) => {
      image.setPosition(index + 1);
    });
  }
}

export default ImageOrderServiceEntity;

import { Constants, Services } from '@bode-canada/core';
import axios, { AxiosInstance } from 'axios';
import AuthService from '../AuthService';

declare module 'axios' {
  interface AxiosRequestConfig {
    isRetry?: boolean;
  }
}

const {
  StatusCode,
} = Constants;

const AuthInterceptor: Services.ResponseInterceptor = {
  getErrorCallback(axiosInstance: AxiosInstance) {
    return async (error: unknown) => {
      if (axios.isAxiosError(error)) {
        const originalRequest = error.config;
        if (originalRequest) {
          const isAuthError = error.response && [StatusCode.Unauthorized, StatusCode.Forbidden]
            .includes(error.response.status);

          if (isAuthError && !originalRequest.isRetry) {
            originalRequest.isRetry = true;
            try {
              await AuthService.refresh();
            } catch (awsError) {
              return Promise.reject(awsError);
            }
            return axiosInstance(originalRequest);
          }
        }
      }

      return Promise.reject(error);
    };
  },
};

export default AuthInterceptor;

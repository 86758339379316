import {
  OfferManagementStepperStatus,
} from '../enums';

export const PREPARING_STEPPER_STATUSES = [
  OfferManagementStepperStatus.Draft,
  OfferManagementStepperStatus.InProgress,
];

export const INITIAL_FOR_OPPONENT_STEPPER_STATUSES = [
  OfferManagementStepperStatus.OfferSubmitted,
  OfferManagementStepperStatus.OfferReceived,
  OfferManagementStepperStatus.OfflineOfferReceived,
  OfferManagementStepperStatus.OfflineOfferSubmitted,
];

export default {
  PREPARING_STEPPER_STATUSES,
  INITIAL_FOR_OPPONENT_STEPPER_STATUSES,
};

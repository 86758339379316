class OfferSubmitSuccessMapper {
  static mapPrismicDataToContent(data) {
    return {
      hero: this.#mapHero(data.hero)[0],
      title: data.page_title[0].text,
      links: this.#mapLinks(data.links),
    };
  }

  static #mapHero(steps) {
    return steps.map((step) => ({
      image: step.hero_image,
      title: step.hero_title,
      description: step.hero_description,
      button: {
        url: step.hero_button_url,
        title: step.hero_button_title,
      },
    }));
  }

  static #mapLinks(links) {
    return links.map((link) => ({
      description: link.link_description,
      url: link.link_url,
      title: link.link_title,
    }));
  }
}

export default OfferSubmitSuccessMapper;

import { LinkProperty } from '../enums';
import { StyleColorSchemeType } from '../../../styles/enums';

const LinkProps = {
  props: {
    [LinkProperty.ClassName]: {
      default: '',
      type: String,
    },
    [LinkProperty.WrapperClassName]: {
      default: '',
      type: String,
    },
    [LinkProperty.HasChevron]: {
      default: false,
      type: Boolean,
    },
    [LinkProperty.RedirectTo]: {
      required: true,
      type: String,
    },
    [LinkProperty.ColorScheme]: {
      default: StyleColorSchemeType.Primary,
      type: String,
    },
    [LinkProperty.IsUnderlined]: {
      default: false,
      type: Boolean,
    },
  },
};

export default LinkProps;

/* eslint-disable no-param-reassign */
export const SET_PANEL_CONTENT = 'set-panel-content';
export const SET_LOGIN_CONTENT = 'set-login-content';
export const SET_SUCCESSFUL_CONTENT = 'set-successful-content';
export const SET_IS_CONTENT_LOADED = 'set-is-content-loaded';
export default {
  [SET_PANEL_CONTENT](previousState, content) {
    previousState.panelContent = content;
  },
  [SET_LOGIN_CONTENT](previousState, content) {
    previousState.loginContent = content;
  },
  [SET_SUCCESSFUL_CONTENT](previousState, content) {
    previousState.successfulContent = content;
  },
  [SET_IS_CONTENT_LOADED](previousState, isLoaded) {
    previousState.isContentLoaded = isLoaded;
  },
};


import Link from '@/ui-kit/components/link';
import { StyleColorSchemeType } from '@/ui-kit/styles/enums';
import { LinkProperty } from '@/ui-kit/components/link/enums';
import { track } from '@/utils/analytics';
import Logo from '@/components/icons/logo';
import { FooterTrackEvent } from '@/constants/AnalyticEvents';
import routes from '@/constants/routes';

export default {
  name: 'FooterLogoSectionLogo',
  components: {
    Link,
    Logo,
  },
  data() {
    return {
      StyleColorSchemeType,
      LinkProperty,
      routes,
    };
  },
  methods: {
    handleLogoClick() {
      track(FooterTrackEvent.BodeLogoClick);
    },
  },
};

// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import throttle from 'lodash.throttle';
import { Breakpoints } from '@/ui-kit/styles/constants';

const HIDE_WHEN_RESIZE_EVENT_NAME = 'hide-when-plugin-window-resize';
const HIDE_WHEN_SMALLER_DIRECTIVE_NAME = 'hide-when-smaller';
const HIDE_WHEN_GREATER_DIRECTIVE_NAME = 'hide-when-greater';

class HideElementHelper {
  constructor(element) {
    this.element = element;
    this.removeChild = document.createComment('');
  }

  removeElement() {
    if (!this.element.parentNode) {
      return;
    }

    this.element.parentNode.replaceChild(this.removeChild, this.element);
  }

  revertElement() {
    if (!this.removeChild.parentNode) {
      return;
    }

    this.removeChild.parentNode.replaceChild(this.element, this.removeChild);
  }
}

// noinspection JSUnusedGlobalSymbols
const HideWhenPlugin = {
  install(VueInstance) {
    const eventBus = new VueInstance();

    const addEventToHideElement = (element, getIsHiddenCallback) => {
      const hideElementHelperInstance = new HideElementHelper(element);

      eventBus.$on(HIDE_WHEN_RESIZE_EVENT_NAME, (value) => {
        if (getIsHiddenCallback(value)) {
          hideElementHelperInstance.removeElement();

          return;
        }

        hideElementHelperInstance.revertElement();
      });
    };

    window.addEventListener('resize', throttle(() => {
      eventBus.$emit(HIDE_WHEN_RESIZE_EVENT_NAME, window.innerWidth);
    }, 300));

    VueInstance.directive(HIDE_WHEN_SMALLER_DIRECTIVE_NAME, {
      bind(element, binding) {
        addEventToHideElement(element, (currentWidth) => (
          currentWidth <= Breakpoints[binding.value]
        ));
      },
      inserted() {
        eventBus.$emit(HIDE_WHEN_RESIZE_EVENT_NAME, window.innerWidth);
      },
    });

    VueInstance.directive(HIDE_WHEN_GREATER_DIRECTIVE_NAME, {
      bind(element, binding) {
        addEventToHideElement(element, (currentWidth) => (
          currentWidth >= Breakpoints[binding.value]
        ));
      },
      inserted() {
        eventBus.$emit(HIDE_WHEN_RESIZE_EVENT_NAME, window.innerWidth);
      },
    });
  },
};

Vue.use(HideWhenPlugin);

export const OffersManagementTableCustomColumnKey = {
  OfferStatus: 'offer-status',
  OfferSummary: 'offer-summary',
};

export const OffersManagementTableColumnKey = {
  ...OffersManagementTableCustomColumnKey,
  OfferAmount: 'offer-amount',
  AgentCommission: 'agent-commission',
  PriceMinusCommission: 'price-minus-commission',
  Conditions: 'conditions',
  PossessionDate: 'possession-date',
  SubmittedDate: 'submitted-date',
  ExpiryDate: 'expiry-date',
};

export const OffersManagementTableOfferStatus = {
  Initial: 'Initial Offer',
  Counter: 'Counter',
  Rejected: 'Rejected',
  Accepted: 'Accepted',
};

export const OfferManagementExpirationTimeStatus = {
  NA: 'N/A',
  Expired: 'Expired',
};

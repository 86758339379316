export const EMAIL_REGEX = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,4}$/;
export const CASE_INSENSITIVE_EMAIL_REGEX = /^([A-Za-z0-9_-]+\.)*[A-Za-z0-9_-]+@[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*\.[A-Za-z]{2,4}$/;
export const PHONE_REGEXP = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const TIME_REGEXP = /^(\d{1,2}:){1,2}\d{1,2}(\s([AaPp][Mm]))?$/;
export const URL_REGEXP = /(https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%\-/]))?/;

export const EMAIL_FORMAT_ERROR_MESSAGE = 'Invalid email';

export const FILE_IS_TOO_BIG = 'File is too big';

export const PHONE_FORMAT_ERROR_MESSAGE = 'Invalid phone number';
export const TIME_FORMAT_ERROR_MESSAGE = 'Invalid time';
export const REQUIRED_ERROR_MESSAGE = 'Field is required';
export const URL_FORMAT_ERROR_MESSAGE = 'Invalid URL format';


import Icon from '@/ui-kit/components/icon';
import { IconType } from '@/ui-kit/components/icon/enums';
import { StyleColorSchemeType, StyleSizeType } from '@/ui-kit/styles/enums';

export default {
  name: 'ScrollButton',
  components: {
    Icon,
  },
  props: {
    iconType: {
      type: String,
      default: IconType.ChevronUp,
    },
    colorScheme: {
      type: String,
      default: StyleColorSchemeType.Secondary,
    },
    size: {
      type: String,
      default: StyleSizeType.Large,
    },
  },
  data() {
    return {
      IconType,
      StyleColorSchemeType,
      StyleSizeType,
    };
  },
};

class ArrayHelper {
  static getRange(from, to) {
    const range = [];

    if (to < from) {
      return range;
    }

    if (to === from) {
      return [from];
    }

    for (let i = from; i <= to; i++) {
      range.push(i);
    }

    return range;
  }

  static arrayMove(array, fromIndex, toIndex) {
    const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

    if (startIndex >= 0 && startIndex < array.length) {
      const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

      const [item] = array.splice(fromIndex, 1);
      array.splice(endIndex, 0, item);
    }
  }
}

export default ArrayHelper;

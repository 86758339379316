// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify, {
  theme: {
    primary: '#009FD4',
    accent: '#009FD4',
    error: '#B71C1C',
    light: '#ffffff',
  },
});

/* eslint-disable camelcase */
import {
  SET_OFFER_SUBMIT_SUCCESS_CONTENT,
  SET_OFFER_SUBMIT_SUCCESS_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';
import OfferSubmitSuccessMapper from './mappers';

export const SET_OFFER_SUBMIT_SUCCESS_CONTENT_DATA = 'set-offer-submit-success-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_OFFER_SUBMIT_SUCCESS_CONTENT_DATA]({ commit }, data) {
    const content = OfferSubmitSuccessMapper.mapPrismicDataToContent(data);

    commit(SET_OFFER_SUBMIT_SUCCESS_CONTENT, content);
    commit(SET_OFFER_SUBMIT_SUCCESS_IS_CONTENT_LOADED, true);
  },
};

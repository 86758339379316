export const TypographyType = {
  Header1: 'h1',
  Header2: 'h2',
  Header3: 'h3',
  Header4: 'h4',
  Header5: 'h5',
  Header6: 'h6',
  Paragraph: 'p',
  Span: 'span',
};

export const TypographyProperty = {
  Type: 'type',
  ColorScheme: 'colorScheme',
  FontWeight: 'fontWeight',
  Alignment: 'alignment',
  LineThrough: 'lineThrough',
};

import { FooterLinkEventType } from '../enums';

const FooterLinkEvents = {
  methods: {
    handleLinkClick(eventData) {
      this.$emit(FooterLinkEventType.Click, eventData);
    },
    makeLinkEventData(label, linkType) {
      return { label, linkType };
    },
  },
};

export default FooterLinkEvents;

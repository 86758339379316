/* eslint-disable no-param-reassign */
export const SET_TITLE_CONTENT = 'set-hero-content';
export const SET_CARD_CONTENT = 'set-card-content';
export const SET_IS_CONTENT_LOADED = 'set-is-content-loaded';

export default {
  [SET_TITLE_CONTENT](previousState, content) {
    previousState.titleContent = content;
  },
  [SET_CARD_CONTENT](previousState, content) {
    previousState.cardContent = content;
  },
  [SET_IS_CONTENT_LOADED](previousState, isLoaded) {
    previousState.isContentLoaded = isLoaded;
  },
};

import { Services, Enums } from '@bode-canada/core';
import AuthInterceptor from '../interceptors/AuthInterceptor';

const { ServerlessApiPathEnum } = Enums;
const serverlessProxyUrl = process.env.NUXT_ENV_SERVERLESS_PROXY_URL
  || process.env.NUXT_ENV_PROXY_URL;

export const getBaseUrl = (): string => {
  if (process.client) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${process.env.NUXT_ENV_API}/${ServerlessApiPathEnum.SERVERLESS}`;
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${serverlessProxyUrl}${process.env.NUXT_ENV_API}/${ServerlessApiPathEnum.SERVERLESS}`;
};

export default new Services.ApiService(
  { baseURL: getBaseUrl() },
  { response: [AuthInterceptor] },
);

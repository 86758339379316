// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import throttle from 'lodash.throttle';

const RESIZE_DIRECTIVE_NAME = 'element-resize';
const THROTTLE_DELAY = 200;

class ResizeObserversStore {
  constructor() {
    this.observers = new Map();
  }

  addObserver(element, listener) {
    const observer = new ResizeObserver(throttle((entries) => {
      const [entry] = entries;

      listener(entry);
    }, THROTTLE_DELAY));

    this.observers.set(element, observer);

    observer.observe(element);
  }

  removeObserver(element) {
    const observer = this.observers.get(element);

    observer.disconnect();

    this.observers.delete(element);
  }
}

// noinspection JSUnusedGlobalSymbols
const ResizeObserverPlugin = {
  install(VueInstance) {
    const resizeObserversStore = new ResizeObserversStore();

    VueInstance.directive(RESIZE_DIRECTIVE_NAME, {
      inserted(element, bind) {
        resizeObserversStore.addObserver(element, bind.value);
      },
      unbind(element) {
        resizeObserversStore.removeObserver(element);
      },
    });
  },
};

Vue.use(ResizeObserverPlugin);

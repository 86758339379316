
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '../../styles/enums';
import { AccordionEventType, AccordionProperty, AccordionSlotName } from './enums';
import Icon from '../icon';
import { IconType } from '../icon/enums';
import Gutter from '../gutter';
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import Typography from '../typography';
import { TypographyType } from '../typography/enums';
import AccordionProps from './mixins/AccordionProps';

const DEFAULT_STATUS_SETTINGS = {
  showIcon: IconType.Add,
  showText: '',
  hideIcon: IconType.Minus,
  hideText: '',
  colorScheme: StyleColorSchemeType.Secondary,
};

export default {
  name: 'Accordion',
  components: { Typography, Gutter, Icon },
  mixins: [
    AccordionProps,
  ],
  data() {
    return {
      isExpanded: this[AccordionProperty.DefaultIsExpanded],
      IconType,
      AccordionSlotName,
      AccordionProperty,
      StylePositionType,
      StyleSizeType,
      TypographyType,
    };
  },
  computed: {
    combinedStatusSettings() {
      return {
        ...DEFAULT_STATUS_SETTINGS,
        ...this[AccordionProperty.StatusSettings],
      };
    },
    conditionStatusSettings() {
      return this.isExpanded
        ? {
          iconType: this.combinedStatusSettings.hideIcon,
          text: this.combinedStatusSettings.hideText,
        }
        : {
          iconType: this.combinedStatusSettings.showIcon,
          text: this.combinedStatusSettings.showText,
        };
    },
    headerClassNames() {
      return new StyleClassNamesBuilder('accordion-header')
        .addClassName(this[AccordionProperty.HeaderClassName])
        .addConditionClassName('disabled', this[AccordionProperty.IsDisabled])
        .build();
    },
    contentClassNames() {
      return new StyleClassNamesBuilder('accordion-content')
        .addClassName(this[AccordionProperty.ContentClassName])
        .addConditionClassName('expanded', this.isExpanded)
        .addConditionClassName('collapsed', !this.isExpanded)
        .build();
    },
    arrowClassNames() {
      return new StyleClassNamesBuilder('accordion-header__icon')
        .addConditionClassName('expanded', this.isExpanded)
        .build();
    },
  },
  watch: {
    [AccordionProperty.IsDisabled]: {
      handler(isDisabled) {
        if (isDisabled) {
          this.isExpanded = false;
        }
      },
    },
    [AccordionProperty.DefaultIsExpanded]: {
      handler() {
        if (this[AccordionProperty.IsRadioSelect]) {
          this.isExpanded = this[AccordionProperty.DefaultIsExpanded];
        }
      },
    },
  },
  methods: {
    handleHeaderClick() {
      if (this[AccordionProperty.IsDisabled]) {
        return;
      }

      this.toggle();
      this.$emit(AccordionEventType.Toggle, this.isExpanded);
    },
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
  },
};

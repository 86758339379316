import Auth from '@aws-amplify/auth';
import { log } from '../debug';

const debug = log('authentication');

/**
 * Configure AWS cognito and return configuration.
 * @type {Object}
 */
if (process.client) {
  configure();
}

/**
 * User sign up.
 *
 * @param {String} email
 * @param {String} password
 * @param {Object} attributes
 * @return {Promise}
 * @public
 */

export function signup(email, password, attributes = {}) {
  const lowercaseEmail = email.toLowerCase();
  debug(`signup ${lowercaseEmail}:${password}`);
  return Auth.signUp({
    username: lowercaseEmail,
    password,
    attributes: {
      email: lowercaseEmail,
      ...attributes,
    },
  });
}

/**
 * User sign in.
 * Returns the user as found in our user memory cache.
 *
 * @param {String} email
 * @param {String} password
 * @return {Promise<Object>}
 * @public
 */

export function signin(email, password) {
  const lowercaseEmail = email.toLowerCase();
  debug(`signin ${lowercaseEmail}:${password}`);
  return Auth.signIn(lowercaseEmail, password);
}

/**
 * Federated User sign in (OAUTH users).
 *
 * @param {String} provider
 * @param {String} customState
 * @return {Promise<Object>}
 * @public
 */

export async function federatedSignIn(provider, customState) {
  debug(`federated signin ${provider}:${customState}`);
  // @note with response type code, state is not passed to URL
  sessionStorage.setItem('bode:auth:customState', customState);

  return Auth.federatedSignIn({
    provider,
    customState,
  });
}

/**
 * User sign out.
 *
 * Sign out global will disconnect all devices
 * connected.
 *
 * @note we could use global: true to revoke all access tokens
 * but we could have conflicts and not be able to remove cookies (because token
 * has been revoked somewhere else)
 *
 * @return {Promise}
 * @public
 */

export async function signout() {
  debug('signout');
  try {
    return await Auth.signOut();
  } catch (error) {
    window.console.log('error signout:', error);
    return null;
  }
}

export async function deleteUser() {
  const user = await Auth.currentAuthenticatedUser();

  return new Promise((resolve, reject) => user.deleteUser((err, result) => {
    if (err) {
      reject(err);
    } else {
      resolve(result);
    }
  }));
}

/**
 * Resend confirmation code.
 *
 * @param {String} email
 * @return {Promise}
 * @private
 */

export function resendSignUp(email) {
  const lowercaseEmail = email.toLowerCase();
  debug(`resend confirmation code ${lowercaseEmail}`);
  return Auth.resendSignUp(lowercaseEmail);
}

/**
 * User account confirmation.
 *
 * @param {String} username
 * @param {String} code
 * @return {Promise}
 * @public
 */

export function confirm(username, code) {
  const lowercaseUsername = username.toLowerCase();
  debug(`confirm ${lowercaseUsername}:${code}`);
  return Auth.confirmSignUp(lowercaseUsername, code);
}

/**
 * Forgot password flow.
 *
 * Kicks in the reset password flow, an email will be sent
 * with a link or reset code.
 *
 * @param {String} email
 * @return {Promise}
 * @public
 */

export function forgot(email) {
  const lowercaseEmail = email.toLowerCase();
  debug(`forgot ${lowercaseEmail}`);
  return Auth.forgotPassword(lowercaseEmail);
}

/**
 * Reset password.
 *
 * @param {String} email
 * @param {String} code
 * @param {String} password
 */

export function reset(email, code, password) {
  const lowercaseEmail = email.toLowerCase();
  debug(`reset password ${lowercaseEmail}:${password}`);
  return Auth.forgotPasswordSubmit(lowercaseEmail, code, password);
}

/**
 * Get user information.
 *
 * @note User attributes are saved in memory or fetch from cognito (in case memory is cleared and
 * authentication cookies exist).
 *
 * @param {Boolean} shouldRefresh
 * @return {Promise}
 * @public
 */

export async function getCurrentToken(shouldRefresh) {
  debug('get current authenticated user');
  if (shouldRefresh) return getCurrentSession();
  const { attributes } = await Auth.currentAuthenticatedUser();
  return attributes;
}

/**
 * Returns access token needed for api calls.
 * This method will automatically refresh the access token and id token if expired.
 * It will also return a valid refresh token.
 *
 * @return {Promise}
 * @private
 */

export async function getCurrentSession() {
  debug('get session and refresh token');
  const { idToken } = await Auth.currentSession();
  return idToken.payload;
}

/**
 * Update user information.
 *
 * @param {Object} info
 * @return {Promise}
 * @public
 */

export async function updateCurrentToken(info) {
  debug('update user info');
  const user = await Auth.currentAuthenticatedUser();
  return Auth.updateUserAttributes(user, {
    ...info,
  });
}

/**
 * Returns redirection URL used for OAuth.
 * Protocol is https for all domain except localhost.
 *
 * @note hostname is host origin without protocol or port.
 *
 * @param {String} hostname
 * @param {String} path
 * @returns {String}
 * @public
 */

export function getRedirectUrl(hostname, path = '/login/idp') {
  const bool = hostname === 'localhost';
  return `${bool ? 'http' : 'https'}://${hostname}${bool ? `:${window.location.port}` : ''}${path}`;
}

/**
 * Configure cognito authentication.
 *
 * @private
 */

function configure() {
  const { hostname } = window.location;
  return Auth.configure({
    region: process.env.NUXT_ENV_COGNITO_REGION,
    identityPoolRegion: process.env.NUXT_ENV_COGNITO_REGION,
    userPoolId: process.env.NUXT_ENV_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.NUXT_ENV_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.NUXT_ENV_COGNITO_AUTH_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: getRedirectUrl(hostname),
      redirectSignOut: getRedirectUrl(hostname, '/logout'),
      responseType: 'code',
    },
    cookieStorage: cookieStorage(hostname),
  });
}

/**
 * Generate cookie storage information based on hostname.
 * On localhost, we don't need to secure the cookie storage.
 *
 * @param {String} hostname
 * @returns {Object}
 * @private
 */

function cookieStorage(hostname) {
  const isLocalhost = hostname === 'localhost';
  let domain = isLocalhost ? 'localhost' : getDomain(hostname);
  if (domain === 'cloudfront.net') domain = hostname;
  // domain: (isLocalhost ? '' : '.') + hostname,
  return {
    domain,
    path: '',
    expires: 365,
    sameSite: 'lax',
    secure: !isLocalhost,
  };
}

/**
 * Get cookie domain.
 *
 * @param {String} hostname
 * @returns {String}
 * @private
 */

function getDomain(hostname) {
  const chunks = hostname.split('.');
  return chunks.splice(-2).join('.');
}

/**
 * Update user cognito data.
 *
 * @param {Object} data
 * @return {Promise}
 * @public
 */

export async function updateCurrentUserCognitoData(data) {
  const user = await Auth.currentAuthenticatedUser();
  return Auth.updateUserAttributes(user, {
    ...data,
  });
}

/**
 * Verify user attribute.
 *
 * @param {String} attribute
 * @return {Promise}
 * @private
 */

export async function verifyUserAttribute(attribute) {
  return Auth.verifyCurrentUserAttribute(attribute);
}

/**
 * Confirm user attribute.
 *
 * @param {String} email
 * @param {String} code
 * @return {Promise}
 * @private
 */

export function confirmAttribute(attribute, code) {
  return Auth.verifyCurrentUserAttributeSubmit(attribute, code);
}


import Accordion from '@/ui-kit/components/accordion';
import { AccordionSlotName, AccordionProperty } from '@/ui-kit/components/accordion/enums';
import { IconType } from '@/ui-kit/components/icon/enums';
import FooterMobileNavigationAccordionHeader from './accordion-header';
import FooterMobileNavigationAccordionContent from './accordion-content';
import FooterLinkEvents from '../shared/mixins/LinkEvents';
import { FooterLinkEventType } from '../shared/enums';

export default {
  name: 'FooterMobileNavigationAccordion',
  components: {
    Accordion,
    FooterMobileNavigationAccordionHeader,
    FooterMobileNavigationAccordionContent,
  },
  mixins: [FooterLinkEvents],
  props: {
    linkGroupName: {
      required: true,
      type: String,
    },
    links: {
      default: () => ([]),
      type: Array,
    },
  },
  data() {
    return {
      AccordionSlotName,
      AccordionProperty,
      FooterLinkEventType,
    };
  },
  computed: {
    accordionStatusSettings() {
      return {
        showIcon: IconType.ChevronDown,
        hideIcon: IconType.ChevronUp,
      };
    },
  },
};

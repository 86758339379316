export default () => ({
  isContentLoaded: false,
  heroContent: {},
  statContent: {
    title: '',
    linkText: '',
    list: [],
  },
  informationContent: {
    title: '',
    list: [],
  },
  footerContent: {
    section: {},
    list: [],
  },
});

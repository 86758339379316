export const ImageEventType = {
  Load: 'load',
  Error: 'error',
};

export const ImagePropsEventType = {
  Error: 'error',
};

export const ImageProperty = {
  Src: 'src',
  Alt: 'alt',
  ImageClassName: 'imageClassName',
  ReserveSrc: 'reserveSrc',
};

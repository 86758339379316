import { PROVINCES } from '@/constants';
import {
  BuyerCommissionRatesByProvince,
  SellerCommissionRatesByProvince,
  MAX_BODE_COMMISSION,
  BODE_COMMISSION_RATE,
} from './constants';

export default class CommissionHelper {
  static getBuyerCommission(price, province = PROVINCES.AB) {
    return this.getCommissionByRate(BuyerCommissionRatesByProvince[province], price);
  }

  static getSellerCommission(price, province = PROVINCES.AB) {
    return this.getCommissionByRate(SellerCommissionRatesByProvince[province], price);
  }

  static getBodeCommission(price) {
    const estimatedCommission = price * (BODE_COMMISSION_RATE / 100);

    return this.#roundValue(Math.min(estimatedCommission, MAX_BODE_COMMISSION));
  }

  static getCommissionByRate(commissionRates, price) {
    const { commission } = (commissionRates || []).reduce(
      (accum, rate) => (price < accum.previousThresholdPrice
        ? accum
        : this.#getNextCommissionState(
          accum,
          rate,
          rate.thresholdPrice < price ? rate.thresholdPrice : price,
        )),
      { commission: 0, previousThresholdPrice: 0 },
    );

    return this.#roundValue(commission);
  }

  static #getNextCommissionState(previousState, rate, moneyChunk) {
    const { commission, previousThresholdPrice } = previousState;

    return {
      commission: commission + (moneyChunk - previousThresholdPrice) * (rate.value / 100),
      previousThresholdPrice: rate.thresholdPrice,
    };
  }

  static #roundValue(value) {
    return Math.round(value);
  }
}

/* eslint-disable no-param-reassign */
export const SET_HERO_CONTENT = 'set-hero-content';
export const SET_DESCRIPTION_CONTENT = 'set-description-content';
export const SET_CARD_TITLE = 'set-card-title';
export const SET_CARD_CONTENT = 'set-card-content';
export const SET_HOW_IT_WORKS_TITLE = 'set-how-it-works-title';
export const SET_HOW_IT_WORKS_CONTENT = 'set-how-it-works-content';
export const SET_FOOTER_CONTENT = 'set-footer-content';
export const SET_IS_CONTENT_LOADED = 'set-is-content-loaded';
export default {
  [SET_HERO_CONTENT](previousState, content) {
    previousState.heroContent = content;
  },
  [SET_DESCRIPTION_CONTENT](previousState, content) {
    previousState.descriptionContent = content;
  },
  [SET_CARD_TITLE](previousState, content) {
    previousState.cardTitle = content;
  },
  [SET_CARD_CONTENT](previousState, content) {
    previousState.cardContent = content;
  },
  [SET_HOW_IT_WORKS_TITLE](previousState, content) {
    previousState.howItWorksTitle = content;
  },
  [SET_HOW_IT_WORKS_CONTENT](previousState, content) {
    previousState.howItWorksContent = content;
  },
  [SET_FOOTER_CONTENT](previousState, content) {
    previousState.footerContent = content;
  },
  [SET_IS_CONTENT_LOADED](previousState, isLoaded) {
    previousState.isContentLoaded = isLoaded;
  },
};

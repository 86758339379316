/* eslint-disable camelcase */
import {
  SET_HERO_CONTENT,
  SET_INFORMATION_CONTENT,
  SET_LEADERSHIP_CONTENT,
  SET_FOOTER_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      hero,
      information_section,
      leadership_section,
      footer_section,
    } = data;

    const [heroData] = hero;
    const [informationData] = information_section;

    commit(SET_HERO_CONTENT, heroData);
    commit(SET_INFORMATION_CONTENT, informationData);
    commit(SET_LEADERSHIP_CONTENT, leadership_section);
    commit(SET_FOOTER_CONTENT, footer_section);
    commit(SET_IS_CONTENT_LOADED, true);
  },
};


import GutterProps from './mixins/GutterProps';
import { GutterProperty } from './enums';
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';

export default {
  name: 'Gutter',
  mixins: [GutterProps],
  computed: {
    gutterClassNames() {
      return new StyleClassNamesBuilder('gutter')
        .addPositionConditionClassNames(this[GutterProperty.Position])
        .addSizeConditionClassNames(this[GutterProperty.Size])
        .build();
    },
  },
};

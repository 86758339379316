const isClient = process.client;

export default {
  isPresent(key) {
    return isClient && !!localStorage.getItem(key);
  },
  remove(key) {
    if (isClient) {
      localStorage.removeItem(key);
    }
  },
  add(key, value) {
    if (isClient && value) {
      localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
    }
  },
  fetch(key) {
    let data = null;
    if (isClient && localStorage.getItem(key)) {
      try {
        data = JSON.parse(localStorage.getItem(key));
      } catch {
        data = localStorage.getItem(key);
      }
    }
    return data;
  },
};

export const ButtonType = {
  Submit: 'submit',
  Reset: 'reset',
  Button: 'button',
};

export const ButtonPropsEventType = {
  Click: 'click',
};

export const ButtonFillType = {
  Filled: 'planned',
  Outlined: 'outlined',
  Underlined: 'underlined',
  Text: 'text',
};

export const ButtonProperty = {
  ClassName: 'className',
  RedirectType: 'redirectType',
  RedirectTo: 'redirectTo',
  Type: 'type',
  ColorScheme: 'colorScheme',
  Fill: 'fill',
  Size: 'size',
  IsDisabled: 'isDisabled',
  IsLoading: 'isLoading',
  ShowIcon: 'showIcon',
  IconColor: 'iconColor',
};

export default {
  ButtonType,
  ButtonPropsEventType,
  ButtonFillType,
};

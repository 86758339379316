
import StyleClassNamesBuilder from '@/ui-kit/styles/helpers/StyleClassNamesBuilder';
import { StyleColorSchemeType } from '@/ui-kit/styles/enums';

export default {
  name: 'Logo',
  props: {
    colorScheme: {
      type: String,
      default: StyleColorSchemeType.Black,
    },
  },
  computed: {
    logoClassNames() {
      return new StyleClassNamesBuilder('logo')
        .addColorSchemeConditionClassNames(this.colorScheme)
        .build();
    },
  },
};

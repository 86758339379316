import { SET_FAVOURITES } from './mutations';
import STORE_NAME from './name';
import ListingsApiService from '@/services/api/listings';
import {
  COVER_IMAGE_POSITION,
} from '@/components/features/listing-flow/shared/components/controls/images/entities/ImageOrderServiceEntity';

export const INIT_FAVOURITES = 'init-favourites';
export const REMOVE_FAVOURITES = 'remove-favourites';
export const ADD_FAVOURITES = 'add-favourites';
export const UPDATE_FAVOURITE_NOTES = 'update-favourite-notes';
export const getAction = (type) => `${STORE_NAME}/${type}`;
export default {
  async [INIT_FAVOURITES]({ commit, state }) {
    const favourites = state.favourites?.length
      ? state.favourites
      : await ListingsApiService.getFavourites();

    commit(SET_FAVOURITES, favourites);

    return favourites;
  },
  async [REMOVE_FAVOURITES]({ commit, state: { favourites } }, { listingId }) {
    const wasRequestSuccessful = await ListingsApiService.removeFromFavourites(listingId)
      .then(() => true)
      .catch(() => false);

    if (wasRequestSuccessful) {
      commit(SET_FAVOURITES, favourites.filter((favourite) => favourite.listingId !== listingId));
    }

    return wasRequestSuccessful;
  },
  async [ADD_FAVOURITES]({ commit, state: { favourites } }, listing) {
    const wasRequestSuccessful = await ListingsApiService.addToFavourites(listing.listingId)
      .then(() => true)
      .catch(() => false);

    if (wasRequestSuccessful) {
      commit(SET_FAVOURITES, favourites.concat([{
        ...listing,
        image: listing.image
          || listing.images?.find((image) => image.position === COVER_IMAGE_POSITION),
      }]));
    }

    return wasRequestSuccessful;
  },
  async [UPDATE_FAVOURITE_NOTES]({ commit, state: { favourites } }, { listingId, notes }) {
    const wasRequestSuccessful = await ListingsApiService.saveFavouriteNotes(listingId, notes)
      .then(() => true)
      .catch(() => false);

    if (wasRequestSuccessful) {
      const updatedFavourites = favourites
        .map((listing) => (listing.listingId === listingId ? { ...listing, notes } : listing));

      commit(SET_FAVOURITES, updatedFavourites);
    }

    return wasRequestSuccessful;
  },
};

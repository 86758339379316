
import { NavLinkProperty } from '../nav-link/enums';
import NavLink from '../nav-link';
import { NavListProps } from './mixins/NavListProps';

export default {
  name: 'NavList',
  components: {
    NavLink,
  },
  mixins: [NavListProps],
  data() {
    return {
      NavLinkProperty,
    };
  },
  methods: {
    getLinkKey(index) {
      return `${index}-nav-list-link`;
    },
  },
};

/* eslint-disable camelcase */
import {
  SET_HERO_CONTENT,
  SET_DESCRIPTION_CONTENT,
  SET_MARKET_DATA_CONTENT,
  SET_SOLD_DATA_CONTENT,
  SET_COMPARABLE_CONTENT,
  SET_FOOTER_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      hero_section,
      description_section,
      market_data_section,
      sold_data_section,
      comparable_section,
      footer_section,
    } = data;

    const [heroData] = hero_section;
    const [descriptionData] = description_section;
    const [marketData] = market_data_section;
    const [soldData] = sold_data_section;
    const [comparableData] = comparable_section;
    const [footerData] = footer_section;

    commit(SET_HERO_CONTENT, heroData);
    commit(SET_DESCRIPTION_CONTENT, descriptionData);
    commit(SET_MARKET_DATA_CONTENT, marketData);
    commit(SET_SOLD_DATA_CONTENT, soldData);
    commit(SET_COMPARABLE_CONTENT, comparableData);
    commit(SET_FOOTER_CONTENT, footerData);
    commit(SET_IS_CONTENT_LOADED, true);
  },
};

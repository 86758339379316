import { StyleSizeType } from '../enums';

// eslint-disable-next-line import/prefer-default-export
export const Breakpoints = {
  [StyleSizeType.XSmall]: 400,
  [StyleSizeType.Small]: 600,
  [StyleSizeType.Medium]: 960,
  [StyleSizeType.Large]: 1264,
  [StyleSizeType.XLarge]: 1904,
  [StyleSizeType.XXLarge]: 3480,
};

import routes from '@/constants/routes';

export const SELL_WITH_BODE = {
  label: 'Sell with Bōde',
  nestedLinks: [
    {
      label: 'Sell your home',
      redirectTo: routes.sellYourHome,
    },
    {
      label: 'My Listings',
      redirectTo: routes.myListings,
    },
    {
      label: 'Offers',
      redirectTo: routes.offersManagementSeller,
    },
    {
      label: 'Seller Checklist',
      redirectTo: routes.sellerChecklist,
    },
  ],
};

export const SEARCH_FOR_HOMES = {
  label: 'Search for homes',
  nestedLinks: [
    {
      label: 'Find a home',
      redirectTo: routes.search,
    },
    {
      label: 'Offers',
      redirectTo: routes.offerManagementBuyer,
    },
    {
      label: 'Saved Search List',
      redirectTo: `${routes.search}${routes.savedSearchList}`,
    },
    {
      label: 'Favourites',
      redirectTo: routes.favourites,
    },
  ],
};

export const SOLD_DATA = {
  label: 'Sold Data',
  redirectTo: routes.soldData,
};

export const MARKET_DATA = {
  label: 'Market Data',
  redirectTo: routes.marketData,
};

export const COMPARABLES = {
  label: 'Comparables',
  redirectTo: routes.comparables,
};

export const ADMIN_NAV_LINK = {
  label: 'Admin',
  nestedLinks: [
    {
      label: 'Users',
      redirectTo: routes.users,
    },
    {
      label: 'Offer Management',
      redirectTo: routes.adminOfferManagement,
    },
    {
      label: 'Builder Report Management',
      redirectTo: routes.adminBuilderReportManagement,
    },
    {
      label: 'Showings',
      redirectTo: routes.adminShowings,
    },
    {
      label: 'Import Listings',
      redirectTo: routes.adminListingImport,
    },
    {
      label: 'Update Listings',
      redirectTo: routes.adminListingUpdate,
    },
    {
      label: 'Listing Management',
      redirectTo: routes.listingManagement,
    },
  ],
};

export const CONTRACTOR_PRO_MARKET_PLACE_NAV_LINK = {
  label: 'Prō Marketplace',
  redirectTo: routes.proMarketplace,
};

export const CONTRACTOR_MY_REQUESTS_NAV_LINK = {
  label: 'My Requests',
  redirectTo: routes.proMarketplaceServiceRequests,
};

export const CONTRACTOR_MESSAGES_NAV_LINK = {
  label: 'Messages',
  redirectTo: routes.messages,
};

export const COMMON_NAV_LINKS = [
  SELL_WITH_BODE,
  SEARCH_FOR_HOMES,
  SOLD_DATA,
  MARKET_DATA,
  COMPARABLES,
];

export const ADMIN_NAV_LINKS = [
  ...COMMON_NAV_LINKS,
  ADMIN_NAV_LINK,
];

export const CONTRACTOR_NAV_LINKS = [
  CONTRACTOR_PRO_MARKET_PLACE_NAV_LINK,
  CONTRACTOR_MY_REQUESTS_NAV_LINK,
  CONTRACTOR_MESSAGES_NAV_LINK,
];

export const HEADER_HEIGHT = 70;

export default {
  COMMON_NAV_LINKS,
  ADMIN_NAV_LINKS,
  CONTRACTOR_NAV_LINKS,
  HEADER_HEIGHT,
};

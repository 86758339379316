import routes from '@/constants/routes';
import { ExternalLinks } from '@/utils/external-navigation/constants';
import {
  FooterLinkGroup,
  FooterLinkProperty,
  FooterLinkType,
} from '../shared/enums';

const FOOTER_COMPANY_LINKS = [
  {
    [FooterLinkProperty.RedirectTo]: routes.whyBode,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Why Bōde',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.whoWeAre,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Who We Are',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: ExternalLinks.BodeProofs,
    [FooterLinkProperty.Type]: FooterLinkType.External,
    [FooterLinkProperty.Data]: {
      label: 'Proof',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: ExternalLinks.Partners,
    [FooterLinkProperty.Type]: FooterLinkType.External,
    [FooterLinkProperty.Data]: {
      label: 'Partners',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: ExternalLinks.Sustainability,
    [FooterLinkProperty.Type]: FooterLinkType.External,
    [FooterLinkProperty.Data]: {
      label: 'Sustainability',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.contact,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Contact',
    },
  },
];

const FOOTER_HOW_IT_WORKS_LINKS = [
  {
    [FooterLinkProperty.RedirectTo]: routes.howItWorksSeller,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Sellers',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.howItWorksBuyer,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Buyers',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.howItWorksBuilder,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Builders',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.proMarketPlaceForPros,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Prō Marketplace',
    },
  },
];

const FOOTER_GET_STARTED_LINKS = [
  {
    [FooterLinkProperty.RedirectTo]: routes.sellYourHome,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Sell your home',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.buySellYourHome,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Buy & Sell with Bōde',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.buyYourHome,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Buy a home',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.proMarketplace,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Find a Prō',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.requestSticker,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Request a sticker',
    },
  },
];

const FOOTER_LEARN_LINKS = [
  {
    [FooterLinkProperty.RedirectTo]: routes.sellerChecklist,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Seller\'s Checklist',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.buyerChecklist,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Buyer\'s Checklist',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: ExternalLinks.BlogBode,
    [FooterLinkProperty.Type]: FooterLinkType.External,
    [FooterLinkProperty.Data]: {
      label: 'Blog',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: ExternalLinks.EventsBode,
    [FooterLinkProperty.Type]: FooterLinkType.External,
    [FooterLinkProperty.Data]: {
      label: 'Events',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: ExternalLinks.HelpBode,
    [FooterLinkProperty.Type]: FooterLinkType.External,
    [FooterLinkProperty.Data]: {
      label: 'Resource Centre',
    },
  },
];

const FOOTER_TOOLS_LINKS = [
  {
    [FooterLinkProperty.RedirectTo]: routes.myAbodeOverview,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'My Abōde Overview',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.marketData,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Market Data',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.soldData,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Sold Data',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.comparables,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Comparables',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: routes.commissionCalculator,
    [FooterLinkProperty.Type]: FooterLinkType.Internal,
    [FooterLinkProperty.Data]: {
      label: 'Calculate Commission',
    },
  },
];

const FOOTER_OFFICES_LINKS = [
  {
    [FooterLinkProperty.RedirectTo]: ExternalLinks.CalgaryGoogleMaps,
    [FooterLinkProperty.Type]: FooterLinkType.Complex,
    [FooterLinkProperty.Data]: {
      label: 'Office',
      title: 'Calgary',
      street: '1550 - 5th Street SW (2nd Floor)',
      region: 'Calgary, AB., T2R 1K3',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: `tel:+${15876023307}`,
    [FooterLinkProperty.Type]: FooterLinkType.Complex,
    [FooterLinkProperty.Data]: {
      label: 'Calgary Office Phone',
      phone: '(587) 602-3307',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: `tel:+${16479560533}`,
    [FooterLinkProperty.Type]: FooterLinkType.Complex,
    [FooterLinkProperty.Data]: {
      label: 'Toronto Office Phone',
      title: 'Toronto',
      phone: '(647) 956-0533',
    },
  },
  {
    [FooterLinkProperty.RedirectTo]: `tel:+${16043375352}`,
    [FooterLinkProperty.Type]: FooterLinkType.Complex,
    [FooterLinkProperty.Data]: {
      label: 'Office',
      title: 'Vancouver',
      phone: '(604) 337-5352',
    },
  },
];

export const FOOTER_LINKS = {
  [FooterLinkGroup.Company]: FOOTER_COMPANY_LINKS,
  [FooterLinkGroup.HowItWorks]: FOOTER_HOW_IT_WORKS_LINKS,
  [FooterLinkGroup.GetStarted]: FOOTER_GET_STARTED_LINKS,
  [FooterLinkGroup.Learn]: FOOTER_LEARN_LINKS,
  [FooterLinkGroup.DataTools]: FOOTER_TOOLS_LINKS,
  [FooterLinkGroup.Offices]: FOOTER_OFFICES_LINKS,
};

export default {
  FOOTER_LINKS,
};

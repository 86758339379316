import type { Enums } from '@bode-canada/core';
import {
  OffersManagementOfferDtoField,
  OffersManagementUserDtoField,
} from '@/services/api/offers-management/enums';
import { ListingOffersInterface } from '@/types/offer-management';

class OffersManagementFilterService {
  static getFirstOffer(offers: ListingOffersInterface[]): ListingOffersInterface {
    const [lastOffer] = this.getAskSortedOffersByDate(offers);

    return lastOffer;
  }

  static getLastOffer(offers: ListingOffersInterface[]): ListingOffersInterface {
    const [lastOffer] = this.getDescSortedOffersByDate(offers);

    return lastOffer;
  }

  static getLastOfferByRole(
    offers: ListingOffersInterface[],
    role: Enums.UserRoleEnum,
  ): ListingOffersInterface {
    const sortedOffers = this.getDescSortedOffersByDate(offers);
    const [lastOfferByRole] = sortedOffers.filter((offer) => (
      offer[OffersManagementUserDtoField.UserRole] === role
    ));

    return lastOfferByRole;
  }

  static getAskSortedOffersByDate(offers: ListingOffersInterface[]): ListingOffersInterface[] {
    return this.#getSortedOffersByDate(offers);
  }

  static getDescSortedOffersByDate(offers: ListingOffersInterface[]): ListingOffersInterface[] {
    return this.#getSortedOffersByDate(offers, true);
  }

  static #getSortedOffersByDate(offers: ListingOffersInterface[], isDesk: boolean = false) {
    return [...offers].sort((a, b) => {
      const aDate = new Date(a[OffersManagementOfferDtoField.OfferUpdatedDate]);
      const bDate = new Date(b[OffersManagementOfferDtoField.OfferUpdatedDate]);

      if (isDesk) {
        return bDate.valueOf() - aDate.valueOf();
      }

      return aDate.valueOf() - bDate.valueOf();
    });
  }
}

export default OffersManagementFilterService;

export default () => ({
  isContentLoaded: false,
  heroContent: {},
  contactCards: {},
  officeContent: {
    title: '',
    subtitle: '',
    list: [],
  },
});

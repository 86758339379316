import { ToastShowingMode, ToastVariant } from '@/ui-kit/components/toast/enums';
import { getAction, OPEN_TOAST, CLOSE_TOAST } from '@/store/toast/actions';

class ToastService {
  #store = null;

  setStore(store) {
    this.#store = store;
  }

  openPositive(title, showingMode) {
    this.#openToast(title, ToastVariant.Positive, showingMode);
  }

  openNegative(title, showingMode) {
    this.#openToast(title, ToastVariant.Negative, showingMode);
  }

  openNeutral(title, showingMode) {
    this.#openToast(title, ToastVariant.Neutral, showingMode);
  }

  openDefault(title, showingMode) {
    this.#openToast(title, ToastVariant.Info, showingMode);
  }

  #openToast(title, variant, showingMode = ToastShowingMode.Temporary) {
    this.#store.dispatch(getAction(OPEN_TOAST), {
      title,
      variant,
      showingMode,
    });
  }

  closeToast() {
    this.#store.dispatch(getAction(CLOSE_TOAST));
  }
}

export default new ToastService();

import Auth from '@aws-amplify/auth';

class AuthService {
  static async refresh() {
    return this.#getIdToken();
  }

  static async signOut() {
    return Auth.signOut();
  }

  static async #getIdToken() {
    const { idToken } = await Auth.currentSession();

    return idToken.payload;
  }
}

export default AuthService;

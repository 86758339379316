/* eslint-disable camelcase */
import {
  SET_HERO_CONTENT,
  SET_DESCRIPTION_CONTENT,
  SET_CARD_TITLE,
  SET_CARD_CONTENT,
  SET_HOW_IT_WORKS_TITLE,
  SET_HOW_IT_WORKS_CONTENT,
  SET_FOOTER_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      hero_section,
      description_section,
      card_section_title,
      card_section,
      how_it_works_title,
      how_it_works_section,
      footer_section,
    } = data;

    const [heroData] = hero_section;
    const [descriptionData] = description_section;
    const [footerData] = footer_section;

    commit(SET_HERO_CONTENT, heroData);
    commit(SET_DESCRIPTION_CONTENT, descriptionData);
    commit(SET_CARD_TITLE, card_section_title);
    commit(SET_CARD_CONTENT, card_section);
    commit(SET_HOW_IT_WORKS_TITLE, how_it_works_title);
    commit(SET_HOW_IT_WORKS_CONTENT, how_it_works_section);
    commit(SET_FOOTER_CONTENT, footerData);
    commit(SET_IS_CONTENT_LOADED, true);
  },
};


import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import DividerProps from './mixins/DividerProps';
import { StyleOrientationType } from '../../styles/enums';

export default {
  name: 'Divider',
  mixins: [DividerProps],
  computed: {
    dividerClassNames() {
      return new StyleClassNamesBuilder('divider')
        .addConditionClassNames(Object.values(StyleOrientationType), this.orientation)
        .addClassName(this.className)
        .build();
    },
  },
};

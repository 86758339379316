/* eslint-disable camelcase */
import {
  SET_HERO_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      hero_header,
      hero_image,
      hero_text,
      carousel_items,
    } = data;

    const heroData = {
      title: hero_header,
      image: hero_image,
      description: hero_text,
      carouselItems: carousel_items,
    };

    commit(SET_HERO_CONTENT, heroData);
    commit(SET_IS_CONTENT_LOADED, true);
  },
};

/* eslint-disable no-param-reassign */
export const SET_TOAST_TITLE = 'set-toast-title';
export const SET_TOAST_VARIANT = 'set-toast-variant';
export const SET_TOAST_IS_OPEN = 'set-toast-is-open';
export const SET_TOAST_SHOWING_MODE = 'set-toast-showing-mode';

export default {
  [SET_TOAST_TITLE](previousState, title) {
    previousState.title = title;
  },
  [SET_TOAST_VARIANT](previousState, variant) {
    previousState.variant = variant;
  },
  [SET_TOAST_IS_OPEN](previousState, isOpen) {
    previousState.isOpen = isOpen;
  },
  [SET_TOAST_SHOWING_MODE](previousState, showingMode) {
    previousState.showingMode = showingMode;
  },
};

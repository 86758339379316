/* eslint-disable no-param-reassign */
import {
  setOfferManagementContent,
  setOfferManagementIsContentLoaded,
} from '../shared/offer-management/mutations';

export const SET_OFFER_MANAGEMENT_SELLER_CONTENT = 'set-offer-management-content';
export const SET_OFFER_MANAGEMENT_SELLER_IS_CONTENT_LOADED = 'set-offer-management-is-content-loaded';

export default {
  [SET_OFFER_MANAGEMENT_SELLER_CONTENT]: setOfferManagementContent,
  [SET_OFFER_MANAGEMENT_SELLER_IS_CONTENT_LOADED]: setOfferManagementIsContentLoaded,
};

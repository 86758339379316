export enum GridColumnType {
  Text = 'text',
  Money = 'money',
  Number = 'number',
  Date = 'date',
  Custom = 'custom',
}

export const GridHeaderPropsEventType = {
  Sort: 'sort',
};

export const GridSortType = {
  Asc: 'ASC',
  Desc: 'DESC',
};

export enum GridSortFnType {
  Alphabet = 'alphabet',
  Number = 'number',
  Date = 'date',
  Custom = 'custom',
}

export enum GridColumnCommonOptionsType {
  Sortable = 'sortable',
  IsSticky = 'is-sticky',
  EmptyCellPlaceholder = 'empty-cell-placeholder',
}

export const GridColumnNumberOptionsType = {
  ...GridColumnCommonOptionsType,
  NumberFormat: 'number-format',
};

export const GridColumnMoneyOptionsType = {
  ...GridColumnCommonOptionsType,
  Currency: 'currency',
};

export const GridColumnDateOptionsType = {
  ...GridColumnCommonOptionsType,
  DateFormat: 'date-format',
};

export enum GridCellCommonPropsType {
  Value = 'value',
  ColorSchema = 'color-schema',
  FillColorSchema = 'fill-color-schema',
}

export enum GridSizePropsType {
  Breakpoint = 'breakpoint',
  TableSize = 'table-size',
  NotCollapsedColumnsAmount = 'not-collapsed-columns-amount',
}

export default {
  GridColumnType,
  GridCellCommonPropsType,
  GridSizePropsType,
  GridColumnDateOptionsType,
};


import { track } from '@/utils/analytics';
import List from '@/ui-kit/components/list';
import ListItem from '@/ui-kit/components/list-item';
import Avatar from '@/ui-kit/components/avatar';
import MenuTooltip from '@/ui-kit/components/menu-tooltip';
import { MenuTooltipSlotName } from '@/ui-kit/components/menu-tooltip/enums';
import { IconType } from '@/ui-kit/components/icon/enums';
import { StyleSizeType, StylePositionType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import routes from '@/constants/routes';
import ExternalNavigationHelper from '@/utils/external-navigation';
import { ExternalLinks } from '@/utils/external-navigation/constants';
import { HeaderPropsEventType } from '@/components/header/enums';

export default {
  name: 'HeaderAvatar',
  components: {
    List,
    ListItem,
    MenuTooltip,
    Avatar,
  },
  props: {
    user: {
      type: Object,
      default: () => ({ isAuthenticated: false, roles: {} }),
    },
    isBuilderDataSub: {
      type: Boolean,
      default: false,
    },
    isHidden: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      routes,
      MenuTooltipSlotName,
      IconType,
      StyleSizeType,
      StylePositionType,
      StyleColorSchemeType,
    };
  },
  computed: {
    avatarSrc() {
      return this.user.picture || '/img/profile.png';
    },
    isContractor() {
      if (!this.user.roles) {
        return false;
      }

      return this.user.roles.contractor;
    },
    isHomeownerDashboardItemDisplayed() {
      return !!this.user.propertyOwnerships?.length;
    },
  },
  methods: {
    handleListItemClick(route) {
      this.$router.push(route);
    },
    handleResourcesClick() {
      track('Resources nav click', {});
      ExternalNavigationHelper.openInNewWindow(ExternalLinks.HelpBode);
    },
    handleSignOutClick() {
      this.$emit(HeaderPropsEventType.SignOut);
    },
  },
};

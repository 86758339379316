export const ListItemPropsEventType = {
  Click: 'click',
};

export const ListItemProperty = {
  Icon: 'icon',
  IconColor: 'iconColor',
  ClassName: 'className',
  IconSize: 'iconSize',
  AppendIcon: 'appendIcon',
  Label: 'label',
  Value: 'value',
  Disabled: 'disabled',
  IsActive: 'isActive',
};


import { StyleSizeType } from '../../styles/enums';
import AvatarProps from './mixins/AvatarProps';
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import { AvatarSlotName, AvatarProperty } from './enums';
import BaseImage from '../image';

export default {
  name: 'Avatar',
  components: {
    BaseImage,
  },
  mixins: [AvatarProps],
  data() {
    return {
      AvatarSlotName,
      AvatarProperty,
      hasImageError: false,
    };
  },
  computed: {
    avatarClassNames() {
      return new StyleClassNamesBuilder('avatar')
        .addConditionClassNames(Object.values(StyleSizeType), this[AvatarProperty.Size])
        .build();
    },
    isImageVisible() {
      return this.src && !this.hasImageError;
    },
  },
  methods: {
    handleImageError() {
      this.hasImageError = true;
    },
  },
};

import { ToastProperty, ToastShowingMode } from '../enums';

const ToastProps = {
  props: {
    [ToastProperty.Title]: {
      default: '',
      type: String,
    },
    [ToastProperty.Variant]: {
      default: '',
      type: String,
    },
    [ToastProperty.IsOpen]: {
      default: false,
      type: Boolean,
    },
    [ToastProperty.CloseCallback]: {
      required: true,
      type: Function,
    },
    [ToastProperty.ShowingMode]: {
      default: ToastShowingMode.Temporary,
      type: String,
    },
  },
};

export default ToastProps;

import { Enums } from '@bode-canada/core';
import {
  OffersManagementListingDtoField,
  OffersManagementOfferDtoField,
  OffersManagementUserDtoField,
} from '@/services/api/offers-management/enums';
import OfferManagementStepperService
  from '@/components/page-specific/offers-management/services/OfferManagementStepperStatusService';
import OfferManagementTableMapService
  from '@/components/page-specific/offers-management/services/OfferManagementTableMapService';
import OffersManagementFilterService
  from '@/components/page-specific/offers-management/services/OffersManagementFilterService';
import OfferManagementOfferDetailsService
  from '@/components/page-specific/offers-management/services/OfferManagementOfferDetailsService';
import DateHelper from '@/ui-kit/helpers/DateHelper';
import {
  ListingOffers,
  ListingOffersInterface, OfferConditionsInterface,
  OfferManagementInterface,
  OfferManagementOffers,
} from '@/types/offer-management';
import { UserListingDto } from '@/types/user';
import { OfferManagementStepperStatus } from '@/components/page-specific/offers-management/stepper/enums';

const { ListingStatusEnum } = Enums;

export type ListingOfferOldDto = {
  listingAdditionalTitleHolders?: UserListingDto[];
  listingAddress: string;
  listingBaths: number;
  listingBeds: number;
  listingFinishedSqft: number;
  listingCity: string;
  listingId: number;
  listingImage: string;
  listingOffers?: OfferOldDto[];
  listingOrigin: Enums.ListingOriginEnum;
  listingPrice: number;
  listingStatus: Enums.ListingStatusEnum;
  listingLatestUpdatedDate: Date | null;
};

export type OfferOldDto = {
  negotiationsSteps: OfferManagementStepperStatus[];
  negotiationsCurrentStep: OfferManagementStepperStatus | null;
  tableRows: unknown[];
  firstOffer: ListingOffersInterface;
  offerExpirationDate: Date;
  offerId: number;
  listingId: number;
  listingOwnerFirstName: string,
  listingOwnerLastName: string,
  listingOwnerId: number;
  opponentFirstName: string;
  opponentLastName: string;
  opponentId: number;
  opponentLastOfferPrice: number;
  lastOfferUpdatedDate: Date;
  lastOfferNumberOfConditions: number;
  lastOfferRole: Enums.UserRoleEnum;
  buyerConditions: OfferConditionsInterface[];
  sellerConditions: OfferConditionsInterface[];
  signers: never[];
  isCounterOffer: boolean;
  offerBuyerIsRealtor: boolean;
  offerIsOfflineOffer: boolean;
};

type MappedOffersTuple = {
  latestUpdatedDate: Date | null,
  mappedOffers: OfferOldDto[],
};

class OffersManagementMapper {
  /* eslint-disable */
  static mapPrismicDataToContent(data: any) {
    return {
      didKnowItems: this.#mapDidKnowItems(data.did_know),
      heroSteps: this.#mapHeroSteps(data.hero_steps),
      title: data.page_title,
      accordionTitle: data.accordion_title,
      didKnowTitle: data.did_know_title,
    };
  }

  static #mapHeroSteps(steps: any) {
    return steps.map((step: any) => ({
      image: step.hero_steps_image,
      title: step.hero_steps_title,
      description: step.hero_steps_description,
      points: step.hero_steps_items.split('\n'),
    }));
  }

  static #mapDidKnowItems(items: any) {
    return items.map((item: any) => ({
      title: item.did_know_description,
      link: item.did_know_link,
    }));
  }
  /* eslint-enable */

  static mapListingsOffers(
    listingsOffers: OfferManagementOffers,
    role: Enums.UserRoleEnum,
  ): ListingOfferOldDto[] {
    const allListingOffersEntries = Object.entries(
      listingsOffers,
    ) as [string, OfferManagementInterface][];
    const listingsWithoutOffers: ListingOfferOldDto[] = [];
    const listingsWithOffers: ListingOfferOldDto[] = [];

    allListingOffersEntries.forEach(([listingId, listingData]) => {
      const listingOffers = listingData[OffersManagementListingDtoField.ListingOffers];
      const listingImage = listingData[OffersManagementListingDtoField.ListingImage];
      const listingAddress = listingData[OffersManagementListingDtoField.ListingAddress];
      const listingStatus = listingData[OffersManagementListingDtoField.ListingStatus];
      const listingOrigin = listingData[OffersManagementListingDtoField.ListingOrigin];
      const listingCity = listingData[OffersManagementListingDtoField.ListingCity];
      const listingPrice = listingData[OffersManagementListingDtoField.ListingPrice];
      const listingBeds = listingData[OffersManagementListingDtoField.ListingBeds];
      const listingBaths = listingData[OffersManagementListingDtoField.ListingBaths];
      const listingFinishedSqft = listingData[OffersManagementListingDtoField.ListingFinishedSqft];
      const { mappedOffers, latestUpdatedDate } = this.#mapOffer(listingOffers, listingData, role);

      const listingOfferDetails: ListingOfferOldDto = {
        listingImage,
        listingAddress,
        listingCity,
        listingPrice: Number(listingPrice),
        listingStatus,
        listingId: Number(listingId),
        listingOrigin,
        listingBeds,
        listingBaths,
        listingFinishedSqft,
        listingOffers: mappedOffers,
        listingLatestUpdatedDate: latestUpdatedDate,
      };

      if (mappedOffers.length) {
        listingsWithOffers.push(listingOfferDetails);
      } else {
        listingsWithoutOffers.push(listingOfferDetails);
      }
    });

    return [
      ...listingsWithOffers,
      ...listingsWithoutOffers,
    ].filter((listingOffers) => ![
      ListingStatusEnum.DRAFT,
      ListingStatusEnum.NEW,
      ListingStatusEnum.HIDDEN,
      ListingStatusEnum.DELETED,
    ].includes(listingOffers.listingStatus));
  }

  static #mapOffer(
    listingOffers: ListingOffers | undefined,
    listingData: OfferManagementInterface,
    role: Enums.UserRoleEnum,
  ): MappedOffersTuple {
    const listingOffersEntries = Object.entries(
      listingOffers || {},
    ) as [string, ListingOffersInterface[]][];
    let latestUpdatedDate: Date | null = null;

    const mappedOffers = listingOffersEntries.map(([offerId, offerData]): OfferOldDto => {
      const negotiations = OffersManagementFilterService.getAskSortedOffersByDate(offerData);
      const { steps, status, isCounterOffer } = OfferManagementStepperService.getStepperData(
        negotiations,
        role,
        listingData,
      );
      const areTableRowsHidden = OfferManagementOfferDetailsService.getIsPreparingStatus(status);
      const { listingId } = listingData;
      const firstOffer = OffersManagementFilterService.getFirstOffer(negotiations);
      const lastOffer = OffersManagementFilterService.getLastOffer(negotiations);
      const { offerExpirationDate } = lastOffer;
      const lastOfferRole = lastOffer[OffersManagementUserDtoField.UserRole];
      const offerBuyerIsRealtor = lastOffer[
        OffersManagementOfferDtoField.OfferBuyerIsRealtor];
      const offerIsOfflineOffer = lastOffer[
        OffersManagementOfferDtoField.OfferIsOfflineOffer];
      const signers: never[] = [];
      const lastOfferNumberOfConditions: number = OfferManagementOfferDetailsService
        .getNumberOfConditions(lastOffer) as number;
      const lastOfferUpdatedDate = new Date(
        lastOffer[OffersManagementOfferDtoField.OfferUpdatedDate],
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const tableRows = areTableRowsHidden
        ? []
        : OfferManagementTableMapService.getTableRowsFromOffers(negotiations);
      const opponentRole = role === Enums.UserRoleEnum.SELLER
        ? Enums.UserRoleEnum.BUYER
        : Enums.UserRoleEnum.SELLER;
      const opponentLastOffer = OffersManagementFilterService.getLastOfferByRole(
        negotiations,
        opponentRole,
      );
      const listingOwnerFirstName = lastOffer[
        OffersManagementListingDtoField.ListingOwnerFirstName
      ];
      const listingOwnerLastName = lastOffer[OffersManagementListingDtoField.ListingOwnerLastName];
      const listingOwnerId = lastOffer[OffersManagementListingDtoField.ListingOwnerId];
      const opponentFirstName = opponentLastOffer
        ? opponentLastOffer[OffersManagementUserDtoField.UserFirstName]
        : listingOwnerFirstName;
      const opponentLastName = opponentLastOffer
        ? opponentLastOffer[OffersManagementUserDtoField.UserLastName]
        : listingOwnerLastName;
      const opponentId = opponentLastOffer
        ? opponentLastOffer[OffersManagementUserDtoField.UserId]
        : listingOwnerId;
      const opponentLastOfferPrice = lastOffer[OffersManagementOfferDtoField.OfferPrice];
      const buyerConditions = lastOffer[OffersManagementOfferDtoField.OfferBuyerConditions] || [];
      const sellerConditions = lastOffer[OffersManagementOfferDtoField.OfferSellerConditions] || [];

      latestUpdatedDate = !latestUpdatedDate
        ? lastOfferUpdatedDate
        : DateHelper.getLatestDate([latestUpdatedDate, lastOfferUpdatedDate]);

      return {
        negotiationsSteps: steps as OfferManagementStepperStatus[],
        negotiationsCurrentStep: status,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        tableRows,
        firstOffer,
        offerExpirationDate: new Date(offerExpirationDate),
        offerId: Number(offerId),
        listingId,
        listingOwnerFirstName,
        listingOwnerLastName,
        listingOwnerId,
        opponentFirstName,
        opponentLastName,
        opponentId,
        opponentLastOfferPrice,
        lastOfferUpdatedDate,
        lastOfferNumberOfConditions,
        lastOfferRole,
        buyerConditions,
        sellerConditions,
        signers,
        isCounterOffer,
        offerBuyerIsRealtor,
        offerIsOfflineOffer,
      };
    });

    return { mappedOffers, latestUpdatedDate };
  }
}

export default OffersManagementMapper;

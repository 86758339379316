import { RedirectType } from '../../../enums';
import { ButtonFillType, ButtonProperty, ButtonType } from '../enums';
import { StyleColorSchemeType, StyleSizeType } from '../../../styles/enums';

const ButtonProps = {
  props: {
    [ButtonProperty.ClassName]: {
      default: '',
      type: String,
    },
    [ButtonProperty.RedirectType]: {
      default: RedirectType.ThisWindow,
      type: String,
    },
    [ButtonProperty.RedirectTo]: {
      default: '',
      type: String,
    },
    [ButtonProperty.Type]: {
      default: ButtonType.Button,
      type: String,
    },
    [ButtonProperty.ColorScheme]: {
      default: StyleColorSchemeType.Primary,
      type: String,
    },
    [ButtonProperty.Fill]: {
      default: ButtonFillType.Filled,
      type: String,
    },
    [ButtonProperty.Size]: {
      default: StyleSizeType.Medium,
      type: String,
    },
    [ButtonProperty.IsDisabled]: {
      default: false,
      type: Boolean,
    },
    [ButtonProperty.IsLoading]: {
      default: false,
      type: Boolean,
    },
    [ButtonProperty.ShowIcon]: {
      default: false,
      type: Boolean,
    },
    [ButtonProperty.IconColor]: {
      default: null,
      type: String,
    },
  },
};

export default ButtonProps;

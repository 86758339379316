import merge from 'deepmerge';
import { log } from '../debug';

const debug = log('user');

/**
 * Memory cache.
 * @note we could use other persistent storage.
 * @type {Object}
 */

let cache;

/**
 * Singleton user cache.
 *
 * @private
 */
const initStore = () => {
  if (!cache) {
    cache = JSON.parse(window.localStorage.getItem('bode:cache'));
  }
};

export default {
  /**
   * Returns cache.
   *
   * @returns {Object}
   * @private
   */

  get() {
    initStore();
    debug(cache == null ? 'get empty cache' : 'get cache');
    // return memory cache for speed
    return cache;
  },

  /**
   * Set cache with data.
   * By default, this method will merge data with the current cache but
   * data can  be forced.
   *
   * @param {Object} data
   * @param {Boolean} force
   * @returns {Object}
   * @public
   */

  set(data, force) {
    initStore();
    debug('set cache');
    cache = force ? data : merge(cache, data);
    window.localStorage.setItem('bode:cache', JSON.stringify(cache));
    return cache;
  },

  /**
   * Reset cache (set it to null).
   *
   * @note we will call this method if the user is not authenticated
   * but the cache exist.
   *
   * @returns {Object}
   * @public
   */

  reset() {
    debug('reset cache');
    window.localStorage.removeItem('bode:cache');
    cache = null;

    return cache;
  },
};

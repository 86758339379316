
import NotFoundPage from '../pages/404-error';

export default {
  components: {
    NotFoundPage,
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    console.error(this.error);
  },
};

/* eslint-disable no-param-reassign */
export const SET_ALL_YOU_NEED_CONTENT = 'set-all-you-need-content';
export const SET_BUY_YOUR_HOME_CONTENT = 'set-buy-your-home-content';
export const SET_SELL_YOUR_HOME_CONTENT = 'set-sell-your-home-content';
export const SET_PHONE_CONTENT = 'set-phone-content';
export const SET_BODE_FEATURES_CONTENT = 'set-bode-features-content';
export const SET_CUSTOMER_FEEDBACK_CONTENT = 'set-customer-feedback-content';
export const SET_SOLD_PREVIEW_CONTENT = 'set-sold-preview-content';
export const SET_FEATURED_IN_CONTENT = 'set-featured-in-content';
export const SET_BODE_IS_SECURE_CONTENT = 'set-bode-is-secure-content';
export const SET_IS_CONTENT_LOADED = 'set-is-content-loaded';

export default {
  [SET_ALL_YOU_NEED_CONTENT](previousState, content) {
    previousState.allYouNeedContent = content;
  },
  [SET_BUY_YOUR_HOME_CONTENT](previousState, content) {
    previousState.buyYourHomeContent = content;
  },
  [SET_SELL_YOUR_HOME_CONTENT](previousState, content) {
    previousState.sellYourHomeContent = content;
  },
  [SET_BODE_FEATURES_CONTENT](previousState, content) {
    previousState.bodeFeaturesContent = content;
  },
  [SET_PHONE_CONTENT](previousState, content) {
    previousState.phoneContent = content;
  },
  [SET_CUSTOMER_FEEDBACK_CONTENT](previousState, content) {
    previousState.customerFeedbackContent = {
      title: content.customer_feedback_header,
      list: content.customer_feedback,
    };
  },
  [SET_SOLD_PREVIEW_CONTENT](previousState, content) {
    previousState.soldPreviewContent = {
      title: content.sold_preview_header,
      linkText: content.sold_preview_link_text,
      list: content.sold_preview,
    };
  },
  [SET_FEATURED_IN_CONTENT](previousState, content) {
    previousState.featuredInContent = {
      title: content.featured_in_header,
      list: content.featured_in,
    };
  },
  [SET_BODE_IS_SECURE_CONTENT](previousState, content) {
    previousState.bodeIsSecureContent = content;
  },
  [SET_IS_CONTENT_LOADED](previousState, isLoaded) {
    previousState.isContentLoaded = isLoaded;
  },
};

import { GutterProperty } from '../enums';
import { StyleSizeType, StylePositionType } from '../../../styles/enums';

const GutterProps = {
  props: {
    [GutterProperty.Size]: {
      default: StyleSizeType.Medium,
      type: String,
    },
    [GutterProperty.Position]: {
      default: StylePositionType.Bottom,
      type: String,
    },
  },
};

export default GutterProps;

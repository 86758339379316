export const AGREEMENT = '/agreement';
export const ListingsPath = {
  Root: '/listings',
  Find: '/find',
  Favourites: '/favourites',
  Shares: '/shares',
  SavedSearches: '/saved-searches',
  SoldData: '/sold-data',
  Sold: '/sold',
  SendShareEmail: '/sendShareListingEmail',
  RequestInfo: '/requestInfo',
  Availability: '/availability',
  Agreement: AGREEMENT,
  AgreementStatus: `${AGREEMENT}/status`,
  AgreementResend: `${AGREEMENT}/resend`,
  LinkAddress: '/linkAddress',
  Address: '/address',
  Relist: '/relist',
  ChangeVisibility: '/change-visibility',
  Schedule: '/schedule',
};

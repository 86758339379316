/* eslint-disable camelcase */
import {
  SET_HERO_CONTENT,
  SET_STAT_CONTENT,
  SET_INFORMATION_CONTENT,
  SET_FOOTER_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;
export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      hero_images,
      hero_title,
      hero_sub_header,
      hero_text,
      hero_button,
      stat_title,
      stat_link,
      stat_section,
      information_title,
      information_section,
      footer_section,
      footer_buttons,
    } = data;

    const [heroImages] = hero_images;
    const [heroTitle] = hero_title;
    const [heroSubHeader] = hero_sub_header;
    const [heroText] = hero_text;
    const [heroButton] = hero_button;
    const heroData = {
      heroImages,
      heroTitle,
      heroSubHeader,
      heroText,
      heroButton,
    };
    const [footerData] = footer_section;

    commit(SET_HERO_CONTENT, heroData);
    commit(SET_STAT_CONTENT, {
      stat_title,
      stat_link,
      stat_section,
    });
    commit(SET_INFORMATION_CONTENT, {
      information_title,
      information_section,
    });
    commit(SET_FOOTER_CONTENT, {
      footerData,
      footer_buttons,
    });
    commit(SET_IS_CONTENT_LOADED, true);
  },
};

/* eslint-disable no-param-reassign */
export const SET_HERO_CONTENT = 'set-hero-content';
export const SET_DESCRIPTION_CONTENT = 'set-description-content';
export const SET_MARKET_DATA_CONTENT = 'set-market-data-content';
export const SET_SOLD_DATA_CONTENT = 'set-sold-data-content';
export const SET_COMPARABLE_CONTENT = 'set-comparable-content';
export const SET_FOOTER_CONTENT = 'set-footer-content';
export const SET_IS_CONTENT_LOADED = 'set-is-content-loaded';
export default {
  [SET_HERO_CONTENT](previousState, content) {
    previousState.heroContent = content;
  },
  [SET_DESCRIPTION_CONTENT](previousState, content) {
    previousState.descriptionContent = content;
  },
  [SET_MARKET_DATA_CONTENT](previousState, content) {
    previousState.marketDataContent = content;
  },
  [SET_SOLD_DATA_CONTENT](previousState, content) {
    previousState.soldDataContent = content;
  },
  [SET_COMPARABLE_CONTENT](previousState, content) {
    previousState.comparableContent = content;
  },
  [SET_FOOTER_CONTENT](previousState, content) {
    previousState.footerContent = content;
  },
  [SET_IS_CONTENT_LOADED](previousState, isLoaded) {
    previousState.isContentLoaded = isLoaded;
  },
};

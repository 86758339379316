export const FooterLinkGroup = {
  GetStarted: 'Get Started',
  HowItWorks: 'How it Works',
  Company: 'Company',
  Learn: 'Learn',
  DataTools: 'Data Tools',
  Offices: 'Offices',
};

export const FooterLinkType = {
  External: 'external',
  Internal: 'internal',
  Complex: 'complex',
};

export const FooterLinkProperty = {
  Data: 'data',
  RedirectTo: 'redirectTo',
  Type: 'type',
  ClassName: 'className',
};

export const FooterLinkEventType = {
  Click: 'click',
};

export const FooterViewMode = {
  Desktop: 'Desktop',
  Mobile: 'Mobile',
};

export default {
  FooterLinkGroup,
  FooterLinkType,
  FooterLinkProperty,
  FooterLinkEventType,
};

/* eslint-disable camelcase */
import {
  SET_HEADER_CONTENT,
  SET_DESCRIPTION_CONTENT,
  SET_CONFIRM_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';
import { DEFAULT_CONFIRM_CONTENT } from './constants';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      form_header,
      form_text,
      blue_section_header,
      blue_section_text,
      thank_you_header,
      thank_you_text,
      thank_you_image,
    } = data;

    const headerData = {
      title: form_header,
      description: form_text,
    };

    const descriptionData = {
      title: blue_section_header,
      description: blue_section_text,
    };

    const confirmData = {
      ...DEFAULT_CONFIRM_CONTENT,
      header: thank_you_header,
      text: thank_you_text,
      image: thank_you_image,
    };

    commit(SET_HEADER_CONTENT, headerData);
    commit(SET_DESCRIPTION_CONTENT, descriptionData);
    commit(SET_CONFIRM_CONTENT, confirmData);
    commit(SET_IS_CONTENT_LOADED, true);
  },
};

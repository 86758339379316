import { PROVINCES } from '@/constants';

export const ThresholdPrice = {
  Min: 100000,
  Max: Infinity,
};

export const MAX_BODE_COMMISSION = 10000;
export const BODE_COMMISSION_RATE = 1;

export const BuyerCommissionRatesByProvince = {
  [PROVINCES.AB]: [
    {
      value: 3.5,
      thresholdPrice: ThresholdPrice.Min,
    },
    {
      value: 1.5,
      thresholdPrice: ThresholdPrice.Max,
    },
  ],
  [PROVINCES.BC]: [
    {
      value: 3.22,
      thresholdPrice: ThresholdPrice.Min,
    },
    {
      value: 1.15,
      thresholdPrice: ThresholdPrice.Max,
    },
  ],
  [PROVINCES.ON]: [
    {
      value: 2.5,
      thresholdPrice: ThresholdPrice.Max,
    },
  ],
};

export const SellerCommissionRatesByProvince = {
  [PROVINCES.AB]: [
    {
      value: 7,
      thresholdPrice: ThresholdPrice.Min,
    },
    {
      value: 3,
      thresholdPrice: ThresholdPrice.Max,
    },
  ],
  [PROVINCES.BC]: [
    {
      value: 7,
      thresholdPrice: ThresholdPrice.Min,
    },
    {
      value: 2.5,
      thresholdPrice: ThresholdPrice.Max,
    },
  ],
  [PROVINCES.ON]: [
    {
      value: 5,
      thresholdPrice: ThresholdPrice.Max,
    },
  ],
};

import { LayoutContainerProperty } from '../enums';
import { StyleSizeType } from '../../../../styles/enums';

const LayoutContainerProps = {
  props: {
    [LayoutContainerProperty.Spacing]: {
      type: String,
      default: StyleSizeType.Small,
    },
  },
};

export default LayoutContainerProps;

export const OfferManagementStepperStatus = {
  Draft: 'Draft',
  InProgress: 'In Progress',
  Received: 'Offer Submitted',
  OfferSubmitted: 'Offer Submitted',
  OfferReceived: 'Offer Received',
  OfferViewed: 'Offer Viewed',
  UnderNegotiation: 'Negotiation',
  OfferAccepted: 'Offer Accepted',
  OfferRejected: 'Offer Rejected',
  OfferRescinded: 'Offer Rescinded',
  Sold: 'Sold',
  ConditionsSatisfied: 'Conditions Satisfied',
  ConditionsNotSatisfied: 'Conditions Not Satisfied',
  PossessionDay: 'Possession Day',
  Expired: 'Expired',
  OfflineOfferSubmitted: 'Offline Offer Submitted',
  OfflineOfferReceived: 'Offline Offer Received',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type OfferManagementStepperStatus = typeof OfferManagementStepperStatus[
  keyof typeof OfferManagementStepperStatus
];

export default {
  OfferManagementStepperStatus,
};

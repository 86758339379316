export const OffersManagementListingDtoField = {
  ListingId: 'listingId',
  ListingImage: 'listingImage',
  ListingStatus: 'listingStatus',
  ListingAddress: 'listingAddress',
  ListingOffers: 'listingOffers',
  ListingCity: 'listingCity',
  ListingPrice: 'listingPrice',
  ListingOwnerId: 'listingOwnerId',
  ListingOwnerFirstName: 'listingOwnerFirstName',
  ListingOwnerLastName: 'listingOwnerLastName',
  ListingOrigin: 'listingOrigin',
  ListingBeds: 'listingBeds',
  ListingBaths: 'listingBaths',
  ListingFinishedSqft: 'listingFinishedSqft',
} as const;

export const OffersManagementUserDtoField = {
  UserId: 'userId',
  UserFirstName: 'userFirstName',
  UserLastName: 'userLastName',
  UserRole: 'userRole',
  UserImage: 'userImage',
} as const;

export const OffersManagementOfferDtoField = {
  OfferId: 'offerId',
  OfferStatus: 'offerStatus',
  OfferPrice: 'offerPrice',
  OfferSellerTerms: 'offerSellerTerms',
  OfferBuyerTerms: 'offerBuyerTerms',
  OfferSellerConditions: 'offerSellerConditions',
  OfferBuyerConditions: 'offerBuyerConditions',
  OfferPossessionDate: 'offerPossessionDate',
  OfferSubmittedDate: 'offerSubmittedDate',
  OfferUpdatedDate: 'offerUpdatedDate',
  OfferIsListingOwner: 'offerIsListingOwner',
  OfferIsViewed: 'offerIsViewed',
  OfferIsAcceptedByBuyer: 'offerAcceptedByBuyer',
  OfferIsAcceptedBySeller: 'offerAcceptedBySeller',
  OfferAreConditionsSatisfied: 'offerAreConditionsSatisfied',
  OfferAreConditionsNotSatisfied: 'offerAreConditionsNotSatisfied',
  OfferExpirationDate: 'offerExpirationDate',
  OfferSigners: 'offerSigners',
  OfflineOfferBuyer: 'offlineOfferBuyer',
  OfferBuyerIsRealtor: 'offerBuyerIsRealtor',
  OfferIsOfflineOffer: 'offerIsOfflineOffer',
} as const;

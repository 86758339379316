
import Link from '@/ui-kit/components/link';
import { LinkProperty } from '@/ui-kit/components/link/enums';
import { StyleColorSchemeType } from '@/ui-kit/styles/enums';
import FooterLinkProps from './mixins/LinkProps';
import FooterLinkEvents from './mixins/LinkEvents';
import { FooterLinkProperty } from './enums';

export default {
  name: 'FooterNavigationInternalLink',
  components: {
    Link,
  },
  mixins: [FooterLinkProps, FooterLinkEvents],
  data() {
    return {
      LinkProperty,
      FooterLinkProperty,
      StyleColorSchemeType,
    };
  },
  computed: {
    linkEventData() {
      return this.makeLinkEventData(
        this[FooterLinkProperty.Data].label,
        this[FooterLinkProperty.Type],
      );
    },
  },
};

export const IconType = {
  Message: 'message',
  Calendar: 'calendar',
  CalendarEmpty: 'calendar-empty',
  Bulb: 'bulb',
  Portal: 'portal',
  Account: 'account',
  MyBode: 'mybode',
  ArrowForward: 'arrow-forward',
  ArrowBack: 'arrow-back',
  ArrowRight: 'arrow-right',
  ArrowLeft: 'arrow-left',
  ArrowDown: 'arrow-down',
  ArrowUp: 'arrow-up',
  Hamburger: 'hamburger',
  ChevronUp: 'chevron-up',
  ChevronDown: 'chevron-down',
  ChevronLeft: 'chevron-left',
  ChevronRight: 'chevron-right',
  FacebookCircle: 'facebook',
  TwitterCircle: 'twitter',
  Link: 'link',
  LinkedInCircle: 'linkedin',
  Instagram: 'instagram',
  Quotes: 'quotes',
  QuotesClose: 'quotes-close',
  Check: 'check',
  AcUnit: 'ac-unit',
  Heating: 'heating',
  Parking: 'parking',
  Garage: 'garage',
  Shop: 'shop',
  Home: 'home',
  Homes: 'homes',
  HomeBush: 'home-bush',
  HomeSize: 'home-size',
  HomePrice: 'home-price',
  HomeSold: 'home-sold',
  Call: 'call',
  Heart: 'heart',
  HeartFilled: 'heart-filled',
  Share: 'share',
  SquareFeet: 'square-feet',
  Bed: 'bed',
  Bath: 'bath',
  Clock: 'clock',
  Realtor: 'realtor',
  Zoning: 'zoning',
  BodePin: 'bode-pin',
  Chat: 'chat',
  ExternalResource: 'external-resource',
  Printer: 'printer',
  Add: 'add',
  Trash: 'trash',
  Search: 'search',
  Pencil: 'pencil',
  PencilThin: 'pencil-thin',
  Rotate: 'rotate',
  QuestionCircle: 'question-circle',
  Minus: 'minus',
  Close: 'close',
  CloseOutlined: 'close-outlined',
  Percent: 'percent',
  HomeInspection: 'home-inspection',
  Furniture: 'furniture',
  Camera: 'camera',
  Renovations: 'renovations',
  Measurements: 'measurements',
  Legal: 'legal',
  Cleaning: 'cleaning',
  Wall: 'wall',
  Fence: 'fence',
  Drywall: 'drywall',
  Engineering: 'engineering',
  Hvac: 'hvac',
  Engineer: 'engineer',
  Lightning: 'lightning',
  Truck: 'truck',
  PaintBrush: 'paint-brush',
  Mortgage: 'mortgage',
  SmartSecurity: 'smart-security',
  Roof: 'roof',
  Landscaping: 'landscaping',
  Organization: 'organization',
  WaterPipes: 'water-pipes',
  Storage: 'storage',
  CloudDownload: 'cloud-download',
  CloudUpload: 'cloud-upload',
  TextUnderline: 'text-underline',
  TextBullets: 'text-bullets',
  TextItalic: 'text-italic',
  TextBold: 'text-bold',
  Location: 'location',
  Google: 'google',
  CircleWavyEmpty: 'circle-wavy-empty',
  CircleWavyCheck: 'circle-wavy-check',
  Spinner: 'spinner-gap',
  SoldListingPin: 'sold-listing-pin',
  MLSPin: 'mls-pin',
  Hoa: 'hoa',
  CircleCheck: 'circle-check',
  CircleInfo: 'circle-info',
  TriangleWarning: 'triangle-warning',
  Eye: 'eye',
  CrossedEye: 'crossed-eye',
  Timer: 'timer',
  StarOutlined: 'star-outlined',
  StarFilled: 'star-filled',
  Cars: 'cars',
  Bathroom: 'bathroom',
  Community: 'community',
  Building: 'building',
  Landscape: 'landscape',
  GarageSize: 'garage-size',
  HomeBode: 'home-bode',
  File: 'file',
  Refresh: 'refresh',
  Hashtag: 'hashtag',
  PieChart: 'pie-chart',
  Coins: 'coins',
  Sort: 'sort',
  Save: 'save',
  Thumb: 'thumb',
  CallCenter: 'call-center',
  CallSupport: 'call-support',
  MessageLamp: 'message-lamp',
  Detached: 'detached',
  RowOrTownhouse: 'row-townhouse',
  SemiDetached: 'semi-detached',
  LowRiseApartment: 'low-rise-appartment',
  HighRiseApartment: 'high-rise-appartment',
  VacantLand: 'vacant-land',
  Settings: 'settings',
  Reset: 'reset',
};

export const IconProperty = {
  ClassName: 'className',
  Types: 'types',
  Type: 'type',
  Size: 'size',
  ColorScheme: 'colorScheme',
};

export default {
  IconType,
  IconProperty,
};

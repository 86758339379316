
import { LayoutItemProperty } from './enums';
import LayoutItemProps from './mixins/LayoutItemProps';
import StyleClassNamesBuilder from '../../../styles/helpers/StyleClassNamesBuilder';
import { StyleSizeType } from '../../../styles/enums';

export default {
  name: 'LayoutGridItem',
  mixins: [LayoutItemProps],
  computed: {
    layoutItemClassNames() {
      const classNamesBuilder = new StyleClassNamesBuilder('layout-grid-item');

      Object.entries(LayoutItemProperty).forEach(([propertyName, size]) => {
        const isSizeProperty = !!StyleSizeType[propertyName];

        if (!isSizeProperty) {
          return;
        }

        const columns = this[size];

        classNamesBuilder.addConditionClassName(`${size}-${columns}`, !!columns);
      });

      return classNamesBuilder.build();
    },
  },
};

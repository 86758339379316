/* eslint-disable camelcase */
import {
  SET_HERO_CONTENT,
  SET_DESCRIPTION_CONTENT,
  SET_LIST_TITLE_CONTENT,
  SET_LIST_BULLET_CONTENT,
  SET_IMAGE_CONTENT,
  SET_FOOTER_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      hero,
      description_section,
      list_section,
      list_section_bullets,
      image_section,
      footer_section,
    } = data;

    const [heroData] = hero;
    const [descriptionData] = description_section;
    const [listTitleData] = list_section;
    const [imageData] = image_section;
    const [footerData] = footer_section;

    commit(SET_HERO_CONTENT, heroData);
    commit(SET_DESCRIPTION_CONTENT, descriptionData);
    commit(SET_LIST_TITLE_CONTENT, listTitleData);
    commit(SET_LIST_BULLET_CONTENT, list_section_bullets);
    commit(SET_IMAGE_CONTENT, imageData);
    commit(SET_FOOTER_CONTENT, footerData);
    commit(SET_IS_CONTENT_LOADED, true);
  },
};


import { StyleColorSchemeType, StyleSizeType } from '../../styles/enums';
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import Icon from '../icon';
import {
  IconButtonPropsEventType,
  ShapeType,
  StyleFillType,
  IconButtonProperty,
} from './enums';
import Spinner from '../spinner';
import IconButtonProps from './mixins/IconButtonProps';

export default {
  name: 'IconButton',
  components: { Spinner, Icon },
  mixins: [IconButtonProps],
  data() {
    return {
      StyleSizeType,
      StyleColorSchemeType,
      IconButtonProperty,
    };
  },
  computed: {
    iconColorScheme() {
      return this[IconButtonProperty.IsDisabled]
        ? StyleColorSchemeType.Gray
        : this[IconButtonProperty.ColorScheme];
    },
    iconButtonClassName() {
      return new StyleClassNamesBuilder('button-icon')
        .addClassName(this[IconButtonProperty.ClassName])
        .addColorSchemeConditionClassNames(this[IconButtonProperty.ColorScheme])
        .addConditionClassNames(Object.values(StyleFillType), this[IconButtonProperty.FillType])
        .addConditionClassNames(Object.values(ShapeType), this[IconButtonProperty.ShapeType])
        .addConditionClassName('disabled', this[IconButtonProperty.IsDisabled])
        .build();
    },
  },
  methods: {
    handleClick() {
      if (this[IconButtonProperty.IsLoading]) {
        return;
      }

      this.$emit(IconButtonPropsEventType.Click);
    },
  },
};

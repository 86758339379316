
import { LayoutContainerProperty } from './enums';
import LayoutContainerProps from './mixins/LayoutContainerProps';
import StyleClassNamesBuilder from '../../../styles/helpers/StyleClassNamesBuilder';
import { StyleSizeType } from '../../../styles/enums';

export default {
  name: 'LayoutGridContainer',
  mixins: [LayoutContainerProps],
  computed: {
    layoutContainerClassNames() {
      return new StyleClassNamesBuilder('layout-grid-container')
        .addConditionClassNames(
          Object.values(StyleSizeType),
          this[LayoutContainerProperty.Spacing],
        )
        .build();
    },
  },
};

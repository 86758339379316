
import TypographyStyles from '../mixins/TypographyStyles';

export default {
  name: 'Header6',
  mixins: [TypographyStyles],
  computed: {
    header6ClassNames() {
      return this.getTypographyClassNames('header-6');
    },
  },
};

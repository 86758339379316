
import { MenuTooltipSlotName, MenuTooltipProperty } from './enums';
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import MenuTooltipProps from './mixins/MenuTooltipProps';

export default {
  name: 'MenuTooltip',
  mixins: [MenuTooltipProps],
  data: () => ({
    isFocused: false,
    MenuTooltipSlotName,
    MenuTooltipProperty,
  }),
  computed: {
    dropdownClassNames() {
      return new StyleClassNamesBuilder('menu-tooltip-dropdown')
        .addConditionClassName('right', this[MenuTooltipProperty.IsRightSide])
        .build();
    },
  },
  methods: {
    show() {
      this.isFocused = true;
    },
    hide() {
      this.isFocused = false;
    },
  },
};


import Typography from '@/ui-kit/components/typography';
import Link from '@/ui-kit/components/link';
import { LinkProperty } from '@/ui-kit/components/link/enums';
import {
  StyleSizeType,
  StyleColorSchemeType,
  StylePositionType,
} from '@/ui-kit/styles/enums';
import { TypographyType, TypographyProperty } from '@/ui-kit/components/typography/enums';
import Gutter from '@/ui-kit/components/gutter';
import { GutterProperty } from '@/ui-kit/components/gutter/enums';
import { FooterTrackEvent } from '@/constants/AnalyticEvents';
import routes from '@/constants/routes';

export default {
  name: 'FooterCopyright',
  components: {
    Typography,
    Link,
    Gutter,
  },
  data() {
    return {
      TypographyType,
      TypographyProperty,
      GutterProperty,
      LinkProperty,
      StyleSizeType,
      StyleColorSchemeType,
      StylePositionType,
      FooterTrackEvent,
      routes,
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};


import { StyleSizeType } from '@/ui-kit/styles/enums';
import FooterDesktopNavigation from './desktop';
import FooterMobileNavigation from './mobile';
import { FOOTER_LINKS } from './constants';

export default {
  name: 'FooterNavigation',
  components: {
    FooterDesktopNavigation,
    FooterMobileNavigation,
  },
  data() {
    return {
      StyleSizeType,
      FOOTER_LINKS,
    };
  },
};

import { SpinnerProperty } from '../enums';
import { StyleColorSchemeType, StyleSizeType } from '../../../styles/enums';

const SpinnerProps = {
  props: {
    [SpinnerProperty.Size]: {
      default: StyleSizeType.Medium,
      type: String,
    },
    [SpinnerProperty.ColorScheme]: {
      default: StyleColorSchemeType.Gray,
      type: String,
    },
  },
};

export default SpinnerProps;

import {
  SET_CURRENT_PROVINCE,
} from './mutations';
import STORE_NAME from './name';

export const SET_PROVINCE_DATA = 'set-province-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_PROVINCE_DATA]({ commit }, province) {
    const currentProvince = province;

    commit(SET_CURRENT_PROVINCE, currentProvince);
  },
};

import { Enums } from '@bode-canada/core';
import ServerlessApiService from '../ServerlessApiService';

const { ServerlessApiPathEnum, UserProfileImageApiPathEnum, UsersApiPathEnum } = Enums;

export default class UserApiService {
  static BASE_URL = `${UsersApiPathEnum.USERS}/${ServerlessApiPathEnum.V1}`;

  static getUserById(userId) {
    return ServerlessApiService.get(
      `${this.BASE_URL}/${userId}`,
    );
  }

  static getCurrentUser() {
    const referrer = document.referrer ? `?referrer=${document.referrer}` : '';

    return ServerlessApiService.get(`${this.BASE_URL}${referrer}`);
  }

  static saveProfileImage(userId, image) {
    return ServerlessApiService.post(
      `${this.BASE_URL}/${UserProfileImageApiPathEnum.PROFILE_IMAGE}/${userId}`,
      image,
    );
  }
}

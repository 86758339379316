
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import { ListItemPropsEventType, ListItemProperty } from './enums';
import Icon from '../icon';
import Gutter from '../gutter';
import { StylePositionType, StyleSizeType } from '../../styles/enums';
import ListItemProps from './mixins/ListItemProps';

export default {
  name: 'ListItem',
  components: {
    Gutter,
    Icon,
  },
  mixins: [ListItemProps],
  data: () => ({
    StylePositionType,
    StyleSizeType,
    ListItemProperty,
  }),
  computed: {
    listItemClassNames() {
      return new StyleClassNamesBuilder('list-item')
        .addConditionClassName('active', this[ListItemProperty.IsActive] && !this[ListItemProperty.Disabled])
        .addClassName(this[ListItemProperty.ClassName])
        .build();
    },
  },
  methods: {
    handleClick() {
      if (!this[ListItemProperty.Disabled]) {
        this.$emit(ListItemPropsEventType.Click, this[ListItemProperty.Value]);
      }
    },
  },
};

import { Enums } from '@bode-canada/core';
import {
  OffersManagementListingDtoField,
  OffersManagementOfferDtoField,
  OffersManagementUserDtoField,
} from '@/services/api/offers-management/enums';
import DateHelper from '@/ui-kit/helpers/DateHelper';
import OffersManagementFilterService from './OffersManagementFilterService';
import {
  INITIAL_FOR_OPPONENT_STEPPER_STATUSES,
  PREPARING_STEPPER_STATUSES,
} from '../stepper/constants';
import { ThresholdPrice } from '@/utils/commission/constants';
import CommissionHelper from '@/utils/commission';

class OfferManagementOfferDetailsService {
  static getBuyerAgentCommissionTerm(offer) {
    const { listingPrice, listingProvince } = offer;
    const buyerTerms = offer[OffersManagementOfferDtoField.OfferBuyerTerms];
    const sellerTerms = offer[OffersManagementOfferDtoField.OfferSellerTerms];
    const buyerCommissionTermFromBuyer = buyerTerms.find((term) => !!term?.commissionType) || '';
    const buyerCommissionTermFromSeller = sellerTerms.find((term) => !!term?.commissionType) || '';
    const commissionTerm = buyerCommissionTermFromBuyer || buyerCommissionTermFromSeller;

    if (commissionTerm) {
      return this.calculateBuyerAgentCommission(commissionTerm, listingPrice, listingProvince);
    }

    return null;
  }

  static calculateBuyerAgentCommission(term, price, province) {
    switch (term.commissionType) {
      case Enums.BuyerCommissionEnum.FLAT_RATE:
        return term.flatRate;
      case Enums.BuyerCommissionEnum.TYPICAL_COMMISSION:
        return CommissionHelper.getBuyerCommission(price, province);
      case Enums.BuyerCommissionEnum.CUSTOM_COMMISSION:
        return CommissionHelper
          .getCommissionByRate(
            this.#getCustomCommissionRates(
              term.firstPartOfSalePriceCommission,
              term.balanceOfSalePriceCommission,
            ),
            price,
          );
      default:
        return 0;
    }
  }

  static #getCustomCommissionRates(
    firstPartOfSalePriceCommission,
    balanceOfSalePriceCommission,
  ) {
    return [
      { value: firstPartOfSalePriceCommission, thresholdPrice: ThresholdPrice.Min },
      { value: balanceOfSalePriceCommission, thresholdPrice: ThresholdPrice.Max },
    ];
  }

  static getNumberOfConditions(offer) {
    const sellerConditions = offer[OffersManagementOfferDtoField.OfferSellerConditions];
    const buyerConditions = offer[OffersManagementOfferDtoField.OfferBuyerConditions];
    const numberOfSellerConditions = sellerConditions?.length || 0;
    const numberOfBuyerConditions = buyerConditions?.length || 0;

    return numberOfSellerConditions + numberOfBuyerConditions;
  }

  static getIsConditionalSale(offers) {
    const lastOffer = OffersManagementFilterService.getLastOffer(offers);

    return !!lastOffer.buyerConditions?.length || !!lastOffer.sellerConditions?.length;
  }

  static getIsPossessionDayComplete(offer) {
    return DateHelper.getIsPast(offer[OffersManagementOfferDtoField.OfferPossessionDate]);
  }

  static getIsPossessionDatePassed(offers) {
    const lastOffer = OffersManagementFilterService.getLastOffer(offers);
    const possessionDate = new Date(lastOffer[OffersManagementOfferDtoField.OfferPossessionDate]);
    const today = new Date();

    DateHelper.resetDateHours(possessionDate);
    DateHelper.resetDateHours(today);

    return today > new Date(possessionDate);
  }

  static getHasCounterOffers(offers) {
    return offers.every((offer) => offer.offerStatus === Enums.OfferStatusEnum.BUYER_COUNTER
      || offer.offerStatus === Enums.OfferStatusEnum.SELLER_COUNTER);
  }

  static getLastAcceptedOffer(offers) {
    const sortedOffers = OffersManagementFilterService.getDescSortedOffersByDate(offers);

    return sortedOffers.find((offer) => (
      offer[OffersManagementOfferDtoField.OfferStatus] === Enums.OfferStatusEnum.ACCEPTED
    ));
  }

  static getIsAccepted(offers) {
    return Boolean(this.getLastAcceptedOffer(offers));
  }

  static getIsOfflineOfferSubmitted(offers) {
    return offers.some((offer) => (
      offer[OffersManagementOfferDtoField.OfferStatus] === Enums.OfferStatusEnum.OFFLINE_OFFER_SUBMITTED
    ));
  }

  static getIsRejected(offers) {
    return offers.some((offer) => (
      offer[OffersManagementOfferDtoField.OfferStatus] === Enums.OfferStatusEnum.REJECTED
    ));
  }

  static getPendingOffers(offers) {
    return offers.filter((offer) => (
      offer[OffersManagementOfferDtoField.OfferStatus] === Enums.OfferStatusEnum.PENDING
    ));
  }

  static getIsSubmitted(offers) {
    return !!this.getPendingOffers(offers).length;
  }

  static getIsExpired(offer) {
    const expireDate = offer[OffersManagementOfferDtoField.OfferExpirationDate];

    if (!expireDate) {
      return false;
    }

    return DateHelper.getIsPast(expireDate);
  }

  static getIsPreparingStatus(status) {
    return PREPARING_STEPPER_STATUSES.includes(status);
  }

  static getIsInitialForOpponentStatus(status) {
    return INITIAL_FOR_OPPONENT_STEPPER_STATUSES.includes(status);
  }

  static getHasAllNecessaryParticipants(offers, status) {
    if (this.getIsInitialForOpponentStatus(status) || this.getIsPreparingStatus(status)) {
      return true;
    }

    const hasBuyer = offers.some((offer) => (
      offer[OffersManagementUserDtoField.UserRole] === Enums.UserRoleEnum.BUYER
    ));
    const hasSeller = offers.some((offer) => (
      offer[OffersManagementUserDtoField.UserRole] === Enums.UserRoleEnum.SELLER
    ));

    return hasSeller && hasBuyer;
  }

  static getIsMls(listing) {
    return listing[OffersManagementListingDtoField.ListingOrigin] !== Enums.ListingOriginEnum.BODE;
  }
}

export default OfferManagementOfferDetailsService;

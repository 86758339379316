/* eslint-disable no-param-reassign */
export const SET_OFFER_SUBMIT_SUCCESS_CONTENT = 'set-offer-submit-success-content';
export const SET_OFFER_SUBMIT_SUCCESS_IS_CONTENT_LOADED = 'set-offer-submit-success-is-content-loaded';

export default {
  [SET_OFFER_SUBMIT_SUCCESS_CONTENT](previousState, content) {
    previousState.contentData = content;
  },
  [SET_OFFER_SUBMIT_SUCCESS_IS_CONTENT_LOADED](previousState, isContentLoaded) {
    previousState.isContentLoaded = isContentLoaded;
  },
};


import { ImagePlaceholderSlotType } from './enums';

export default {
  name: 'ImagePlaceholder',
  data() {
    return {
      ImagePlaceholderSlotType,
    };
  },
};

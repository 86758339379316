
import { LinkProperty } from '@/ui-kit/components/link/enums';
import {
  ListItemProperty,
  ListItemPropsEventType,
} from '@/ui-kit/components/list-item/enums';
import MenuTooltip from '../menu-tooltip';
import { MenuTooltipSlotName } from '../menu-tooltip/enums';
import List from '../list';
import Link from '../link';
import ListItem from '../list-item';
import NavLinkProps from './mixins/NavLinkProps';
import { StyleColorSchemeType } from '../../styles/enums';

export default {
  name: 'NavLink',
  components: {
    ListItem,
    List,
    MenuTooltip,
    Link,
  },
  mixins: [NavLinkProps],
  data: () => ({
    currentRoutePath: '',
    MenuTooltipSlotName,
    StyleColorSchemeType,
    LinkProperty,
    ListItemProperty,
    ListItemPropsEventType,
  }),
  computed: {
    isDropdownViewable() {
      return !!this.nestedLinks;
    },
  },
  watch: {
    '$route.path': {
      handler(value) {
        this.currentRoutePath = value;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.currentRoutePath = this.$route.path;
  },
  methods: {
    getIsActive(route) {
      return this.currentRoutePath.includes(route);
    },
    getNestedLinkKey(index) {
      return `${index}-${this.label}-nested-link`;
    },
    handleListItemClick(route) {
      this.$router.push(route);
    },
  },
};

/* eslint-disable camelcase */
import {
  SET_ALL_YOU_NEED_CONTENT,
  SET_SELL_YOUR_HOME_CONTENT,
  SET_BUY_YOUR_HOME_CONTENT,
  SET_PHONE_CONTENT,
  SET_IS_CONTENT_LOADED,
  SET_BODE_FEATURES_CONTENT,
  SET_CUSTOMER_FEEDBACK_CONTENT,
  SET_SOLD_PREVIEW_CONTENT,
  SET_FEATURED_IN_CONTENT,
  SET_BODE_IS_SECURE_CONTENT,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      all_you_need,
      buy_your_home,
      sell_your_home,
      phone,
      bode_features,
      customer_feedback,
      customer_feedback_header,
      sold_preview_header,
      sold_preview_link_text,
      sold_preview,
      featured_in_header,
      featured_in,
      bode_is_secure,
    } = data;

    const [allYouNeedData] = all_you_need;
    const [buyYourHomeData] = buy_your_home;
    const [sellYourHomeData] = sell_your_home;
    const [bodeFeaturesData] = bode_features;
    const [bodeIsSecureContent] = bode_is_secure;
    const [phoneData] = phone;

    commit(SET_ALL_YOU_NEED_CONTENT, allYouNeedData);
    commit(SET_BUY_YOUR_HOME_CONTENT, buyYourHomeData);
    commit(SET_SELL_YOUR_HOME_CONTENT, sellYourHomeData);
    commit(SET_BODE_FEATURES_CONTENT, bodeFeaturesData);
    commit(SET_CUSTOMER_FEEDBACK_CONTENT, {
      customer_feedback,
      customer_feedback_header,
    });
    commit(SET_SOLD_PREVIEW_CONTENT, {
      sold_preview_link_text,
      sold_preview_header,
      sold_preview,
    });
    commit(SET_FEATURED_IN_CONTENT, {
      featured_in,
      featured_in_header,
    });
    commit(SET_BODE_IS_SECURE_CONTENT, bodeIsSecureContent);
    commit(SET_PHONE_CONTENT, phoneData);
    commit(SET_IS_CONTENT_LOADED, true);
  },
};

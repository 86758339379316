export const ExternalLinkProperty = {
  ClassName: 'className',
  Href: 'href',
  ColorScheme: 'colorScheme',
  HasChevron: 'hasChevron',
  IsUnderlined: 'isUnderlined',
};

export const ExternalLinkEventType = {
  Click: 'click',
};

export default {
  ExternalLinkProperty,
  ExternalLinkEventType,
};

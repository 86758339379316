
import { IconType } from '../icon/enums';
import { StyleSizeType, StyleColorSchemeType } from '../../styles/enums';
import ToastProps from './mixins/ToastProps';
import Icon from '../icon';
import IconButton from '../icon-button';
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import { ToastVariant, ToastProperty, ToastShowingMode } from './enums';
import { CLOSE_CALLBACK_VISIBILITY_TIMER } from './constants';

export default {
  name: 'Toast',
  components: {
    Icon,
    IconButton,
  },
  mixins: [ToastProps],
  data() {
    return {
      IconType,
      StyleSizeType,
      StyleColorSchemeType,
      ToastProperty,
    };
  },
  computed: {
    isTemporaryMode() {
      return this[ToastProperty.ShowingMode] === ToastShowingMode.Temporary;
    },
    className() {
      return new StyleClassNamesBuilder('toast')
        .addConditionClassNames(Object.values(ToastVariant), this[ToastProperty.Variant])
        .build();
    },
    iconType() {
      const iconTypeByVariant = {
        [ToastVariant.Positive]: IconType.CircleCheck,
        [ToastVariant.Negative]: IconType.TriangleWarning,
        [ToastVariant.Info]: IconType.CircleInfo,
        [ToastVariant.Neutral]: IconType.CircleInfo,
      };
      return iconTypeByVariant[this[ToastProperty.Variant]];
    },
  },
  watch: {
    [ToastProperty.IsOpen](isOpen) {
      this.handleIsOpenChange(isOpen);
    },
  },
  methods: {
    handleIsOpenChange(isOpen) {
      if (isOpen && this.isTemporaryMode) {
        setTimeout(this.closeToast, CLOSE_CALLBACK_VISIBILITY_TIMER);
      }
    },
    handleCloseIconCLick() {
      this.closeToast();
    },
    closeToast() {
      this[ToastProperty.CloseCallback]?.();
    },
  },
};


import { track } from '@/utils/analytics';
import Icon from '@/ui-kit/components/icon';
import { IconType, IconProperty } from '@/ui-kit/components/icon/enums';
import { StyleSizeType, StyleColorSchemeType, StylePositionType } from '@/ui-kit/styles/enums';
import ExternalLink from '@/ui-kit/components/external-link';
import { ExternalLinkProperty } from '@/ui-kit/components/external-link/enums';
import { ExternalLinks } from '@/utils/external-navigation/constants';
import { SOCIAL_LINKS } from '@/components/footer/logo-section/constants';

export default {
  name: 'FooterLogoSectionSocialLinks',
  components: {
    ExternalLink,
    Icon,
  },
  data() {
    return {
      ExternalLinkProperty,
      IconType,
      IconProperty,
      StyleSizeType,
      StylePositionType,
      StyleColorSchemeType,
      ExternalLinks,
      SOCIAL_LINKS,
    };
  },
  methods: {
    handleSocialLinkClick(eventName) {
      track(eventName);
    },
  },
};

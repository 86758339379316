import { Constants, Helpers } from '@bode-canada/core';
import { TIME_REGEXP } from '../components/form/validators/constants';

const TIME_SEPARATOR = ':';
const MIDNIGHT_VALUE_SEPARATOR = ' ';

export const DateMidnightValue = {
  Am: 'am',
  Pm: 'pm',
};

class DateHelper {
  static getIsToday(date) {
    const today = new Date();
    const givenDate = new Date(date);

    this.resetDateHours(today);
    this.resetDateHours(givenDate);

    return today.getTime() === givenDate.getTime();
  }

  static getIsFuture(date) {
    const today = new Date();
    const givenDate = new Date(date);

    return today < givenDate;
  }

  static getIsPast(date) {
    const today = new Date();
    const givenDate = new Date(date);

    return today > givenDate;
  }

  static getLatestDate(dates) {
    const datesTimestamp = dates.map((date) => (new Date(date)).getTime());

    return new Date(Math.max(...datesTimestamp));
  }

  static resetDateHours(date) {
    date.setHours(0, 0, 0, 0);

    return date;
  }

  static getDateDifferenceTimeText(fromDate, toDate) {
    const [days, hours, minutes] = this.getDateDifferenceTime(fromDate, toDate);
    let text = '';

    if (days) {
      text = text.concat(`${days}d`);
    }

    if (hours) {
      text = text.concat(`${hours}h`);
    }

    if (minutes) {
      text = text.concat(`${minutes}m`);
    }

    return text;
  }

  static getTimeText(hours, minutes) {
    let text = '';

    if (hours === 0) {
      text = text.concat(`${Constants.HOURS_IN_MIDNIGHT}`);
    } else if (hours <= Constants.HOURS_IN_MIDNIGHT) {
      text = text.concat(`${hours}`);
    } else {
      text = text.concat(`${hours % Constants.HOURS_IN_MIDNIGHT}`);
    }

    text = text.concat(':');

    if (minutes > 10) {
      text = text.concat(`${minutes}`);
    } else {
      text = text.concat(`0${minutes}`);
    }

    text = text.concat(' ');

    if (hours >= Constants.HOURS_IN_MIDNIGHT) {
      text = text.concat(DateMidnightValue.Pm);
    } else if (hours < Constants.HOURS_IN_MIDNIGHT) {
      text = text.concat(DateMidnightValue.Am);
    }

    return text;
  }

  static getDateDifferenceTime(fromDate, toDate) {
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);

    if (date1 > date2) {
      return [0, 0, 0];
    }

    const totalMilliseconds = Math.abs(date2 - date1);

    const remainingDays = Math.floor(Helpers.DateHelper.getTimeInDifferentUnit(
      totalMilliseconds,
      Constants.DateUnit.Milliseconds,
      Constants.DateUnit.Day,
    ));

    const remainingDaysInHours = Helpers.DateHelper.getTimeInDifferentUnit(
      remainingDays,
      Constants.DateUnit.Day,
      Constants.DateUnit.Hour,
    );
    const totalHours = Helpers.DateHelper.getTimeInDifferentUnit(
      totalMilliseconds,
      Constants.DateUnit.Milliseconds,
      Constants.DateUnit.Hour,
    );
    const remainingHours = Math.floor(totalHours - remainingDaysInHours);

    const remainingDaysInMinutes = Helpers.DateHelper.getTimeInDifferentUnit(
      remainingDays,
      Constants.DateUnit.Day,
      Constants.DateUnit.Minute,
    );
    const remainingHoursInMinutes = Helpers.DateHelper.getTimeInDifferentUnit(
      remainingHours,
      Constants.DateUnit.Hour,
      Constants.DateUnit.Minute,
    );
    const totalMinutes = Helpers.DateHelper.getTimeInDifferentUnit(
      totalMilliseconds,
      Constants.DateUnit.Milliseconds,
      Constants.DateUnit.Minute,
    );
    const remainingMinutes = (
      Math.floor(totalMinutes - remainingDaysInMinutes - remainingHoursInMinutes)
    );

    return [remainingDays, remainingHours, remainingMinutes];
  }

  static getJoinedDateAndTime(date, time) {
    const resetDateHours = this.resetDateHours(new Date(date));
    const timeInTimestamps = this.getTimestampsFromTime(time);

    return new Date(resetDateHours.getTime() + timeInTimestamps);
  }

  static getTimeFromTimestamps(timestamps) {
    const hours = Math.floor(Helpers.DateHelper.getTimeInDifferentUnit(
      timestamps,
      Constants.DateUnit.Milliseconds,
      Constants.DateUnit.Hour,
    ));

    const hoursInMinutes = Helpers.DateHelper.getTimeInDifferentUnit(
      hours,
      Constants.DateUnit.Hour,
      Constants.DateUnit.Minute,
    );

    const minutes = Math.floor(Helpers.DateHelper.getTimeInDifferentUnit(
      timestamps,
      Constants.DateUnit.Milliseconds,
      Constants.DateUnit.Minute,
    ));

    const remainingMinutes = (
      Math.floor(minutes - hoursInMinutes)
    );

    return this.getTimeText(hours, remainingMinutes);
  }

  static getTimestampsFromTime(time) {
    if (!TIME_REGEXP.test(time)) {
      return 0;
    }

    const [hoursMinutes, midnightValue] = time.split(MIDNIGHT_VALUE_SEPARATOR);
    const [hours, minutes] = hoursMinutes.split(TIME_SEPARATOR);

    const millisecondsInHours = Helpers.DateHelper.getTimeInDifferentUnit(
      hours,
      Constants.DateUnit.Hour,
      Constants.DateUnit.Milliseconds,
    );
    const millisecondsInMinutes = Helpers.DateHelper.getTimeInDifferentUnit(
      minutes,
      Constants.DateUnit.Minute,
      Constants.DateUnit.Milliseconds,
    );

    if (Number(hours) === Constants.HOURS_IN_MIDNIGHT) {
      return midnightValue === DateMidnightValue.Pm ? millisecondsInHours + millisecondsInMinutes
        : millisecondsInMinutes;
    }

    const millisecondInMidnightValue = midnightValue === DateMidnightValue.Pm
      ? Helpers.DateHelper.getTimeInDifferentUnit(
        Constants.HOURS_IN_MIDNIGHT,
        Constants.DateUnit.Hour,
        Constants.DateUnit.Milliseconds,
      ) : 0;

    return millisecondsInHours + millisecondsInMinutes + millisecondInMidnightValue;
  }

  static getIsDateInstance(date) {
    return date instanceof Date;
  }

  static getDateRange(from, to) {
    if (!this.getIsDateInstance(from) || !this.getIsDateInstance(to)) {
      return [];
    }

    if (from >= to) {
      return [];
    }

    const dates = [];
    let lastDateInRange = from;

    while (lastDateInRange < to) {
      dates.push(new Date(lastDateInRange));
      lastDateInRange = new Date(lastDateInRange);
      lastDateInRange.setDate(lastDateInRange.getDate() + 1);
    }

    dates.push(to);

    return dates;
  }
}

export default DateHelper;

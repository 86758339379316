import { log } from '../debug';
import LocalStorageService from '@/services/LocalStorageService/LocalStorageService';

const debug = log('analytics');

// noinspection JSUnusedGlobalSymbols
/**
 * Vue analytics plugin.
 *
 * @note analytics events are under the namespace analytics:integration:segment-io
 * and can be activated with our debug utils.
 *
 * @type {Object}
 * @publilc
 */

export default {
  install(Vue) {
    /**
     * Expose analytics track in vue home.
     *
     * @param {String} topic
     * @param {Object} attributes
     * @private
     */

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$track = (topic, attributes) => track(topic, attributes);
  },
};

export function page(attrs = {}) {
  const { pathname, origin } = window.location;
  debug(`page ${pathname}`);
  const meta = getMetaAttributes(pathname);

  return window.analytics.page({
    ...attrs,
    ...meta,
    path: pathname,
    search: '',
    url: origin,
  });
}

/**
 * Get event attributes from meta tags.
 * Property meta tags prefixed with bode: can be used to pass some information from the backend on
 * page load.
 *
 * Examples:
 *   // return type of listing on listing page
 *   <meta property='bode:type' content='mls' />
 *
 * @param {String} path
 * @returns {Object}
 * @private
 */

function getMetaAttributes(path) {
  const attributes = {};
  const base = document.querySelector('meta[property="bode:path"]');
  // look for attributes only if bode: url match the given path
  if (base && base.content === path) {
    const nodes = document.querySelectorAll('meta[property^="bode:"]');
    [...nodes].forEach((el) => {
      const property = el.getAttribute('property');
      if (property !== 'bode:path') attributes[property.split('bode:')[1]] = el.content;
    });
  }
  return attributes;
}

/**
 * Identify user.
 *
 * @param {String} id
 * @param {Object} traits
 * @public
 */

export function identify(id, traits = {}) {
  debug(`identify ${id}`);
  return window.analytics.identify(id, traits);
}

/**
 * Trigger event.
 *
 * @note we are modifyging the context so query parameteras are not pasaed long.
 * Query parameters can be an issue with destination such as Facebook Pixels (personal information).
 *
 * @param {String} topic
 * @param {Object} attributes
 * @public
 */

export function track(topic, attributes) {
  if (!topic) {
    return null;
  }

  debug(`track ${topic}`);
  const { pathname, origin } = window.location;
  const utmParams = getUTMAttributes();
  return window.analytics.track(topic, {
    ...attributes,
    ...utmParams,
    path: pathname,
  }, {
    context: {
      page: {
        search: '',
        url: origin,
      },
    },
  });
}

/**
 * Reset analytics.
 * @note should be called on logout
 *
 * @public
 */

export function reset() {
  debug('reset');

  return window.analytics.reset();
}

/**
 * Get UTM attributes from Page URL.
 * UTM parameters are types of query strings added to the end of a URL
 *
 * Examples: http://www.mysite.com/specificlandingpage.html?utm_source=google&utm_medium=cpc&utm_campaign
 *
 * @returns {Object} utmParams
 * @private
 */

function getUTMAttributes() {
  const utmQuery = window.location.search.substring(1);
  const utmVariables = utmQuery.split('&');
  const utmParams = {
    context: {
      campaign: {
        source: '',
        medium: '',
        campaign: '',
        content: '',
        term: '',
      },
    },
  };
  let isUTMParams = false;

  Object.keys(utmParams.context.campaign).forEach((param) => {
    utmVariables.forEach((umtVar) => {
      const pName = umtVar.split('=');
      if (pName[0].split('utm_')[1] === param && pName[1]) {
        isUTMParams = true;
        [, utmParams.context.campaign[param]] = pName;
      }
    });
  });
  if (utmQuery && isUTMParams) {
    if (LocalStorageService.isPresent('UTM_PARAMS')) {
      LocalStorageService.remove('UTM_PARAMS');
    }
    LocalStorageService.add('UTM_PARAMS', utmParams);
  } else if (!utmQuery && !isUTMParams && LocalStorageService.isPresent('UTM_PARAMS')) {
    return LocalStorageService.fetch('UTM_PARAMS');
  }
  return utmParams;
}

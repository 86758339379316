
import Header from '@/components/header';
import Footer from '@/components/footer';
import Spinner from '@/components/spinner';
import { resetUser } from '@/utils/user';
import { hasCookie } from '@/utils/cookie';
import { signout } from '@/utils/auth';
import { getAction, INIT, REFRESH } from '@/store/user/actions';
import StyleClassNamesBuilder from '@/ui-kit/styles/helpers/StyleClassNamesBuilder';
import WindowScrollHelper from '@/ui-kit/helpers/WindowScrollHelper';
import ScrollButton from '@/components/scroll-button/ScrollButton';
import Toast from '@/components/toast';
import routes from '@/constants/routes';

const SCROLL_THRESHOLD_MODIFIER = 1.5;
const SCROLL_BUTTON_EXCLUDED_ROUTES = [
  routes.myListings,
];

export default {
  components: {
    Header,
    Footer,
    Toast,
    Spinner,
    ScrollButton,
  },
  data() {
    return {
      visibilityEvent: null,
      visibilityCallback: null,
      userRefreshInterval: null,
      isLoading: true,
      scrollYPosition: 0,
      scrollThreshold: 0,
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.userData;
    },
    isContainerIndentIgnored() {
      // TODO: Add list of marketing pages which ignores container indent
      return true;
    },
    appContainerClassNames() {
      return new StyleClassNamesBuilder('app-container')
        .addConditionClassName('ignored', this.isContainerIndentIgnored)
        .build();
    },
    showScrollButton() {
      return this.scrollYPosition > this.scrollThreshold
        && !SCROLL_BUTTON_EXCLUDED_ROUTES.includes(this.$route.path);
    },
  },
  /**
   * Set refresh interval based on browser visibility.
   *
   * @private
   */
  mounted() {
    this.$store.dispatch(getAction(INIT)).finally(() => {
      this.$nextTick(() => {
        this.isLoading = false;
      });
    });
    const [hidden, event] = useVisibilityApi();

    this.visibilityEvent = event;
    this.visibilityCallback = () => {
      clearInterval(this.userRefreshInterval);
      if (!document[hidden]) {
        this.userRefreshInterval = setInterval(
          () => this.$store.dispatch(getAction(REFRESH)),
          60000,
        );
      }
    };
    this.visibilityCallback();
    document.addEventListener(this.visibilityEvent, this.visibilityCallback);

    this.scrollThreshold = window.innerHeight * SCROLL_THRESHOLD_MODIFIER;
    window.addEventListener('scroll', this.updateScrollPosition);
  },
  beforeDestroy() {
    clearInterval(this.userRefreshInterval);
    document.removeEventListener(this.visibilityEvent, this.visibilityCallback);
    window.removeEventListener('scroll', this.updateScrollPosition);
  },
  methods: {
    async signout() {
      const hasImpersonation = hasCookie('bode-with-impersonation');
      const redirectPage = hasImpersonation ? routes.users : routes.root;

      resetUser();

      if (!hasImpersonation) {
        await signout();
      }

      this.$store.dispatch(getAction(REFRESH));
      this.$router.push(redirectPage);
    },
    updateScrollPosition() {
      this.scrollYPosition = window.scrollY;
    },
    scrollToTop() {
      WindowScrollHelper.scrollOnTopSmoothly();
    },
  },
};

/**
 * Returns cross browser API visibility keywords.
 *
 * @returns {Array}
 * @private
 */

// eslint-disable-next-line no-unused-vars
function useVisibilityApi() {
  if (isDefined(document.hidden)) {
    return ['hidden', 'visibilitychange'];
  }
  if (isDefined(document.msHidden)) {
    return ['msHidden', 'msvisibilitychange'];
  }
  if (isDefined(document.webkitHidden)) {
    return ['webkitHidden', 'webkitvisibilitychange'];
  }
  return [];
}

/**
 * Check if typeof argument different than undefined.
 *
 * @param {*} arg
 * @returns {Boolean}
 * @private
 */

function isDefined(arg) {
  return typeof arg !== 'undefined';
}

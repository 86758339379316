import axios from 'axios';
import { getCurrentSession } from './auth';
import { log } from './debug';

const debug = log('request');

export default async function request(...args) {
  try {
    return fetch(...args);
  } catch (error) {
    const { response } = error;
    if (response && response.status === 401) {
      await refreshToken(...args);
      return fetch(...args);
    }
    throw error;
  }
}

/**
 * Fetch api.
 *
 * @param {String} method
 * @param {String} requestUrl
 * @param {Object} options
 * @returns {Promise<Object>}
 * @private
 */

async function fetch(method, requestUrl, options = {}) {
  debug(`request ${method} ${requestUrl}`);
  const isAbsoluteUtl = requestUrl.startsWith('http');
  const baseApiPath = `${process.client ? '' : process.env.NUXT_ENV_PROXY_URL}${process.env.NUXT_ENV_API}`;
  const url = isAbsoluteUtl
    ? requestUrl
    : `${baseApiPath}${requestUrl}`;

  return axios({
    method,
    url,
    ...options,
  });
}

/**
 * Refresh token in case of 401.
 *
 * @returns {Promise<Object>}
 * @private
 */

async function refreshToken() {
  debug('refresh authentication token');
  return getCurrentSession();
}

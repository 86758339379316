export const NavLinkProperty = {
  Label: 'label',
  RedirectTo: 'redirectTo',
  IsBeta: 'isBeta',
  NestedLinks: 'nestedLinks',
};

export default {
  NavLinkProperty,
};

/* eslint-disable no-param-reassign */
export const SET_HERO_CONTENT = 'set-hero-content';
export const SET_STATEMENT_CONTENT = 'set-statement-content';
export const SET_CARD_CONTENT = 'set-card-content';
export const SET_BOTTOM_CONTENT = 'set-footer-content';
export const SET_IS_CONTENT_LOADED = 'set-is-content-loaded';
export default {
  [SET_HERO_CONTENT](previousState, content) {
    previousState.heroContent = content;
  },
  [SET_STATEMENT_CONTENT](previousState, content) {
    previousState.statementContent = content;
  },
  [SET_CARD_CONTENT](previousState, content) {
    previousState.cardContent = content;
  },
  [SET_BOTTOM_CONTENT](previousState, content) {
    previousState.bottomContent = content;
  },
  [SET_IS_CONTENT_LOADED](previousState, isLoaded) {
    previousState.isContentLoaded = isLoaded;
  },
};

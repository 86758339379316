
/**
 * @component Spinner
 *
 * @public
 */

export default {
  name: 'SpinnerComponent',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'fixed',
    },
    spinnerMessage: {
      type: String,
      default: '',
    },
  },
};


import Logo from '@/components/icons/logo';
import routes from '@/constants/routes';
import NavList from '@/ui-kit/components/nav-list';
import {
  ADMIN_NAV_LINKS,
  COMMON_NAV_LINKS,
  CONTRACTOR_NAV_LINKS,
} from '@/components/header/constants';
import Button from '@/ui-kit/components/button';
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import { ButtonFillType } from '@/ui-kit/components/button/enums';
import HeaderAvatar from '@/components/header/header-avatar';
import HeaderHamburger from '@/components/header/header-hamburger';
import { HeaderPropsEventType } from '@/components/header/enums';
import { NavigationEvent } from '@/constants/AnalyticEvents';
import { QueryParams } from '@/constants/QueryParams';

export default {
  name: 'Header',
  components: {
    HeaderHamburger,
    HeaderAvatar,
    Button,
    NavList,
    Logo,
  },
  props: {
    user: {
      type: Object,
      default: () => ({ isAuthenticated: false }),
    },
  },
  data() {
    return {
      links: COMMON_NAV_LINKS,
      NAV_LINKS: COMMON_NAV_LINKS,
      StylePositionType,
      StyleSizeType,
      StyleColorSchemeType,
      ButtonFillType,
      NavigationEvent,
    };
  },
  computed: {
    currentPathReferrer() {
      return `${QueryParams.Referrer}=${encodeURIComponent(this.$route.fullPath)}`;
    },
    loginPath() {
      return `${routes.login}?${this.currentPathReferrer}`;
    },
    signupPath() {
      return `${routes.signup}?${this.currentPathReferrer}`;
    },
    isBuilderDataSub() {
      return this.$store.getters['user/isBuilderDataSub'];
    },
  },
  watch: {
    user(value) {
      this.links = this.getNavLinksByRoles(value.roles);
    },
  },
  methods: {
    handleLogoClick() {
      this.navigateTo(routes.root);
    },

    handleSignOutClick() {
      this.$emit(HeaderPropsEventType.SignOut);
    },

    navigateTo(route) {
      this.$router.push(route);
    },

    getNavLinksByRoles(roles) {
      if (!roles) {
        return COMMON_NAV_LINKS;
      }

      if (roles.contractor) {
        return CONTRACTOR_NAV_LINKS;
      }

      if (roles.admin) {
        return ADMIN_NAV_LINKS;
      }

      return COMMON_NAV_LINKS;
    },
  },
};


import List from '@/ui-kit/components/list';
import ListItem from '@/ui-kit/components/list-item';
import MenuTooltip from '@/ui-kit/components/menu-tooltip';
import { MenuTooltipSlotName } from '@/ui-kit/components/menu-tooltip/enums';
import { IconType } from '@/ui-kit/components/icon/enums';
import { StyleSizeType, StylePositionType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import Accordion from '@/ui-kit/components/accordion';
import Icon from '@/ui-kit/components/icon';
import Divider from '@/ui-kit/components/divider';
import Gutter from '@/ui-kit/components/gutter';
import IconButton from '@/ui-kit/components/icon-button';
import ExternalNavigationHelper from '@/utils/external-navigation';
import { ExternalLinks } from '@/utils/external-navigation/constants';

const OPENED_LINK_LABEL = 'Main Menu';

export default {
  name: 'HeaderHamburger',
  components: {
    IconButton,
    Gutter,
    Divider,
    Icon,
    Accordion,
    List,
    ListItem,
    MenuTooltip,
  },
  props: {
    user: {
      type: Object,
      default: () => ({ isAuthenticated: false, roles: {} }),
    },
    links: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      openedLink: '',
      isDropdownViewable: true,
      MenuTooltipSlotName,
      IconType,
      StyleSizeType,
      StylePositionType,
      StyleColorSchemeType,
    };
  },
  computed: {
    linkIcon() {
      return this.openedLink ? IconType.ChevronLeft : '';
    },
    linkAppendIcon() {
      return !this.openedLink ? IconType.ChevronRight : '';
    },
  },
  methods: {
    getLabel(label) {
      return !this.openedLink ? label : OPENED_LINK_LABEL;
    },
    getIsAccordionDividerVisible(index, length, label) {
      return !this.openedLink
        && this.getIsNotLastItem(index, length)
        && this.getIsLinkVisible(label);
    },
    getIsNotLastItem(index, length) {
      const lastIndex = length - 1;

      return index !== lastIndex;
    },
    getIsLinkVisible(route) {
      return !this.openedLink || this.openedLink === route;
    },
    getIsSingleLink(link) {
      const hasNoNestedLinks = !link.nestedLinks || !link.nestedLinks.length;
      const hasRedirectToProperty = !!link.redirectTo;

      return hasNoNestedLinks && hasRedirectToProperty;
    },
    getLinkKey(index) {
      return `header-hamburger-link-${index}`;
    },
    getNestedLinkKey(index) {
      return `header-hamburger-nested-link-${index}`;
    },
    handleSingleLinkClick(redirectTo) {
      this.$router.push(redirectTo);
      this.resetOpenedLink();
      this.closeDropdownAfterClick();
    },
    handleLinkClick(link) {
      this.openNestedLinks(link);
    },
    handleNestedLinkClick(route) {
      this.$router.push(route);
      this.resetOpenedLink();
      this.closeDropdownAfterClick();
    },
    closeDropdownAfterClick() {
      const DELAY = 200;
      this.isDropdownViewable = false;

      setTimeout(() => {
        this.isDropdownViewable = true;
      }, DELAY);
    },
    resetOpenedLink() {
      this.openedLink = '';
    },
    handleFacebookClick() {
      ExternalNavigationHelper.openInNewWindow(ExternalLinks.FacebookBode);
    },
    handleLinkedInClick() {
      ExternalNavigationHelper.openInNewWindow(ExternalLinks.LinkedInBode);
    },
    openNestedLinks(link) {
      if (link === this.openedLink) {
        this.openedLink = '';

        return;
      }

      this.openedLink = link;
    },
  },
};

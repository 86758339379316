import { ExternalLinkProperty } from '../enums';
import { StyleColorSchemeType } from '../../../styles/enums';

const ExternalLinkProps = {
  props: {
    [ExternalLinkProperty.ClassName]: {
      default: '',
      type: String,
    },
    [ExternalLinkProperty.Href]: {
      required: true,
      type: String,
    },
    [ExternalLinkProperty.ColorScheme]: {
      default: StyleColorSchemeType.Primary,
      type: String,
    },
    [ExternalLinkProperty.HasChevron]: {
      default: false,
      type: Boolean,
    },
    [ExternalLinkProperty.IsUnderlined]: {
      default: false,
      type: Boolean,
    },
  },
};

export default ExternalLinkProps;

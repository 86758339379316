export const START_TOP_POSITION = 0;

export const BehaviorType = {
  Smooth: 'smooth',
};

class WindowScrollHelper {
  static scrollOnTop() {
    this.#scroll({ top: START_TOP_POSITION });
  }

  static scrollOnTopSmoothly() {
    this.#scroll({
      top: START_TOP_POSITION,
      behavior: BehaviorType.Smooth,
    });
  }

  static scrollIntoView(element, options) {
    element.scrollIntoView(options);
  }

  static #scroll(options) {
    window.scrollTo(options);
  }
}

export default WindowScrollHelper;

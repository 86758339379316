
import { TypographyType, TypographyProperty } from './enums';
import TypographyProps from './mixins/TypographyProps';
import Header1 from './fonts/h1';
import Header2 from './fonts/h2';
import Header3 from './fonts/h3';
import Header4 from './fonts/h4';
import Header5 from './fonts/h5';
import Header6 from './fonts/h6';
import Paragraph from './fonts/p';
import Span from './fonts/span';
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import { StyleAlignmentType } from '../../styles/enums';

export default {
  name: 'Typography',
  mixins: [TypographyProps],
  data() {
    return {
      TypographyProperty,
    };
  },
  computed: {
    textComponent() {
      switch (this[TypographyProperty.Type]) {
        case TypographyType.Header1:
          return Header1;
        case TypographyType.Header2:
          return Header2;
        case TypographyType.Header3:
          return Header3;
        case TypographyType.Header4:
          return Header4;
        case TypographyType.Header5:
          return Header5;
        case TypographyType.Header6:
          return Header6;
        case TypographyType.Span:
          return Span;
        case TypographyType.Paragraph:
        default:
          return Paragraph;
      }
    },
    typographyClassNames() {
      return new StyleClassNamesBuilder('typography')
        .addColorSchemeConditionClassNames(this[TypographyProperty.ColorScheme])
        .addConditionClassNames(
          Object.values(StyleAlignmentType),
          this[TypographyProperty.Alignment],
        )
        .addConditionClassName('line-through', this[TypographyProperty.LineThrough])
        .build();
    },
  },
};

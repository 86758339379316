import { Enums } from '@bode-canada/core';

export const GEO_LOCATION_URL = 'https://ipapi.co/json/' as const;

export const LISTING_ORIGIN_TYPES = {
  Bode: 'Bode',
  Repliers: 'Repliers_REBGV',
  Bridge: 'Bridge_CREB',
  BridgeRAE: 'Bridge_RAE',
  DDF: 'DDF_AB',
  bode: 'Bode',
  repliers: 'Repliers_REBGV',
  bridge: 'Bridge_CREB',
  bridgeRAE: 'Bridge_RAE',
  ddf: 'DDF_AB',
} as const;

export const LISTING_TRACK_EVENT_ORIGIN_TYPES = {
  Bode: 'Bode',
  Repliers: 'MLS',
  Bridge: 'MLS',
  BridgeRAE: 'MLS',
  DDF: 'MLS',
  MLS: 'MLS',
} as const;

export const PROVINCES = {
  [Enums.ProvinceEnum.AB]: Enums.ProvinceEnum.AB,
  [Enums.ProvinceEnum.BC]: Enums.ProvinceEnum.BC,
  [Enums.ProvinceEnum.ON]: Enums.ProvinceEnum.ON,
} as const;

export const DIRECTIONS = {
  East: 'East',
  North: 'North',
  West: 'West',
  South: 'South',
  Northeast: 'Northeast',
  Northwest: 'Northwest',
  Southeast: 'Southeast',
  Southwest: 'Southwest',
} as const;

export const DIRECTIONS_OPTIONS = [
  { label: DIRECTIONS.East, value: DIRECTIONS.East },
  { label: DIRECTIONS.North, value: DIRECTIONS.North },
  { label: DIRECTIONS.West, value: DIRECTIONS.West },
  { label: DIRECTIONS.South, value: DIRECTIONS.South },
  { label: DIRECTIONS.Northeast, value: DIRECTIONS.Northeast },
  { label: DIRECTIONS.Northwest, value: DIRECTIONS.Northwest },
  { label: DIRECTIONS.Southeast, value: DIRECTIONS.Southeast },
  { label: DIRECTIONS.Southwest, value: DIRECTIONS.Southwest },
] as const;

// todo: change text to label when we stop use old select field
// todo: change province select component to this enum when all ontario
// implementations finished
export const PROVINCE_OPTIONS = [
  { text: 'Alberta', value: PROVINCES.AB },
  { text: 'British Columbia', value: PROVINCES.BC },
  { text: 'Ontario', value: PROVINCES.ON },
] as const;

// todo: Remove this from the store province select and replace with PROVINCE_OPTIONS
// seen above once all pages are correctly implemented (Ontario can break un-implemented pages)
export const PROVINCE_OPTIONS_NOT_ONTARIO = [
  { text: 'Alberta', value: PROVINCES.AB },
  { text: 'British Columbia', value: PROVINCES.BC },
] as const;

export const PROVINCE_OPTIONS_WITH_SHORT_LABEL = [
  { label: PROVINCES.AB, value: PROVINCES.AB },
  { label: PROVINCES.BC, value: PROVINCES.BC },
  { label: PROVINCES.ON, value: PROVINCES.ON },
] as const;


import Typography from '@/ui-kit/components/typography';
import { TypographyProperty, TypographyType } from '@/ui-kit/components/typography/enums';
import { StyleColorSchemeType } from '@/ui-kit/styles/enums';

export default {
  name: 'FooterMobileNavigationAccordionHeader',
  components: { Typography },
  props: {
    linkGroupName: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      TypographyProperty,
      TypographyType,
      StyleColorSchemeType,
    };
  },
};

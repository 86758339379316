import { AccordionProperty } from '../enums';

const AccordionProps = {
  props: {
    [AccordionProperty.DefaultIsExpanded]: {
      default: false,
      type: Boolean,
    },
    [AccordionProperty.IsDisabled]: {
      default: false,
      type: Boolean,
    },
    [AccordionProperty.IsStatusContentHidden]: {
      default: false,
      type: Boolean,
    },
    [AccordionProperty.StatusSettings]: {
      default: () => ({}),
      type: Object,
    },
    [AccordionProperty.HeaderClassName]: {
      default: '',
      type: String,
    },
    [AccordionProperty.ContentClassName]: {
      default: '',
      type: String,
    },
    [AccordionProperty.IsRadioSelect]: {
      default: false,
      type: Boolean,
    },
  },
};

export default AccordionProps;

// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import VCurrencyField from 'v-currency-field';

Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: 0,
  autoDecimalMode: true,
  min: 0,
  max: null,
  defaultValue: 0,
});

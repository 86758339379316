import { IconButtonProperty, StyleFillType, ShapeType } from '../enums';
import { ButtonType } from '../../button/enums';
import { StyleSizeType, StyleColorSchemeType } from '../../../styles/enums';

const IconButtonProps = {
  props: {
    [IconButtonProperty.ButtonType]: {
      default: ButtonType.Button,
      type: String,
    },
    [IconButtonProperty.ClassName]: {
      default: '',
      type: String,
    },
    [IconButtonProperty.IconClassName]: {
      default: '',
      type: String,
    },
    [IconButtonProperty.Type]: {
      required: true,
      type: String,
    },
    [IconButtonProperty.Size]: {
      default: StyleSizeType.Medium,
      type: String,
    },
    [IconButtonProperty.ColorScheme]: {
      default: StyleColorSchemeType.Primary,
      type: String,
    },
    [IconButtonProperty.IsDisabled]: {
      default: false,
      type: Boolean,
    },
    [IconButtonProperty.IsLoading]: {
      default: false,
      type: Boolean,
    },
    [IconButtonProperty.FillType]: {
      default: StyleFillType.Button,
      type: String,
    },
    [IconButtonProperty.ShapeType]: {
      default: ShapeType.Rounded,
      type: String,
    },
  },
};

export default IconButtonProps;

import { SET, SET_NOTIFICATIONS, SET_PROPERTY_OWNERSHIPS } from './mutations';
import { getUser } from '@/utils/user';
import popup from '@/utils/auth/window';
import AccountApiService from '@/services/api/user/account';
import STORE_NAME from './name';

export const INIT = 'init';
export const REFRESH = 'refresh';
export const AUTHENTICATE = 'authenticate';
export const UPDATE_NOTIFICATIONS = 'update-notifications';
export const ADD_ROLE = 'add-role';
export const ADD_PROPERTY_OWNERSHIP = 'add-property-ownership';
export const getAction = (type) => `${STORE_NAME}/${type}`;
export default {
  async [INIT]({ commit }, { forceRefresh, path } = {}) {
    const user = await getUser(forceRefresh, path);

    commit(SET, user);

    return user;
  },
  async [AUTHENTICATE]({ commit, state }, referrer = window.location.pathname) {
    let { userData } = state;

    if (!userData.isAuthenticated) {
      userData = await popup(`/login?referrer=${referrer}`);
    }

    commit(SET, userData);

    return userData;
  },
  [REFRESH]({ dispatch }) {
    return dispatch(INIT, { forceRefresh: true, path: '/user/notifications' });
  },
  [UPDATE_NOTIFICATIONS]({ commit }, notifications) {
    commit(SET_NOTIFICATIONS, notifications);
  },
  async [ADD_ROLE]({ commit, state }, role) {
    const { userData } = state;
    const { roles } = userData;

    if (!roles[role]) {
      await AccountApiService.patchAccount({ roles: [...Object.keys(roles), role] });
      commit(SET, { ...userData, roles: { ...roles, [role]: true } });
    }
  },
  [ADD_PROPERTY_OWNERSHIP]({ commit, state }, propertyOwnership) {
    const { userData } = state;
    const { propertyOwnerships } = userData;
    commit(SET_PROPERTY_OWNERSHIPS, [...propertyOwnerships, propertyOwnership]);
  },
};

/* eslint-disable camelcase */
import {
  SET_HERO_CONTENT,
  SET_CONTACT_CARDS_CONTENT,
  SET_OFFICE_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;
export default {

  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      hero_section,
      contact_cards,
      office_title,
      office_subtitle,
      office_section,
    } = data;
    const [heroData] = hero_section;
    commit(SET_HERO_CONTENT, heroData);
    commit(SET_CONTACT_CARDS_CONTENT, contact_cards);
    commit(SET_OFFICE_CONTENT, {
      office_title,
      office_subtitle,
      office_section,
    });
    commit(SET_IS_CONTENT_LOADED, true);
  },
};


import Spinner from '../spinner';
import { ButtonPropsEventType, ButtonFillType, ButtonProperty } from './enums';
import { StyleColorSchemeType, StyleSizeType, StylePositionType } from '../../styles/enums';
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import Gutter from '../gutter';
import Icon from '../icon';
import { IconType } from '../icon/enums';
import { RedirectType } from '../../enums';
import ExternalNavigationHelper from '@/utils/external-navigation';
import ButtonProps from './mixins/ButtonProps';

export default {
  name: 'Button',
  components: {
    Icon,
    Gutter,
    Spinner,
  },
  mixins: [ButtonProps],
  data() {
    return {
      IconType,
      StyleSizeType,
      StylePositionType,
      StyleColorSchemeType,
      ButtonProperty,
    };
  },
  computed: {
    buttonClassNames() {
      return new StyleClassNamesBuilder('button')
        .addClassName(this[ButtonProperty.ClassName])
        .addSizeConditionClassNames(this[ButtonProperty.Size])
        .addColorSchemeConditionClassNames(this[ButtonProperty.ColorScheme])
        .addConditionClassNames(Object.values(ButtonFillType), this[ButtonProperty.Fill])
        .build();
    },
    hasRedirection() {
      return !!this[ButtonProperty.RedirectTo];
    },
    additionalIconsColorScheme() {
      if (this[ButtonProperty.IconColor]) {
        return this[ButtonProperty.IconColor];
      }

      if (this[ButtonProperty.ColorScheme] === StyleColorSchemeType.White) {
        return StyleColorSchemeType.Secondary;
      }

      return this[ButtonProperty.Fill] === ButtonFillType.Filled
        ? StyleColorSchemeType.White
        : this[ButtonProperty.ColorScheme];
    },
    isIconVisible() {
      return this[ButtonProperty.ShowIcon] || this.hasRedirection;
    },
  },
  methods: {
    handleClick() {
      if (this[ButtonProperty.IsLoading] || this[ButtonProperty.IsDisabled]) {
        return;
      }

      if (this[ButtonProperty.RedirectTo]) {
        this.handleRedirect(this[ButtonProperty.RedirectTo]);
      }

      this.$emit(ButtonPropsEventType.Click);
    },
    handleRedirect(redirectTo) {
      switch (this[ButtonProperty.RedirectType]) {
        case RedirectType.NewWindow:
          return ExternalNavigationHelper.openInNewWindow(redirectTo);
        case RedirectType.ThisWindow:
        default:
          return this.$router.push(redirectTo);
      }
    },
  },
};

import { SET_IS_OFFER_ADDRESS_AUTO_COMPLETE_DISABLED } from './mutations';
import STORE_NAME from './name';

export const SET_OFFER_ADDRESS_AUTO_COMPLETE_DISABLED = 'set-offer-address-auto-complete-disabled';
export const getAction = (type) => `${STORE_NAME}/${type}`;
export default {
  [SET_OFFER_ADDRESS_AUTO_COMPLETE_DISABLED]({ commit }, value) {
    commit(SET_IS_OFFER_ADDRESS_AUTO_COMPLETE_DISABLED, value);
  },
};

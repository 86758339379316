let popup = null;

/**
 * Create authentication popup window and listen for login success.
 *
 * @note the login page post a message with the topic "authentication" to let opener
 * windows know that the user logged in.
 *
 * @todo we should reject the promise if window is closed without authenticated
 * @todo we should support multiple windows at a given time (or one window if possible).
 */

export default function createPopup(path = '/login') {
  return new Promise((resolve) => {
    if (popup) popup.close();
    popup = window.open(path, 'authentication', 'width=450,height=580,menubar=no,toolbar=no');
    const close = (event) => {
      const { data: { topic, user } } = event;
      if (topic === 'login') {
        popup.close();
        resolve(user);
        window.removeEventListener('message', close);
      }
    };
    window.addEventListener('message', close);
  });
}

import { ListProperty } from '../enums';

const ListProps = {
  props: {
    [ListProperty.ClassName]: {
      default: '',
      type: String,
    },
  },
};

export default ListProps;


import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import ListProps from './mixins/ListProps';
import { ListProperty } from './enums';

export default {
  name: 'List',
  mixins: [ListProps],
  computed: {
    listClassNames() {
      return new StyleClassNamesBuilder('list')
        .addClassName(this[ListProperty.ClassName])
        .build();
    },
  },
};

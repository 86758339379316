import routes from '@/constants/routes';
import { ButtonFillType } from '@/ui-kit/components/button/enums/index';

export const DEFAULT_CONFIRM_CONTENT = {
  image: '',
  header: '',
  text: '',
  buttons: [
    {
      title: 'Bōde for buyers',
      url: routes.howItWorksBuyer,
      fill: ButtonFillType.Filled,
    },
    {
      title: 'Bōde for sellers',
      url: routes.howItWorksSeller,
      eventType: 'secondary-button-clicked',
      fill: ButtonFillType.Outlined,
    },
  ],
};

export default {
  DEFAULT_CONFIRM_CONTENT,
};

import axios from 'axios';
import { getCurrentSession } from './auth';

class API {
  constructor() {
    this.version = 6;
    this.axios = axios.create({
      baseURL: `${process.client ? '' : process.env.NUXT_ENV_PROXY_URL}${process.env.NUXT_ENV_API}`,
      timeout: 60000,
    });
    this.popups = {};
  }

  init(popups) {
    this.popups = popups;
  }

  async get(url, params = {}, config = {}, errorMessages = {}) {
    const requestConfig = {
      ...config,
      method: 'GET',
      url,
      params,
    };
    const res = await this.request(requestConfig, errorMessages);
    return res.data;
  }

  async post(url, data, config = {}, errorMessages = {}) {
    const requestConfig = {
      ...config,
      method: 'POST',
      url,
      data,
    };
    const res = await this.request(requestConfig, errorMessages);
    return res.data;
  }

  async put(url, data, config = {}, errorMessages = {}) {
    const requestConfig = {
      ...config,
      method: 'PUT',
      url,
      data,
    };
    const res = await this.request(requestConfig, errorMessages);
    return res.data;
  }

  async delete(url, data, errorMessages = {}) {
    const res = await this.request(
      {
        method: 'DELETE',
        url,
        data,
      },
      errorMessages,
    );
    return res.data;
  }

  async request(config, errorMessage) {
    try {
      await refresh();
      const res = await this.axios.request(config);
      if (
        res.headers['x-version']
        && res.headers['x-version'] !== this.version
      ) {
        this.popups.promptReload = true;
      }
      return res;
    } catch (e) {
      if (e.response && e.response.status >= 400) {
        this.popups.errorMsg = errorMessage[e.response.status]
          ? errorMessage[e.response.status]
          : 'Something went wrong!';
        this.popups.error = true;
        throw e;
      } else {
        throw e;
      }
    }
  }
}

/**
 * Refresh session.
 * @not Api calls can be triggered when the current access token is expired
 * and we need to make sure they are refreshed.
 *
 * @private
 */

async function refresh() {
  try {
    await getCurrentSession();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

export default new API();

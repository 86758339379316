
import Gutter from '@/ui-kit/components/gutter';
import Typography from '@/ui-kit/components/typography';
import LayoutGridContainer from '@/ui-kit/components/layout-grid/container';
import LayoutGridItem from '@/ui-kit/components/layout-grid/item';
import { LayoutItemProperty, LayoutItemColumn } from '@/ui-kit/components/layout-grid/item/enums';
import { LayoutContainerProperty } from '@/ui-kit/components/layout-grid/container/enums';
import { TypographyProperty, TypographyType } from '@/ui-kit/components/typography/enums';
import {
  StyleColorSchemeType,
  StyleFontWeightType,
  StyleSizeType,
  StylePositionType,
} from '@/ui-kit/styles/enums';
import { GutterProperty } from '@/ui-kit/components/gutter/enums';
import FooterNavigationList from '../shared/list';
import { FooterViewMode, FooterLinkEventType } from '../shared/enums';
import FooterTrackEvent from '../shared/mixins/TrackEvent';

export default {
  name: 'FooterDesktopNavigation',
  components: {
    Gutter,
    Typography,
    FooterNavigationList,
    LayoutGridItem,
    LayoutGridContainer,
  },
  mixins: [FooterTrackEvent],
  props: {
    links: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      LayoutItemProperty,
      LayoutItemColumn,
      LayoutContainerProperty,
      TypographyProperty,
      TypographyType,
      GutterProperty,
      StyleColorSchemeType,
      StyleFontWeightType,
      StyleSizeType,
      StylePositionType,
      FooterLinkEventType,
    };
  },
  methods: {
    handleLinkClick({ label }) {
      this.trackLinkClick(FooterViewMode.Desktop, label);
    },
  },
};

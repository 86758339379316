export const HOW_IT_WORKS = '/how-it-works';
export const SELLER = '/seller';
export const BUYER = '/buyer';
export const BUILDER = '/builder';
export const OFFERS = '/offers';
export const SETTINGS = '/settings';
export const PRO = '/pro';
export const LISTINGS = '/listings';
export const USERS = '/users';
export const SEARCH = '/search';
export const ADMIN = '/admin';
export const DASHBOARD = '/dashboard';
export const VIEWINGS = '/viewings';
export const SUBMIT_SUCCESS = '/submit-success';
export const VERIFICATION = '/verification';

const routes = {
  admin: ADMIN,
  adminOfferManagement: `${ADMIN}/offer-management`,
  adminShowings: `${ADMIN}/showings`,
  adminListingImport: `${ADMIN}/listings/import`,
  adminListingUpdate: `${ADMIN}/listings/update`,
  adminBuilderReportManagement: `${ADMIN}/builder-report-management`,
  app: '/app',
  builderDataReports: '/builder-data-reports',
  buyer: BUYER,
  buyerChecklist: `${BUYER}-checklist`,
  buySellYourHome: '/buy-sell-your-home',
  buyYourHome: '/buy-your-home',
  calendar: '/calendar',
  comparables: '/comparables',
  commissionCalculator: '/commissions-calculator',
  contact: '/contact',
  documents: '/documents',
  docuSign: '/ds',
  dashboard: DASHBOARD,
  favourites: '/favourites',
  faq: '/faq',
  removeFavourite: '/listings/favourites',
  howItWorks: HOW_IT_WORKS,
  howItWorksBuilder: `${HOW_IT_WORKS}${BUILDER}`,
  howItWorksBuyer: `${HOW_IT_WORKS}${BUYER}`,
  howItWorksSeller: `${HOW_IT_WORKS}${SELLER}`,
  listHome: '/list-home',
  linkListing: '/link-listing',
  listingDashboard: `${LISTINGS}/:id${DASHBOARD}`,
  listingViewings: `${LISTINGS}/:id${VIEWINGS}`,
  listingSubmitSuccess: `${LISTINGS}/:id${SUBMIT_SUCCESS}`,
  listings: LISTINGS,
  listingsReview: `${LISTINGS}/review`,
  login: '/login',
  resetPassword: 'login/reset',
  logout: '/logout',
  signup: '/signup',
  signupConfirm: '/signup/confirm',
  signupAdditionalInformation: '/signup/additional-information',
  messages: '/messages',
  myAbodeOverview: '/my-abode-overview',
  myListings: '/my-listings',
  notFound: '/error404',
  offers: OFFERS,
  offersSummary: `${OFFERS}/summary`,
  offerSubmitSuccess: `${OFFERS}${SUBMIT_SUCCESS}`,
  offerManagementBuyer: '/offer-management-buyer',
  offersManagementSeller: '/offer-management-seller',
  pro: PRO,
  profile: '/user',
  proSearch: `${PRO}-search`,
  proEdit: `${PRO}-edit`,
  proEditProfile: `${PRO}-edit/profile`,
  proEditService: `${PRO}-edit/service`,
  proMarketplace: `${PRO}-marketplace`,
  proMarketplaceViewRequest: `${PRO}-marketplace/view-request`,
  proMarketplacePortal: `${PRO}-marketplace${PRO}-portal`,
  proMarketplaceServiceRequests: `${PRO}-marketplace/service-requests`,
  proMarketplaceServiceRequest: `${PRO}-marketplace/service-request`,
  proMarketplacesServiceRequestPhotos: `${PRO}-marketplace/service-request/photography-measurements`,
  proMarketPlaceForPros: `${PRO}-marketplace/marketplace-for-pros`,
  proMarketPlaceForProsSignup: `${PRO}-marketplace/marketplace-for-pros/pro-sign-up`,
  proMarketPlaceForProsLogin: `${PRO}-marketplace/pro-login`,
  proMarketPlaceForProsSuccess: `${PRO}-marketplace/marketplace-for-pros/pro-sign-up/successful`,
  marketData: '/market-data',
  requestSticker: '/request-sticker',
  root: '/',
  sellerChecklist: `${SELLER}-checklist`,
  savedSearches: `${SEARCH}/saved-search-list`,
  savedSearchList: '/saved-search-list',
  search: SEARCH,
  seller: SELLER,
  sellYourHome: '/sell-your-home',
  settings: SETTINGS,
  settingsAccount: `${SETTINGS}/account`,
  settingsNotifications: `${SETTINGS}/notifications`,
  settingsProfile: `${SETTINGS}/profile`,
  settingsVerification: `${SETTINGS}${VERIFICATION}`,
  soldData: '/sold-data',
  soldProperty: '/sold-property',
  termsOfService: '/terms-of-service',
  privacyPolicy: '/privacy',
  user: '/user',
  users: USERS,
  whoWeAre: '/whoweare',
  whyBode: '/why-bode',
  notifications: '/notifications',
  listingManagement: '/listing-management',
  listingEditing: 'listing-editing',
  homeownerDashboard: '/homeowner-dashboard',
};

export default routes;


import FooterNavigationList from '../shared/list';
import FooterLinkEvents from '../shared/mixins/LinkEvents';
import { FooterLinkEventType } from '../shared/enums';

export default {
  name: 'FooterMobileNavigationAccordionContent',
  components: {
    FooterNavigationList,
  },
  mixins: [FooterLinkEvents],
  props: {
    links: {
      default: () => ([]),
      type: Array,
    },
  },
  data() {
    return {
      FooterLinkEventType,
    };
  },
};

/* eslint-disable camelcase */
import {
  SET_TITLE_CONTENT,
  SET_CARD_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;
export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      pro_portal_title,
      pro_portal_card,
    } = data;

    commit(SET_TITLE_CONTENT, pro_portal_title);
    commit(SET_CARD_CONTENT, pro_portal_card);
    commit(SET_IS_CONTENT_LOADED, true);
  },
};

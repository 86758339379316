
import { FooterLinkProperty, FooterLinkEventType } from './enums';
import FooterNavigationLinkFactory from './factories/link';
import FooterLinkEvents from './mixins/LinkEvents';

export default {
  name: 'FooterNavigationList',
  components: {
    FooterNavigationLinkFactory,
  },
  mixins: [FooterLinkEvents],
  props: {
    links: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      FooterLinkProperty,
      FooterLinkEventType,
    };
  },
};

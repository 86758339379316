import routes from '../constants/routes';
import { getAction, INIT } from '@/store/user/actions';

const initializeUserStore = async (store, shouldWait) => {
  const dispatchInit = () => store.dispatch(getAction(INIT));

  if (shouldWait) {
    await dispatchInit();
  } else {
    dispatchInit();
  }
};

export default async ({ store, route, redirect }) => {
  const requiresAuth = route.meta.some((metaData) => metaData.requiresAuth);
  const userState = store.state.user || {};

  if (!userState.initialized && process.client) {
    await initializeUserStore(store, requiresAuth);
  }

  if (requiresAuth && !userState.userData.isAuthenticated) {
    redirect(`${routes.login}?referrer=${encodeURIComponent(route.fullPath)}`);
  }
};

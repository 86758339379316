
import BodePinIcon from '@/components/icons/bodepin';
import useCanonicalHead from '@/mixins/useCanonicalHead';

export default {
  name: 'Error404Page',
  components: {
    BodePinIcon,
  },
  mixins: [useCanonicalHead()],
  head: {
    title: '404 Page Not Found',
    meta: [
      // Do not allow search engine robots to index or archive error page
      { name: 'robots', content: 'noindex, noarchive' },
    ],
  },
  methods: {
    goBack() {
      window.history.back();
    },
  },
};

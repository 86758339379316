import { ImageProperty } from '../enums';

const ImageCommonProps = {
  props: {
    [ImageProperty.Src]: {
      required: true,
      type: String,
    },
    [ImageProperty.Alt]: {
      required: true,
      type: String,
    },
    [ImageProperty.ImageClassName]: {
      default: '',
      type: String,
    },
  },
};

export default ImageCommonProps;

import { track } from '@/utils/analytics';

const FooterTrackEvent = {
  methods: {
    trackLinkClick(viewType, linkLabel) {
      track(`${viewType} Footer_${linkLabel} link click`);
    },
  },
};

export default FooterTrackEvent;

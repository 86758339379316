import {
  StyleBemType,
  StyleColorSchemeType,
  StylePositionType,
  StyleSizeType,
} from '../enums';

const BemSymbols = {
  [StyleBemType.Block]: '-',
  [StyleBemType.Element]: '__',
  [StyleBemType.Modifier]: '--',
};

class StyleClassNamesBuilder {
  rootClassName = '';

  classNames = [];

  constructor(rootClassName) {
    this.rootClassName = rootClassName;
    this.classNames = [rootClassName];
  }

  addClassName(className) {
    if (!className) {
      return this;
    }

    this.classNames = [...this.classNames, className];

    return this;
  }

  addClassNames(classNames) {
    if (!classNames) {
      return this;
    }

    this.classNames = [...this.classNames, ...classNames];

    return this;
  }

  addConditionClassName(variant, condition) {
    if (!condition) {
      return this;
    }

    const className = this.#getBemClassName(variant, StyleBemType.Modifier);

    return this.addClassName(className);
  }

  addBlockClassName(blockName) {
    const className = this.#getBemClassName(blockName, StyleBemType.Block);

    return this.addClassName(className);
  }

  addElementClassName(element) {
    const className = this.#getBemClassName(element, StyleBemType.Modifier);

    return this.addClassName(className);
  }

  addConditionClassNames(variants, currentVariant) {
    const classNames = this.#getConditionClassNames(variants, currentVariant);

    return this.addClassNames(classNames);
  }

  addSizeConditionClassNames(size) {
    const sizeValues = Object.values(StyleSizeType);

    return this.addConditionClassNames(sizeValues, size);
  }

  addColorSchemeConditionClassNames(colorScheme) {
    const colorSchemeValues = Object.values(StyleColorSchemeType);

    return this.addConditionClassNames(colorSchemeValues, colorScheme);
  }

  addPositionConditionClassNames(position) {
    const positionValues = Object.values(StylePositionType);

    return this.addConditionClassNames(positionValues, position);
  }

  build() {
    return this.classNames.join(' ');
  }

  #getConditionClassNames(variants, currentVariant) {
    return variants
      .filter((variant) => variant === currentVariant)
      .map((variant) => this.#getBemClassName(variant, StyleBemType.Modifier));
  }

  #getBemClassName(variant, bemSymbol) {
    const modifierSymbol = BemSymbols[bemSymbol];

    return `${this.rootClassName}${modifierSymbol}${variant}`;
  }
}

export default StyleClassNamesBuilder;

import { StyleOrientationType } from '@/ui-kit/styles/enums';
import { DividerProperty } from '../enums';

const DividerProps = {
  props: {
    [DividerProperty.ClassName]: {
      default: '',
      type: String,
    },
    [DividerProperty.Orientation]: {
      default: StyleOrientationType.Horizontal,
      type: String,
    },
  },
};

export default DividerProps;

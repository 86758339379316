import { LayoutItemProperty } from '../enums';

const makeSizeProps = () => (
  Object.values(LayoutItemProperty).reduce((acc, size) => ({
    ...acc,
    [size]: { type: Number },
  }), {})
);

const LayoutItemProps = {
  props: {
    ...makeSizeProps(),
  },
};

export default LayoutItemProps;

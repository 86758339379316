import { StyleSizeType } from '../../../../styles/enums';

export const LayoutItemColumn = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
  Six: 6,
  Seven: 7,
  Eight: 8,
  Nine: 9,
  Ten: 10,
  Eleven: 11,
  Twelve: 12,
};

export const LayoutItemProperty = {
  ...StyleSizeType,
};

export default {
  LayoutItemProperty,
  LayoutItemColumn,
};


import TypographyStyles from '../mixins/TypographyStyles';

export default {
  name: 'Paragraph',
  mixins: [TypographyStyles],
  computed: {
    paragraphClassNames() {
      return this.getTypographyClassNames('paragraph');
    },
  },
};

import { Enums } from '@bode-canada/core';
import {
  OffersManagementOfferDtoField, OffersManagementUserDtoField,
} from '@/services/api/offers-management/enums';
import {
  OffersManagementTableColumnKey,
  OffersManagementTableOfferStatus,
  OfferManagementExpirationTimeStatus,
} from '@/components/page-specific/offers-management/table/enums';
import { GridCellCommonPropsType } from '@/ui-kit/components/grid/enums';
import { StyleColorSchemeType } from '@/ui-kit/styles/enums';
import OfferManagementOfferDetailsService
  from '@/components/page-specific/offers-management/services/OfferManagementOfferDetailsService';
import DateHelper from '@/ui-kit/helpers/DateHelper';

const FINAL_OFFER_STATUSES = [
  OffersManagementTableOfferStatus.Accepted,
  OffersManagementTableOfferStatus.Rejected,
];

class OfferManagementTableMapService {
  static getTableRowsFromOffers(offers) {
    return offers
      .reduce((acc, offer, index) => {
        const prevOffer = this.#getPrevOffer(offers, index);
        const nextOffer = this.#getNextOffer(offers, index);
        const offerStatus = this.#getOfferStatus(prevOffer, offer, nextOffer);

        if (!offerStatus) {
          return acc;
        }

        return [...acc, this.#getTableRowFromOffer(prevOffer, offer, nextOffer)];
      }, []);
  }

  static #getTableRowFromOffer(prevOffer, offer, nextOffer) {
    const prevOfferNumberOfConditions = prevOffer
      && OfferManagementOfferDetailsService.getNumberOfConditions(prevOffer);
    const offerNumberOfConditions = offer
      && OfferManagementOfferDetailsService.getNumberOfConditions(offer);
    const offerStatus = this.#getOfferStatus(prevOffer, offer, nextOffer);
    const offerCommissionData = this.#getBuyerOfferCommissionValues(offer);
    const prevOfferCommissionData = this.#getBuyerOfferCommissionValues(prevOffer);

    return {
      [OffersManagementTableColumnKey.OfferStatus]: {
        [GridCellCommonPropsType.Value]: {
          text: offerStatus,
          avatars: [{ src: offer[OffersManagementUserDtoField.UserImage], alt: '' }],
          firstName: offer[OffersManagementUserDtoField.UserFirstName],
          lastName: offer[OffersManagementUserDtoField.UserLastName],
          isFinalOffer: this.#getIsFinalStatus(offerStatus),
        },
        [GridCellCommonPropsType.ColorSchema]: this.#getCommonCellColorSchema(offerStatus),
        [GridCellCommonPropsType.FillColorSchema]: this.#getCellFillColorSchema(offerStatus),
      },
      [OffersManagementTableColumnKey.OfferAmount]: {
        [GridCellCommonPropsType.Value]: offer[OffersManagementOfferDtoField.OfferPrice],
        [GridCellCommonPropsType.ColorSchema]: this.#getCounterCellColorSchema(
          prevOffer?.[OffersManagementOfferDtoField.OfferPrice],
          offer[OffersManagementOfferDtoField.OfferPrice],
          offerStatus,
        ),
        [GridCellCommonPropsType.FillColorSchema]: this.#getCellFillColorSchema(offerStatus),
      },
      [OffersManagementTableColumnKey.AgentCommission]: {
        [GridCellCommonPropsType.Value]: offerCommissionData?.buyerAgentCommission,
        [GridCellCommonPropsType.ColorSchema]: this.#getCounterCellColorSchema(
          prevOfferCommissionData?.buyerAgentCommission,
          offerCommissionData?.buyerAgentCommission,
          offerStatus,
        ),
        [GridCellCommonPropsType.FillColorSchema]: this.#getCellFillColorSchema(offerStatus),
      },
      [OffersManagementTableColumnKey.PriceMinusCommission]: {
        [GridCellCommonPropsType.Value]: offerCommissionData?.priceMinusSaleCommission,
        [GridCellCommonPropsType.ColorSchema]: this.#getCounterCellColorSchema(
          prevOfferCommissionData?.priceMinusSaleCommission,
          offerCommissionData?.priceMinusSaleCommission,
          offerStatus,
        ),
        [GridCellCommonPropsType.FillColorSchema]: this.#getCellFillColorSchema(offerStatus),
      },
      [OffersManagementTableColumnKey.Conditions]: {
        [GridCellCommonPropsType.Value]: offerNumberOfConditions,
        [GridCellCommonPropsType.ColorSchema]: this.#getCounterCellColorSchema(
          prevOfferNumberOfConditions,
          offerNumberOfConditions,
          offerStatus,
        ),
        [GridCellCommonPropsType.FillColorSchema]: this.#getCellFillColorSchema(offerStatus),
      },
      [OffersManagementTableColumnKey.PossessionDate]: {
        [GridCellCommonPropsType.Value]: offer[OffersManagementOfferDtoField.OfferPossessionDate],
        [GridCellCommonPropsType.ColorSchema]: this.#getCounterCellColorSchema(
          prevOffer?.[OffersManagementOfferDtoField.OfferPossessionDate],
          offer[OffersManagementOfferDtoField.OfferPossessionDate],
          offerStatus,
        ),
        [GridCellCommonPropsType.FillColorSchema]: this.#getCellFillColorSchema(offerStatus),
      },
      [OffersManagementTableColumnKey.SubmittedDate]: {
        [GridCellCommonPropsType.Value]: offer[OffersManagementOfferDtoField.OfferSubmittedDate],
        [GridCellCommonPropsType.ColorSchema]: this.#getCommonCellColorSchema(offerStatus),
        [GridCellCommonPropsType.FillColorSchema]: this.#getCellFillColorSchema(offerStatus),
      },
      [OffersManagementTableColumnKey.ExpiryDate]: {
        [GridCellCommonPropsType.Value]: this.#getExpirationRemainingTime(
          offer[OffersManagementOfferDtoField.OfferExpirationDate],
          offerStatus,
        ),
        [GridCellCommonPropsType.ColorSchema]: this.#getCommonCellColorSchema(offerStatus),
        [GridCellCommonPropsType.FillColorSchema]: this.#getCellFillColorSchema(offerStatus),
      },
      [OffersManagementTableColumnKey.OfferSummary]: {
        [GridCellCommonPropsType.Value]: {
          offerId: offer[OffersManagementOfferDtoField.OfferId],
          isFinalOffer: this.#getIsFinalStatus(offerStatus),
        },
        [GridCellCommonPropsType.ColorSchema]: this.#getCommonCellColorSchema(offerStatus),
        [GridCellCommonPropsType.FillColorSchema]: this.#getCellFillColorSchema(offerStatus),
      },
    };
  }

  static #getBuyerOfferCommissionValues(offer) {
    if (offer) {
      const buyerAgentCommission = OfferManagementOfferDetailsService
        .getBuyerAgentCommissionTerm(offer);
      const priceMinusSaleCommission = buyerAgentCommission
        ? offer[OffersManagementOfferDtoField.OfferPrice] - buyerAgentCommission
        : offer[OffersManagementOfferDtoField.OfferPrice];
      const offerCommissionData = {
        buyerAgentCommission,
        priceMinusSaleCommission,
      };

      return offerCommissionData;
    }

    return null;
  }

  static #getCellFillColorSchema(offerStatus) {
    switch (offerStatus) {
      case OffersManagementTableOfferStatus.Accepted:
        return StyleColorSchemeType.Secondary;
      case OffersManagementTableOfferStatus.Rejected:
        return StyleColorSchemeType.Danger;
      default:
        return '';
    }
  }

  static #getCommonCellColorSchema(offerStatus) {
    if (this.#getIsFinalStatus(offerStatus)) {
      return StyleColorSchemeType.White;
    }

    return StyleColorSchemeType.Gray;
  }

  static #getCounterCellColorSchema(prevOfferValue, offerValue, offerStatus) {
    if (this.#getIsFinalStatus(offerStatus)) {
      return StyleColorSchemeType.White;
    }

    const isValueChanged = prevOfferValue !== offerValue;

    if (prevOfferValue === 0 && isValueChanged) {
      return StyleColorSchemeType.Danger;
    }

    if (!prevOfferValue || !isValueChanged) {
      return StyleColorSchemeType.Gray;
    }

    return StyleColorSchemeType.Danger;
  }

  static #getOfferStatus(prevOffer, offer, nextOffer) {
    const prevOfferUserRole = prevOffer?.[OffersManagementUserDtoField.UserRole];
    const offerUserRole = offer?.[OffersManagementUserDtoField.UserRole];
    const offerStatus = offer?.[OffersManagementOfferDtoField.OfferStatus];

    if (prevOfferUserRole === offerUserRole && offerStatus !== Enums.OfferStatusEnum.DRAFT) {
      return '';
    }

    if (!nextOffer && offer.offerStatus === Enums.OfferStatusEnum.ACCEPTED) {
      return OffersManagementTableOfferStatus.Accepted;
    }

    if (!nextOffer && offer.offerStatus === Enums.OfferStatusEnum.REJECTED) {
      return OffersManagementTableOfferStatus.Rejected;
    }

    if (!prevOffer) {
      return OffersManagementTableOfferStatus.Initial;
    }

    if ((prevOffer && nextOffer) || (prevOffer && !nextOffer)) {
      return OffersManagementTableOfferStatus.Counter;
    }

    return '';
  }

  static #getIsFinalStatus(status) {
    return FINAL_OFFER_STATUSES.includes(status);
  }

  static #getNextOffer(offers, index) {
    const indexOfLastOffer = offers.length - 1;

    return index === indexOfLastOffer ? null : offers[index + 1];
  }

  static #getPrevOffer(offers, index) {
    return index === 0 ? null : offers[index - 1];
  }

  static #getExpirationRemainingTime(offerExpirationDate, offerStatus) {
    if ([
      OffersManagementTableOfferStatus.Initial,
      OffersManagementTableOfferStatus.Counter,
    ].includes(offerStatus)) {
      if (!offerExpirationDate) {
        return offerExpirationDate;
      }

      const isPast = DateHelper.getIsPast(offerExpirationDate);

      if (isPast) {
        return OfferManagementExpirationTimeStatus.Expired;
      }

      return DateHelper.getDateDifferenceTimeText(new Date(), offerExpirationDate);
    }

    return OfferManagementExpirationTimeStatus.NA;
  }
}

export default OfferManagementTableMapService;


import { StyleSizeType } from '../../styles/enums';
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import IconProps from './mixins/IconProps';
import { IconProperty } from './enums';

export default {
  name: 'Icon',
  mixins: [
    IconProps,
  ],
  data() {
    return {
      StyleSizeType,
    };
  },
  computed: {
    iconClassName() {
      return new StyleClassNamesBuilder('icon')
        .addBlockClassName(this[IconProperty.Type])
        .addColorSchemeConditionClassNames(this[IconProperty.ColorScheme])
        .addSizeConditionClassNames(this[IconProperty.Size])
        .addClassName(this[IconProperty.ClassName])
        .build();
    },
  },
};

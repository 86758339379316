export const QueryParams = {
  Location: 'location',
  Lat: 'lat',
  Lon: 'lon',
  Lng: 'lng',
  MinLat: 'minLat',
  MaxLat: 'maxLat',
  MinLon: 'minLon',
  MaxLon: 'maxLon',
  Zoom: 'zoom',
  Address: 'address',
  AddressId: 'addressId',
  MinPrice: 'minPrice',
  MaxPrice: 'maxPrice',
  Beds: 'beds',
  MinBeds: 'minBeds',
  MaxBeds: 'maxBeds',
  Baths: 'baths',
  MinBaths: 'minBaths',
  BuildingType: 'buildingType',
  BuildingClass: 'buildingClass',
  PropertyType: 'propertyType',
  ListingType: 'listingType',
  ListingStatus: 'listingStatus',
  FinishedArea: 'finishedArea',
  MinFinishedSqft: 'minFinishedSqft',
  MaxFinishedSqft: 'maxFinishedSqft',
  MinLotSizeAcres: 'minLotSizeAcres',
  MaxLotSizeAcres: 'maxLotSizeAcres',
  MinYearBuilt: 'minYearBuilt',
  MaxYearBuilt: 'maxYearBuilt',
  YearBuilt: 'yearBuilt',
  Edit: 'edit',
  SearchId: 'searchId',
  Name: 'name',
  NotificationFreq: 'notificationFreq',
  Parking: 'parking',
  Style: 'style',
  SqFt: 'sqFt',
  Street: 'street',
  Unit: 'unit',
  City: 'city',
  PostalCode: 'postalCode',
  ListingId: 'listingId',
  UserId: 'userId',
  Agent: 'agent',
  Community: 'community',
  Province: 'province',
  TransactionDate: 'transactionDate',
  Service: 'service',
  For: 'for',
  OfferId: 'offerId',
  IsOfflineOffer: 'isOfflineOffer',
  Referrer: 'referrer',
  MinClosedAt: 'minClosedAt',
  Group: 'group',
  Sort: 'sort',
  String: 'string',
  Limit: 'limit',
  Search: 'search',
  ListingStatuses: 'listingStatuses',
  ListingTypes: 'listingTypes',
  UnitType: 'unitType',
  Origins: 'origins',
  TotalFinishSqft: 'totalFinishSqft',
  AddressConfirmationStep: 'addressConfirmationStep',
  MinParkingSpaces: 'minParkingSpaces',
  ParkingTypes: 'parkingTypes',
  MinGarageSpaces: 'minGarageSpaces',
  LandSize: 'landSize',
  IsCondoExcluded: 'isCondoExcluded',
  OccupancyStatuses: 'occupancyStatuses',
  SuiteAvailability: 'suiteAvailability',
};

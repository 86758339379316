import { StyleFontWeightType } from '../../../styles/enums';
import StyleClassNamesBuilder from '../../../styles/helpers/StyleClassNamesBuilder';

const TypographyStyles = {
  props: {
    fontWeight: {
      type: String,
      default: StyleFontWeightType.Light,
    },
  },
  methods: {
    getTypographyClassNames(rootClassName) {
      return new StyleClassNamesBuilder(rootClassName)
        .addConditionClassNames(Object.values(StyleFontWeightType), this.fontWeight)
        .build();
    },
  },
};

export default TypographyStyles;


import TypographyStyles from '../mixins/TypographyStyles';

export default {
  name: 'Span',
  mixins: [TypographyStyles],
  computed: {
    spanClassNames() {
      return this.getTypographyClassNames('span');
    },
  },
};

/* eslint-disable no-param-reassign */
export const SET = 'set';
export const SET_NOTIFICATIONS = 'set-notifications';
export const SET_PROPERTY_OWNERSHIPS = 'set-property-ownerships';

export default {
  [SET](previousState, user) {
    previousState.initialized = true;
    previousState.userData = user;
  },
  [SET_NOTIFICATIONS](previousState, notifications) {
    previousState.userData.notifications = notifications;
  },
  [SET_PROPERTY_OWNERSHIPS](previousState, propertyOwnerships) {
    previousState.userData.propertyOwnerships = propertyOwnerships;
  },
};


import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '../../styles/enums';
import { LinkProperty } from './enums';
import Icon from '../icon';
import { IconType } from '../icon/enums';
import Gutter from '../gutter';
import LinkProps from './mixins/LinkProps';

export default {
  name: 'Link',
  components: { Gutter, Icon },
  mixins: [LinkProps],
  data: () => ({
    IconType,
    StyleSizeType,
    StylePositionType,
    StyleColorSchemeType,
    LinkProperty,
  }),
  computed: {
    component() {
      if (this[LinkProperty.RedirectTo]) {
        return 'NuxtLink';
      }
      return 'span';
    },
    wrapperClassNames() {
      return new StyleClassNamesBuilder('link-wrapper')
        .addClassName(this[LinkProperty.WrapperClassName])
        .build();
    },
    linkClassNames() {
      return new StyleClassNamesBuilder('link')
        .addClassName(this[LinkProperty.ClassName])
        .addColorSchemeConditionClassNames(this[LinkProperty.ColorScheme])
        .addConditionClassName('underlined', this[LinkProperty.IsUnderlined])
        .addConditionClassName('has-chevron', this[LinkProperty.HasChevron])
        .build();
    },
  },
};


import Gutter from '@/ui-kit/components/gutter';
import { GutterProperty } from '@/ui-kit/components/gutter/enums';
import { StyleSizeType, StylePositionType } from '@/ui-kit/styles/enums';
import FooterLogoSectionLogo from './logo';
// import FooterLogoSectionScheduleCall from './schedule-call';
import FooterLogoSectionSocialLinks from './social-links';

export default {
  name: 'FooterLogoSection',
  components: {
    Gutter,
    FooterLogoSectionLogo,
    // FooterLogoSectionScheduleCall,
    FooterLogoSectionSocialLinks,
  },
  data() {
    return {
      GutterProperty,
      StyleSizeType,
      StylePositionType,
    };
  },
};

export const AvatarSlotName = {
  Placeholder: 'placeholder',
};

export const AvatarProperty = {
  Size: 'size',
  Src: 'src',
  Alt: 'alt',
};

export const AvatarPropsEventType = {
  Error: 'error',
};

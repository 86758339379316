
import TypographyStyles from '../mixins/TypographyStyles';

export default {
  name: 'Header1',
  mixins: [TypographyStyles],
  computed: {
    header1ClassNames() {
      return this.getTypographyClassNames('header-1');
    },
  },
};

import { AvatarProperty } from '../enums';
import { StyleSizeType } from '../../../styles/enums';
import { DEFAULT_AVATAR_ALT } from '../constants';

const AvatarProps = {
  props: {
    [AvatarProperty.Size]: {
      default: StyleSizeType.Medium,
      type: String,
    },
    [AvatarProperty.Src]: {
      default: '',
      type: String,
    },
    [AvatarProperty.Alt]: {
      default: DEFAULT_AVATAR_ALT,
      type: String,
    },
  },
};

export default AvatarProps;

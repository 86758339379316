/**
 * Has cookie.
 *
 * @param {String} name
 * @returns {Boolean}
 * @public
 */

export function hasCookie(name) {
  const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
  const cookie = cookies.find((cookieItem) => cookieItem.startsWith(name));
  return !!cookie;
}

/**
 * Create session cookies available accross the platform.
 *
 * @param {String} name
 * @param {String} value
 * @param {Boolean} secure
 * @public
 */

export function createCookie(name, value, secure) {
  const isSecure = secure && (window.location.hostname !== 'localhost');
  document.cookie = `${name}=${value}; path=/;${isSecure ? 'secure' : ''}; SameSite=Strict`;
}

/**
 * Delete cookie.
 *
 * @param {String} name
 * @public
 */

export function deleteCookie(name) {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

import { StyleColorSchemeType, StyleSizeType } from '../../../styles/enums';
import { IconProperty } from '../enums';

const IconProps = {
  props: {
    [IconProperty.ClassName]: {
      default: '',
      type: String,
    },
    [IconProperty.Type]: {
      required: true,
      type: String,
    },
    [IconProperty.Size]: {
      default: StyleSizeType.Medium,
      type: String,
    },
    [IconProperty.ColorScheme]: {
      default: StyleColorSchemeType.Gray,
      type: String,
    },
  },
};

export default IconProps;

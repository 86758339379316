/* eslint-disable camelcase */
import {
  SET_HERO_CONTENT,
  SET_STATEMENT_CONTENT,
  SET_CARD_CONTENT,
  SET_BOTTOM_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;
export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      hero_section,
      statement_section,
      card_section,
      bottom_section,
    } = data;

    const [heroData] = hero_section;
    const [statementData] = statement_section;
    const [bottomData] = bottom_section;

    commit(SET_HERO_CONTENT, heroData);
    commit(SET_STATEMENT_CONTENT, statementData);
    commit(SET_CARD_CONTENT, card_section);
    commit(SET_BOTTOM_CONTENT, bottomData);
    commit(SET_IS_CONTENT_LOADED, true);
  },
};

import STORE_NAME from './name';
import {
  SET_TOAST_TITLE,
  SET_TOAST_VARIANT,
  SET_TOAST_IS_OPEN,
  SET_TOAST_SHOWING_MODE,
} from './mutations';
import { ToastVariant, ToastShowingMode } from '../../ui-kit/components/toast/enums';

export const getAction = (type) => `${STORE_NAME}/${type}`;
export const OPEN_TOAST = 'open-toast';
export const CLOSE_TOAST = 'close-toast';

export default {
  async [OPEN_TOAST]({ commit }, data) {
    commit(SET_TOAST_TITLE, data.title);
    commit(SET_TOAST_VARIANT, data.variant);
    commit(SET_TOAST_IS_OPEN, true);
    commit(SET_TOAST_SHOWING_MODE, data.showingMode);
  },
  async [CLOSE_TOAST]({ commit }) {
    commit(SET_TOAST_TITLE, '');
    commit(SET_TOAST_VARIANT, ToastVariant.Positive);
    commit(SET_TOAST_IS_OPEN, false);
    commit(SET_TOAST_SHOWING_MODE, ToastShowingMode.Temporary);
  },
};

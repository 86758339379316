/* eslint-disable no-param-reassign */
export const SET_HERO_CONTENT = 'set-hero-content';
export const SET_DESCRIPTION_CONTENT = 'set-description-content';
export const SET_LIST_TITLE_CONTENT = 'set-list-title-content';
export const SET_LIST_BULLET_CONTENT = 'set-list-bullet-content';
export const SET_IMAGE_CONTENT = 'set-image-content';
export const SET_FOOTER_CONTENT = 'set-footer-content';
export const SET_IS_CONTENT_LOADED = 'set-is-content-loaded';
export default {
  [SET_HERO_CONTENT](previousState, content) {
    previousState.heroContent = content;
  },
  [SET_DESCRIPTION_CONTENT](previousState, content) {
    previousState.descriptionContent = content;
  },
  [SET_LIST_TITLE_CONTENT](previousState, content) {
    previousState.listTitleContent = content;
  },
  [SET_LIST_BULLET_CONTENT](previousState, content) {
    previousState.listBulletContent = content;
  },
  [SET_IMAGE_CONTENT](previousState, content) {
    previousState.imageContent = content;
  },
  [SET_FOOTER_CONTENT](previousState, content) {
    previousState.footerContent = content;
  },
  [SET_IS_CONTENT_LOADED](previousState, isLoaded) {
    previousState.isContentLoaded = isLoaded;
  },
};

export const ToastVariant = {
  Positive: 'positive',
  Negative: 'negative',
  Info: 'info',
  Neutral: 'neutral',
};

export const ToastShowingMode = {
  Temporary: 'temporary',
  Static: 'static',
};

export const ToastProperty = {
  Title: 'title',
  Variant: 'variant',
  IsOpen: 'isOpen',
  CloseCallback: 'closeCallback',
  ShowingMode: 'showingMode',
};

export const ToastMessage = {
  CreatingListingAgreement: 'One moment please! We are creating your Listing Agreement to sign before submission. Please ensure your browser allows pop ups for the agreement in DocuSign to appear.',
  DraftUnderReviewToast: 'We are checking things over and will get back to you shortly',
  UnderReviewToast: 'We are currently checking things over and will get back to you shortly',
  DraftWithATH: 'Only the primary title holder can edit the listing at this time',
  PhotographyMeasurementSuccessSubmit: 'Your request has been submitted successfully. Check your inbox for updates',
  PhotographyMeasurementErrorSubmit: 'Submission was unsuccessful. Please try again. If the issue persists please contact the Bōde team via the chatbot.',
  AvailabilitiesModalSuccessToast: 'Your details have been sent to the Bōde team',
  EditDisabledNeedsSigners: 'Your Listing Agreement has already been created in DocuSign. Please sign it first before making additional edits.',
  ATHRemoved: 'Additional Title Holder has been removed',
  SignupConfirmCodeSentTo: 'We sent a new code at',
  FavouriteUpdated: 'Favourite Updated',
  NoResultFound: 'No results were found. Please expand your search',
  FavouriteImageSaved: 'Favourite Image/s Saved',
  AccountRoleUpdateSuccess: 'User Role Updated',
  AccountRoleUpdateFailure: 'There was an issue with updating your role please try again',
  AccountInfoUpdateSuccess: 'Account Information Updated',
  AccountInfoUpdateFailure: 'There was an issue with your request please try again',
  AccountInfoInvalid: 'One of your account fields is either invalid or required',
  ResubmittedOffer: 'Pre-populating a new offer with your previous offer details. You may edit each detail before you submit a fresh offer.',
  DepositExtensionRequest: 'Your deposit extension request has been sent',
  DepositExtensionRequestFailure: 'Your deposit extension request encountered an error please try again',
  TransferDateUpdated: 'Your deposit transfer date has been submitted',
  TransferDateFailure: 'Your transfer date encountered an error please try again',
  ConditionsStatusUpdated: 'Your condition status(s) have been updated',
  ConditionsStatusFailure: 'Updating condition status(s) encountered an error please try again',
  FileUploadError: 'File upload error. Your file is too big and cannot be uploaded. Please choose a file less than 5MB',
  OfferPossessionDateModal: 'Your change has been submitted successfully',
  OfferPossessionDateModalFail: 'Offer possession date change failed - please try again',
  DepositExtensionModal: 'Your response to the buyer has been sent',
  DepositExtensionModalFail: 'Your response to the buyer has failed - please try again',
  SellerInfoModal: 'Seller information has been added',
  SellerInfoModalFail: 'Adding seller information failed - please try again',
  PossessionDayFeedback: 'Your feedback has been sent',
  PossessionDayFeedbackFail: 'There was an error with your request- please try again',
  BookViewing: 'Your request has been sent. Please wait for the confirmation page to load.',
  BookViewingUnauthenticated: 'Please login or create an account to book a viewing.',
  BookViewingUnverified: 'Please complete identity verification inside account/settings before booking a viewing.',
  RelistListing: 'This property has been re-listed successfully',
  RelistListingFail: 'There was an issue with re-listing, please try again',
  SoldDataShareSuccessful: 'Share Successful',
  ServiceRequests: 'Service request(s) loaded successfully',
  ServiceRequestsFail: 'There was an issue with retrieving your service requests, please try again',
  ServiceRequestsDelete: 'Service request deleted successfully',
  ServiceRequestsDeleteFail: 'There was an issue deleting this request, please try again',
  ViewRequest: 'Service request information loaded successfully',
  ViewRequestFail: 'There was an issue with retrieving this leads info, please try again',
  ViewRequestAcceptService: 'Service request accepted',
  ViewRequestAcceptServiceFail: 'There was an issue accepting this service request, please try again',
  ViewRequestDeclineService: 'Service request declined',
  ViewRequestDeclineServiceFail: 'There was an issue declining this service request, please try again',
  AdminListingSearch: 'Request for listings based on ID successful',
  AdminListingSearchFail: 'There was a problem retrieving listing information, please try again',
  AdminJoinListingData: 'Listing data join successful',
  AdminJoinListingDataFail: 'There was an issue joining these listings, please try again',
  CurrentUserIsNotBusiness: 'Current user is not a business. Log in as a Prō and try again.',
  UnableToLoadUserProfile: 'Unable to load user profile',
  TooManyAths: 'You have reached the limit for additional titleholders in your province. Please get in touch via the chatbot if you need assistance.',
  CompleteListing: 'Please complete the listing before preview.',
  LinkCopied: 'The link has been copied. You can now paste it anywhere you like.',
  MarketDataShareSearchSuccess: 'Your market data search has been shared successfully.',
  PowerOfAttorneyDisclaimer: 'In order to legally sell a property on behalf of someone else you are required to show a Power of Attorney document. Please send a message to the chat bot if you have any questions.',
  AgreementHasBeenSent: 'The agreement has been sent to your email.',
  AgreementSendError: 'Error creating agreement. Please try again later.',
  UploadMeasurementsDocument: 'The Bōde Team has not yet received your professional measurements which are required before your listing can be submitted. Please get in touch if you have any questions.',
  EditIconInSubmittedStatus: 'You can\'t edit listing in Submitted or Under Review status',
  ChangeOwnerSuccess: 'The Owner has been updated successfully',
  ChangeOwnerFail: 'An error occured changing owners - please try again',
  SingleOwnerNeedsSigning: 'Please sign the Listing Agreement sent via DocuSign to your inbox. This step is required before your listing is reviewed by the Bōde team.',
  AthNeedsSigning: 'One or more co-owners on your listing have not yet signed the Listing Agreement. The email from DocuSign is in their inbox. All owners must sign the Listing Agreement before your listing is reviewed by the Bōde team.',
  OfferRequested: 'Thank you for you interest in this home! Your details have been sent to the Bōde Team.  They will follow up with you shortly via email to create your offer.',
  MobileSearchLocationServicesMessage: 'Turn on Location Services for optimal searching',
  DraftListingDeleted: 'Draft listing has been deleted successfully',
  SavedSearchDeleteSuccess: 'Saved search deleted successfully.',
  SavedSearchDeleteFail: 'There was an issue deleting your saved search.',
  BuilderListingRemoval: 'Your update has been sent to the Bōde Team. We will get in touch shortly.',
  RequestIssue: 'There was an issue with your request. Please try again.',
  RequestSuccess: 'Your request has been submitted successfully.',
  HowItWorksBuilderFormSuccess: 'Thank you! Your information has been sent to the team and they will follow up shortly.',
  HowItWorksBuilderFormFail: 'Your information has not been sent. Please try again. If the problem persists, please contact help@bodecanada.com',
  AdditionalBuyerRemovalSuccess: 'Additional buyer has been removed successfully',
  AdditionalBuyerRemovalFail: 'Something went wrong trying to remove this additional buyer, please try again',
  ContactDetailsFail: 'There was an issue with submitting additional buyers, please try again',
  BuilderListingAssignmentSuccess: 'Your listing has been successfully transferred. The new owner will see the listing in their account.',
  BuilderListingAssignmentFail: 'There was an issue re-assigning this listing: Please try again.',
  BuilderAccountRetrievalFail: 'There was an issue retrieving all business accounts: Please try again.',
  BuilderNoOtherAccounts: 'No other business member currently exist under your builder account',
  PhotoAndMeasurementStatusUpdate: 'Photo and measurements status progress has been updated',
  PhotoAndMeasurementStatusUpdateFail: 'There was an issue updating Photo and measurements status please try again',
};

export default {
  ToastVariant,
  ToastMessage,
};

class ExternalNavigationHelper {
  static openInNewWindow(href) {
    window.open(href);
  }

  static openInCurrentWindow(href) {
    window.location.replace(href);
  }
}

export default ExternalNavigationHelper;

/* eslint-disable camelcase */
import {
  SET_HERO_CONTENT,
  SET_FOOTER_CONTENT,
  SET_IS_CONTENT_LOADED,
} from './mutations';
import STORE_NAME from './name';

export const SET_CONTENT_DATA = 'set-content-data';
export const getAction = (type) => `${STORE_NAME}/${type}`;

export default {
  async [SET_CONTENT_DATA]({ commit }, data) {
    const {
      hero_header,
      hero_image,
      hero_text,
      carousel_items,
      bottom_section_header,
      bottom_section_image,
      bottom_section_text,
      bottom_section_button,
      button_url,
    } = data;

    const heroData = {
      title: hero_header,
      image: hero_image,
      description: hero_text,
      carouselItems: carousel_items,
    };
    const footerData = {
      header: bottom_section_header,
      image: bottom_section_image,
      text: bottom_section_text,
      buttons: [
        {
          title: bottom_section_button,
          url: button_url,
        },
      ],
    };

    commit(SET_HERO_CONTENT, heroData);
    commit(SET_FOOTER_CONTENT, footerData);
    commit(SET_IS_CONTENT_LOADED, true);
  },
};

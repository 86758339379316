
import TypographyStyles from '../mixins/TypographyStyles';

export default {
  name: 'Header2',
  mixins: [TypographyStyles],
  computed: {
    header2ClassNames() {
      return this.getTypographyClassNames('header-2');
    },
  },
};

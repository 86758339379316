export default () => ({
  isContentLoaded: false,
  phoneContent: {},
  allYouNeedContent: {},
  buyYourHomeContent: {},
  sellYourHomeContent: {},
  bodeFeaturesContent: {},
  customerFeedbackContent: {
    title: '',
    list: [],
  },
  soldPreviewContent: {
    title: '',
    linkText: '',
    list: [],
  },
  featuredInContent: {
    title: '',
    list: [],
  },
  bodeIsSecureContent: {},
});

/* eslint-disable no-param-reassign */
export const SET_LISTING_SUBMIT_SUCCESS_CONTENT = 'set-listing-submit-success-content';
export const SET_LISTING_SUBMIT_SUCCESS_IS_CONTENT_LOADED = 'set-listing-submit-success-is-content-loaded';

export default {
  [SET_LISTING_SUBMIT_SUCCESS_CONTENT](previousState, content) {
    previousState.contentData = content;
  },
  [SET_LISTING_SUBMIT_SUCCESS_IS_CONTENT_LOADED](previousState, isContentLoaded) {
    previousState.isContentLoaded = isContentLoaded;
  },
};


export default {
  name: 'Bodepin',
  props: {
    height: {
      type: String,
      default: '44px',
    },
    width: {
      type: String,
      default: '24px',
    },
  },
};

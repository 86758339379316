import api from '../../../../utils/api';
import { UserPath } from '../enums';

export default class AccountApiService {
  static patchAccount(data) {
    return api.put(UserPath.Account, data);
  }

  static deleteUserAccount(body) {
    return api.delete(UserPath.Account, body);
  }
}

export const AccordionSlotName = {
  Header: 'header',
  Content: 'content',
};

export const AccordionEventType = {
  Toggle: 'toggle',
};

export const AccordionProperty = {
  DefaultIsExpanded: 'defaultIsExpanded',
  IsStatusContentHidden: 'isStatusContentHidden',
  StatusSettings: 'statusSettings',
  HeaderClassName: 'headerClassName',
  ContentClassName: 'contentClassName',
  IsDisabled: 'isDisabled',
  IsRadioSelect: 'isRadioSelect',
};

export default {
  AccordionSlotName,
  AccordionEventType,
};

import { FooterLinkProperty, FooterLinkType } from '../enums';

const FooterLinkProps = {
  props: {
    [FooterLinkProperty.RedirectTo]: {
      required: true,
      type: String,
    },
    [FooterLinkProperty.Type]: {
      default: FooterLinkType.Internal,
      type: String,
    },
    [FooterLinkProperty.Data]: {
      default: () => ({}),
      type: Object,
    },
    [FooterLinkProperty.ClassName]: {
      default: '',
      type: String,
    },
  },
};

export default FooterLinkProps;


import FooterMobileNavigationAccordion from './accordion';
import { FooterLinkEventType, FooterViewMode } from '../shared/enums';
import FooterTrackEvent from '../shared/mixins/TrackEvent';

export default {
  name: 'FooterMobileNavigation',
  components: {
    FooterMobileNavigationAccordion,
  },
  mixins: [FooterTrackEvent],
  props: {
    links: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      FooterLinkEventType,
    };
  },
  methods: {
    handleLinkClick({ label }) {
      this.trackLinkClick(FooterViewMode.Mobile, label);
    },
  },
};


import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import { StylePositionType, StyleSizeType } from '../../styles/enums';
import { ExternalLinkProperty, ExternalLinkEventType } from './enums';
import { IconType } from '../icon/enums';
import Icon from '../icon';
import Gutter from '../gutter';
import ExternalLinkProps from './mixins/ExternalLinkProps';

export default {
  name: 'ExternalLink',
  components: {
    Icon,
    Gutter,
  },
  mixins: [ExternalLinkProps],
  data: () => ({
    IconType,
    StyleSizeType,
    StylePositionType,
    ExternalLinkProperty,
  }),
  computed: {
    externalLinkClassNames() {
      return new StyleClassNamesBuilder('external-link')
        .addClassName(this[ExternalLinkProperty.ClassName])
        .addColorSchemeConditionClassNames(this[ExternalLinkProperty.ColorScheme])
        .addConditionClassName('underline', this[ExternalLinkProperty.IsUnderlined])
        .build();
    },
  },
  methods: {
    handleClick() {
      this.$emit(ExternalLinkEventType.Click);
    },
  },
};
